import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Menu: ComponentStyleConfig = {
  baseStyle: {
    item: {
      _hover: {
        bg: 'qGray.50',
      },
    },
  },
};
