import { Button, chakra, Input, VStack, Text, Grid, Heading, Center } from '@chakra-ui/react';
import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WireGuardFormGridItem } from './WireGuardFormGridItem';
import { SectionLoading } from 'components';
import { useCreateWireGuardConfigurationMutation } from 'utils/graphql/hooks';
import type { WireGuardInstanceType, WireGuardQuery } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

const publicKeyInputId = 'public-key';

interface CreateWireGuardFormData {
  instanceType: WireGuardInstanceType;
  publicKey: string;
}

interface WireGuardConfigFormProps {
  instanceType: WireGuardInstanceType;
  refetchConfig: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<WireGuardQuery, Error>>;
}

export const WireGuardConfigForm: FunctionComponent<WireGuardConfigFormProps> = ({
  instanceType,
  refetchConfig,
}) => {
  const { t } = useTranslation();
  const { readonlyProps } = useAuthUtils();

  const errorToast = useIotSimToast({ status: 'error' });
  const infoToast = useIotSimToast({ status: 'info' });

  const { mutateAsync, isPending } = useCreateWireGuardConfigurationMutation();

  const { register, handleSubmit, reset } = useForm<CreateWireGuardFormData>({});

  const onSubmit = handleSubmit(async ({ publicKey }) => {
    try {
      infoToast({ title: t('config.wireGuard.success.configIsBeingSetUp') });

      await mutateAsync({
        instanceType,
        publicKey,
      });

      await refetchConfig();

      reset();
      infoToast({ title: t('config.wireGuard.success.configIsDeploying') });
    } catch (e) {
      reset();
      errorToast({
        title:
          (e instanceof Error && t('config.wireGuard.error.configFailed')) || t('error.generic'),
        ...(e instanceof Error && { description: e.message }),
      });
    }
  });

  return (
    <>
      {isPending ? (
        <SectionLoading />
      ) : (
        <VStack alignItems="normal">
          <Heading fontSize="lg">{t('config.wireGuard.registration')}</Heading>

          <Text>{t('config.wireGuard.publicKeyRegistrationDescription')}</Text>

          <chakra.form onSubmit={onSubmit}>
            <Grid templateColumns="repeat(6, 1fr)" mt="4" alignItems="center">
              <WireGuardFormGridItem type="label">
                <chakra.label htmlFor={publicKeyInputId}>
                  {t('config.wireGuard.publicKey')}
                </chakra.label>
              </WireGuardFormGridItem>
              <WireGuardFormGridItem type="input">
                <Input
                  id={publicKeyInputId}
                  size="sm"
                  variant="userData"
                  {...register('publicKey', {
                    validate: (value) =>
                      value?.trim() ? true : t('config.wireGuard.error.publicKeyRequired'),
                  })}
                  placeholder={t('config.wireGuard.publicKeyExample')}
                  isRequired
                />
              </WireGuardFormGridItem>
            </Grid>

            <Center>
              <Button mt="6" type="submit" {...readonlyProps}>
                {t('common.submit')}
              </Button>
            </Center>
          </chakra.form>
        </VStack>
      )}
    </>
  );
};
