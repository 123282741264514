import { Box, type BoxProps } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { Loading, type LoadingProps } from 'components/Loading';

interface SectionLoadingProps extends LoadingProps, BoxProps {}

export const SectionLoading: FunctionComponent<SectionLoadingProps> = ({
  loadingResourceName,
  ...rest
}) => (
  <Box py="20" {...rest}>
    <Loading loadingResourceName={loadingResourceName} />
  </Box>
);
