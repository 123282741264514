import { routes } from '../../../utils/routes';

export const footerItems = [
  { i18nKey: 'footer.nav.imprint', href: routes.external.imprint },
  { i18nKey: 'footer.nav.privacyPolicy', href: routes.external.privacy },
  {
    i18nKey: 'footer.nav.termsOfUse',
    href: routes.external.termsOfUse,
  },
  { i18nKey: 'footer.nav.termsAndConditions', href: routes.external.termsAndConditions },
  { i18nKey: 'footer.nav.shop', href: routes.external.shop },
];
