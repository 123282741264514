import { enUS } from 'date-fns/locale';
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from './en';

// Dates are formatted using date-fns. When dynamic languages are added, this constant can be set dynamically
export const dateFnsLocale = enUS;

export const i18n = i18next.use(initReactI18next).init({
  fallbackLng: 'en',
  interpolation: {
    escapeValue: true,
    skipOnVariables: false,
  },
  // in the first instance this project is only available in english
  supportedLngs: ['en'],
  resources: {
    en,
  },
});

i18next.services.formatter?.add('lowercase', (value) => value.toLowerCase());
i18next.services.formatter?.add('uppercase', (value) => value.toUpperCase());
i18next.services.formatter?.add(
  'capitalize',
  (value) => value.charAt(0).toUpperCase() + value.slice(1),
);

// current language or english as fallback
const countryName = new Intl.DisplayNames([i18next.language ?? 'en'], {
  type: 'region',
});

export const getCountryNameOfCode = (code: string) => {
  try {
    return countryName.of(code);
  } catch (error) {
    return code;
  }
};
