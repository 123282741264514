import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react';
import { useEffect, type Dispatch, type FunctionComponent, type SetStateAction } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputContainer } from 'components';
import { InputValidation } from 'utils';
import {
  useUpdateUserAttributesMutation,
  type UpdateUserAttributesMutationVariables,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

interface SendCodeProps {
  formId: string;
  setSendCodeOpen: Dispatch<SetStateAction<boolean>>;
  setFormIsSubmitting: Dispatch<SetStateAction<boolean>>;
}

export const SendCodeModalContent: FunctionComponent<SendCodeProps> = ({
  formId,
  setSendCodeOpen,
  setFormIsSubmitting,
}) => {
  const { t } = useTranslation();
  const { authUser } = useAuthUserContext();
  const { mutateAsync } = useUpdateUserAttributesMutation();
  const errorToast = useIotSimToast({ status: 'error' });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<UpdateUserAttributesMutationVariables>({
    defaultValues: { email: '' },
  });

  useEffect(() => {
    setFormIsSubmitting(isSubmitting);
  }, [isSubmitting, setFormIsSubmitting]);

  const onSubmit = handleSubmit(async ({ email }) => {
    try {
      await mutateAsync({ email });

      setSendCodeOpen(false);
    } catch (error) {
      errorToast({
        title: t('error.generic'),
        ...(error instanceof Error && { description: error.message }),
      });
    }
  });

  return (
    <form onSubmit={onSubmit} id={formId}>
      <InputContainer>
        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel>{t('account.user.changeEmail.newEmail')}</FormLabel>
          <Input
            {...register('email', {
              validate: (value) => {
                if (value === authUser?.attributes.email) {
                  return t('account.user.changeEmail.sameEmail');
                }
                if (value && !InputValidation.email.test(value)) {
                  return t('auth.error.mailWrongFormat');
                }

                return undefined;
              },
            })}
            placeholder={t('account.user.changeEmail.newEmail')}
            autoFocus
          />
          <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
        </FormControl>
      </InputContainer>
    </form>
  );
};
