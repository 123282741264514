import { Alert, AlertDescription, AlertIcon, type AlertProps } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

// parameter from pipeline is in pascal case
const displayWarning = process.env.REACT_APP_SHOW_GLOBAL_WARNING?.toLowerCase();

// unset parameter from pipeline is a whitespace
const warningMessage = process.env.REACT_APP_GLOBAL_WARNING_MESSAGE?.trim();

export const MaintenanceAlert: FunctionComponent<AlertProps> = ({ ...rest }) => {
  const { t } = useTranslation();

  if (displayWarning !== 'true') {
    return null;
  }

  return (
    <Alert status="warning" justifyContent="center" mb="5" {...rest}>
      <AlertIcon />
      <AlertDescription>{warningMessage || t('error.maintenance')}</AlertDescription>
    </Alert>
  );
};
