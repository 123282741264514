import { Box, Tooltip, type BoxProps } from '@chakra-ui/react';
import { useCallback, useMemo, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockClickEventPropagation } from './BlockClickEventPropagation';
import { ReactComponent as SimActive } from 'assets/icons/SimActive.svg';
import { ReactComponent as SimDeactivated } from 'assets/icons/SimDeactivated.svg';
import { ReactComponent as SimExpired } from 'assets/icons/SimExpired.svg';
import { ReactComponent as SimPending } from 'assets/icons/SimPending.svg';
import { ReactComponent as SimReorderVolume } from 'assets/icons/SimReorderVolume.svg';
import { SimState, SimStateReason } from 'utils/graphql/hooks';
import { userCanActivateSim } from 'utils/simUtils';

interface SimStateIndicatorProps extends BoxProps {
  state: SimState;
  stateReason?: SimStateReason | null;
  withoutStateTranslation?: boolean;
  withoutTooltip?: boolean;
}

export const SimStateIndicator: FunctionComponent<SimStateIndicatorProps> = ({
  state,
  stateReason,
  withoutStateTranslation = false,
  withoutTooltip = false,
  ...rest
}) => {
  const { t } = useTranslation();

  const StateIcon = useCallback(() => {
    switch (state) {
      case SimState.Active:
        return <SimActive />;
      case SimState.ActivationPending:
        return (
          <Box data-testid="simStateIconActivationPending" color="qGreen.500">
            <SimPending />
          </Box>
        );
      case SimState.SuspendPending:
        return (
          <Box data-testid="simStateIconSuspendPending" color="red.600">
            <SimPending />
          </Box>
        );
      case SimState.Suspended:
        switch (stateReason) {
          case SimStateReason.ContractDurationExceeded:
            return <SimExpired />;
          case SimStateReason.DataVolumeQuotaExceeded:
            return <SimReorderVolume />;
          case SimStateReason.SimUnused: // will not be displayed in portal anyway
            return null;
          case SimStateReason.User:
          case SimStateReason.ActivationFailed:
          default: // e.g. fallback for ScannedSimCardOverview
            return <SimDeactivated />;
        }
      // could not occur in portal
      case SimState.Initial:
      case SimState.Terminated:
      default:
        return null;
    }
  }, [state, stateReason]);

  const tooltipLabel = useMemo(() => {
    if (userCanActivateSim({ state, stateReason })) {
      return t(`sim.stateReason.${stateReason}`);
    }

    // all other not system deactivation reasons are handled above
    return state === SimState.Suspended
      ? `${t(`sim.stateReason.${stateReason}`)} (${t('sim.deactivatedBySystem')})`
      : t(`sim.state.${state}`);
  }, [state, stateReason, t]);

  return (
    <BlockClickEventPropagation>
      <Tooltip label={tooltipLabel} isDisabled={withoutTooltip}>
        <Box w="fit-content" {...rest} data-testid="simStateIndicator">
          <StateIcon />
        </Box>
      </Tooltip>
    </BlockClickEventPropagation>
  );
};
