import {
  Grid,
  Heading,
  Button,
  Textarea,
  Input,
  Box,
  FormControl,
  FormLabel,
  Flex,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { type FunctionComponent, useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SectionStack } from 'components';
import type { SimPageQueryVariables } from 'utils/graphql/hooks';
import {
  SimSortBy,
  SortDirection,
  SearchableColumn,
  useSimPageQuery,
  useCreateSupportTicketMutation,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

interface SimOption {
  value: string;
  label: string;
}

interface SupportFormFields {
  name: string;
  email: string;
  phone: string;
  simCards: any[];
  firstOccurrenceTimestamp: string;
  description: string;
}

export const SupportPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const [simQueryParams, setSimQueryParams] = useState<SimPageQueryVariables>({
    first: 10,
    offset: 0,
    sortBy: SimSortBy.Iccid,
    sortDirection: SortDirection.Asc,
    searchKey: SearchableColumn.Iccid,
    searchValue: '',
  });

  const [simOptions, setSimOptions] = useState<SimOption[]>([]);

  const { isLoading, data: simPageData } = useSimPageQuery(simQueryParams);

  useEffect(() => {
    if (simPageData?.simPage?.items) {
      setSimOptions(
        simPageData.simPage.items.map((item) => ({
          value: item.iccid,
          label: item.iccid,
        })),
      );
    }
  }, [simPageData]);

  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<SupportFormFields>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      firstOccurrenceTimestamp: '',
      description: '',
    },
  });

  const {
    field: { onChange: onChangeSimCardSelect, onBlur: onBlurSimCardSelect, value: simCards },
  } = useController<SupportFormFields>({
    name: 'simCards',
    defaultValue: [],
    control,
  });

  const { mutateAsync: createSupportTicket } = useCreateSupportTicketMutation();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });
  // clearValue() is missing in typeof Select but has to be used to clear the select after form submition
  const selectSimCardsRef = useRef<typeof Select & { clearValue: () => void }>(null);

  return (
    <SectionStack type="outer" flex="1">
      <Heading as="h2" size="lg" px="12" py="7" display="flex" justifyContent="space-between">
        {t('account.support.supportRequest')}
      </Heading>
      <Grid
        templateColumns={['1fr', null, '2fr 2fr']}
        templateRows="1fr"
        mx="12"
        py="7"
        columnGap="12"
      >
        <Box>
          <FormControl isInvalid={!!errors.name} isRequired>
            <FormLabel>{t('common.name')}</FormLabel>
            <Input
              placeholder={t('common.name')}
              {...register('name', { required: t('account.support.error.nameRequired') })}
            />
            {errors.name && <FormErrorMessage>{errors.name.message}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors.email} isRequired>
            <FormLabel mt="2">{t('common.email')}</FormLabel>
            <Input
              placeholder={t('common.email')}
              type="email"
              {...register('email', {
                required: t('account.support.error.emailRequired'),
                validate: (val) =>
                  /^[^@]+@[^@]{2,}\.[^@]{2,}$/.test(val) || t('account.support.error.emailInvalid'),
              })}
            />
            {errors.email && <FormErrorMessage>{errors.email.message}</FormErrorMessage>}
          </FormControl>
          <FormControl>
            <FormLabel mt="2">{t('common.phone')}</FormLabel>
            <Input placeholder={t('common.phone')} {...register('phone')} />
          </FormControl>
          <FormControl maxW="100%">
            <FormLabel mt="2">{t('account.support.affectedSIMCards')}</FormLabel>
            <Select
              /*
              // @ts-ignore */
              ref={selectSimCardsRef}
              isMulti
              name="simCards"
              placeholder={t('account.support.searchForIccids')}
              closeMenuOnSelect={false}
              options={simOptions}
              isLoading={isLoading}
              selectedOptionStyle="check"
              hideSelectedOptions={false}
              onInputChange={(inputValue) =>
                setSimQueryParams({
                  ...simQueryParams,
                  searchValue: inputValue,
                })
              }
              onChange={onChangeSimCardSelect}
              onBlur={onBlurSimCardSelect}
              noOptionsMessage={() => t('account.support.noSimCardsFound')}
            />
          </FormControl>
          <FormControl isInvalid={!!errors.firstOccurrenceTimestamp} isRequired>
            <FormLabel mt="2">{t('account.support.timestampOfError')}</FormLabel>
            <Input
              placeholder="Select Date and Time"
              size="md"
              type="datetime-local"
              {...register('firstOccurrenceTimestamp', {
                required: t('account.support.error.timestampRequired'),
              })}
            />
            {errors.firstOccurrenceTimestamp && (
              <FormErrorMessage>{errors.firstOccurrenceTimestamp.message}</FormErrorMessage>
            )}
          </FormControl>
        </Box>
        <Box>
          <FormControl
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            h="100%"
            isInvalid={!!errors.description}
            isRequired
          >
            <FormLabel>{t('common.description')}</FormLabel>
            <Textarea
              placeholder={t('common.description')}
              resize="none"
              flexGrow="1"
              {...register('description', {
                required: t('account.support.error.descriptionRequired'),
              })}
            />
            {errors.description && (
              <FormErrorMessage>{errors.description.message}</FormErrorMessage>
            )}
          </FormControl>
        </Box>
      </Grid>
      <Flex
        p="6"
        gap="2"
        justify={[null, 'space-around']}
        direction={['column', 'row']}
        alignItems="center"
      >
        <Button
          onClick={handleSubmit(async (data) => {
            try {
              await createSupportTicket({
                ...data,
                simCards: simCards.map((c: SimOption) => c.value),
                technicalInfo: navigator.userAgent,
              });

              successToast({
                title: t('account.support.ticketCreated'),
              });
            } catch (err) {
              return errorToast({
                title: t('error.generic'),
              });
            }
            selectSimCardsRef.current?.clearValue();

            return reset();
          })}
          isLoading={isSubmitting}
        >
          {t('common.submit')}
        </Button>
      </Flex>
    </SectionStack>
  );
};
