import i18next from 'i18next';
import { useState, type FunctionComponent, type PropsWithChildren } from 'react';
import { SystemAdminCompanySelect } from './components';
import { FullscreenLoading } from 'components/FullscreenLoading';
import { UserGroup } from 'utils/graphql/hooks';
import type { AuthUser } from 'utils/provider/AuthUserProvider';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

const companySelectGroupSet = new Set([UserGroup.SystemAdmin, UserGroup.SystemUser]);

export const AuthenticatedRoute: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { authUser, isLoading } = useAuthUserContext();

  // only for system admin company select
  const [selectedCompanyId, setSelectedCompanyId] = useState<string | undefined>();

  if (isLoading) {
    return <FullscreenLoading />;
  }

  // this can only happen on manual user creation
  if (authUser && (!authUser.companyId || !authUser.group)) {
    handleInvalidUser(authUser);
  }

  if (authUser?.group && companySelectGroupSet.has(authUser.group) && !selectedCompanyId) {
    return <SystemAdminCompanySelect setSelectedCompanyId={setSelectedCompanyId} />;
  }

  return <>{children}</>;
};

const handleInvalidUser = (authUser: AuthUser) => {
  const error = new Error();

  const causeOfError = !authUser.companyId ? 'CompanyId' : 'Group';
  error.name = `Missing${causeOfError}Error`;
  error.message = i18next.t(`error.missing${causeOfError}`);
  error.stack = undefined;

  throw error;
};
