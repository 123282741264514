import {
  Box,
  Button,
  Center,
  CircularProgress,
  CircularProgressLabel,
  Grid,
  HStack,
  InputGroup,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Spacer,
  Text,
  Tooltip,
  VStack,
  useConst,
  type UseDisclosureProps,
} from '@chakra-ui/react';
import type { InfiniteData } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { useCallback, useMemo, useRef, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { defaultSimPagination } from '../simTableConfigs';
import { ExceedDataVolume } from './components/ExceedDataVolume';
import { Ping } from './components/Ping';
import { SSH } from './components/SSH';
import {
  BaseModal,
  CustomNumberInputStepper,
  DetailsDisplayer,
  RenameSimForm,
  SimStateIndicator,
} from 'components';
import { formatSimCardEndDate, formatToDateTimeString } from 'utils/dateUtils';
import type { Sim, SimPageQuery } from 'utils/graphql/hooks';
import {
  ShopProductSku,
  SimState,
  UserGroup,
  useActivateSimCardsMutation,
  useDeactivateSimCardsMutation,
  useInfiniteSimPageQuery,
  usePingQuery,
} from 'utils/graphql/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';
import { useShopCheckoutContext } from 'utils/provider/ShopCheckoutProvider';
import { routes } from 'utils/routes';
import {
  dataVolumeUsedText,
  simStatusText,
  userCanActivateSim,
  userCanDeactivateSim,
} from 'utils/simUtils';

const volumeReloadInputWidth = '20';
const minVolumeReloadAmount = 1;
const maxVolumeReloadAmount = 999;

interface DetailsModalProps extends UseDisclosureProps {
  sim: Sim;
}

export const DetailsModal: FunctionComponent<DetailsModalProps> = ({
  sim,
  isOpen = false,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const { setOrderItems, prefetchShopCheckoutPageData } = useShopCheckoutContext();
  const { hasRequiredRole } = useAuthUserContext();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { readonlyProps, hasReadonlyPermission, readonlyTitleProp } = useAuthUtils();

  const { mutateAsync: deactivateAsync, isPending: isDeactivating } =
    useDeactivateSimCardsMutation();
  const { mutateAsync: activateAsync, isPending: isActivating } = useActivateSimCardsMutation();

  const gbInputRef = useRef<HTMLInputElement>(null);
  const dataVolumeDescriptionId = useConst(`${t('sim.col.dataVolume')}Description`);

  const isDeActivating = useMemo(
    () => isDeactivating || isActivating,
    [isActivating, isDeactivating],
  );

  const deActivationEnabled = useMemo(
    () => !hasReadonlyPermission && (sim.state === SimState.Active || userCanActivateSim(sim)),
    [sim, hasReadonlyPermission],
  );

  const deActivateButtonText = useMemo(
    () =>
      `${userCanDeactivateSim(sim) ? t('common.deactivate') : t('common.activate')} ${t(
        'common.simCard',
      )}`,
    [sim, t],
  );

  const deActivateButtonSystemAdminSuffix = useMemo(
    () =>
      hasRequiredRole(UserGroup.SystemAdmin) && !deActivationEnabled
        ? ` (${t('common.systemAdminOnly')})`
        : '',
    [deActivationEnabled, hasRequiredRole, t],
  );

  const onModalClose = useCallback(() => {
    onClose();
    queryClient.removeQueries({
      queryKey: usePingQuery.getKey({
        iccid: sim.iccid,
      }),
    });
    // removes the search params
    navigate(routes.simCards, { replace: true });
  }, [navigate, onClose, queryClient, sim.iccid]);

  const cardDeActivation = async () => {
    const { iccid, state } = sim;

    if (state === SimState.Active) {
      await deactivateAsync({ iccids: [iccid] });

      await queryClient.invalidateQueries({ queryKey: ['SimPage.infinite'] });
    } else if (state === SimState.Suspended) {
      await activateAsync({ iccids: [iccid] });

      await queryClient.invalidateQueries({ queryKey: ['SimPage.infinite'] });
    }

    onModalClose();
  };

  const prefetchCheckoutData = useCallback(() => {
    prefetchShopCheckoutPageData(ShopProductSku.Datavolume_1gb);
  }, [prefetchShopCheckoutPageData]);

  const changeState = async (value: boolean) => {
    queryClient.setQueryData<InfiniteData<SimPageQuery> | undefined>(
      useInfiniteSimPageQuery.getKey({
        ...defaultSimPagination,
      }),
      (oldData: InfiniteData<SimPageQuery> | undefined) => {
        const data = oldData ? { ...oldData, done: true } : undefined;

        if (data) {
          data.pages.forEach((page) => {
            page.simPage.items.forEach((item) => {
              if (item.iccid === sim.iccid) {
                // eslint-disable-next-line no-param-reassign
                item.canExceedDataVolume = value;
              }
            });
          });
        }

        return data;
      },
    );
  };

  return (
    <BaseModal
      size={['xs', 'md', '2xl', '3xl']}
      isOpen={isOpen}
      onClose={onModalClose}
      header={
        <VStack spacing="4">
          <HStack spacing="3">
            <Text fontSize="xl">{t('common.simCard')}</Text>
          </HStack>

          <HStack spacing="1">
            <SimStateIndicator state={sim.state} stateReason={sim.stateReason} withoutTooltip />
            <Text fontWeight="normal">
              {`${simStatusText(sim.state, sim.stateReason)} - Session: ${sim.session}`}
            </Text>
          </HStack>
        </VStack>
      }
      footer={
        <Button onClick={onModalClose} isDisabled={isDeActivating}>
          {t('common.close')}
        </Button>
      }
    >
      <SimpleGrid templateColumns={['1fr', null, '2fr 1fr']} py="1">
        <VStack spacing="4" pl={[0, 2]}>
          <RenameSimForm sims={[sim]} />

          <Grid templateColumns="1fr 3fr" rowGap={2} width="full">
            <DetailsDisplayer description={t('common.iccid')}>{sim.iccid}</DetailsDisplayer>

            <DetailsDisplayer description={t('sim.col.ip')}>{sim.ip}</DetailsDisplayer>

            <DetailsDisplayer description={t('sim.col.imsi')}>{sim.imsi}</DetailsDisplayer>

            <DetailsDisplayer description={t('sim.col.msisdn')}>{sim.msisdn}</DetailsDisplayer>

            <DetailsDisplayer description={t('sim.col.imei')}>{sim.imei}</DetailsDisplayer>

            <DetailsDisplayer description={t('sim.col.apn')}>{sim.apn}</DetailsDisplayer>

            <DetailsDisplayer
              description={t('sim.col.lastUse')}
              descriptionTitle={t('sim.col.lastUseTooltip')}
            >
              {sim.lastUse ? formatToDateTimeString(sim.lastUse) : t('sim.simCardUnused')}
            </DetailsDisplayer>

            {sim.endDate && (
              <DetailsDisplayer description={t('sim.col.endDate')}>
                {formatSimCardEndDate(sim.endDate)}
              </DetailsDisplayer>
            )}

            <DetailsDisplayer description={t('sim.dataVolume.exactByteUsed')}>
              {Intl.NumberFormat(i18next.language).format(sim.data.count)}
            </DetailsDisplayer>

            <Text>{t('sim.tools')}</Text>
            <HStack>
              <Ping iccid={sim.iccid} />
              <SSH iccid={sim.iccid} />
            </HStack>

            <Spacer />
            <Spacer />

            <Text mt="6">{t('sim.action')}</Text>
            <Box mt="6">
              <Tooltip
                label={
                  hasReadonlyPermission
                    ? readonlyTitleProp
                    : t('sim.simActivationDisabled', {
                        reason: simStatusText(sim.state, sim.stateReason),
                      })
                }
                isDisabled={deActivationEnabled}
                {...(!deActivationEnabled && { shouldWrapChildren: true })}
              >
                <Button
                  variant="secondary"
                  maxW="max-content"
                  isDisabled={!hasRequiredRole(UserGroup.SystemAdmin) && !deActivationEnabled}
                  isLoading={isDeActivating}
                  onClick={cardDeActivation}
                >
                  {`${deActivateButtonText}${deActivateButtonSystemAdminSuffix}`}
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        </VStack>

        <VStack as={Center} mt={[12, null, 0]}>
          <Text id={dataVolumeDescriptionId}>{t('sim.col.dataVolume')}</Text>

          <CircularProgress
            value={sim.data.limit ? (sim.data.count / sim.data.limit) * 100 : 0}
            size="48"
            thickness="4"
            color="qCyan.500"
            trackColor="gray.50"
            capIsRound
            role="presentation"
            // on role presentation these initially set aria labels make no sense
            aria-valuemax={undefined}
            aria-valuemin={undefined}
            aria-valuenow={undefined}
          >
            <CircularProgressLabel>
              <Text aria-describedby={dataVolumeDescriptionId}>
                <Box
                  as="strong"
                  fontSize="2xl"
                  title={t('sim.dataVolume.exact', { used: sim.data.count })}
                >
                  {dataVolumeUsedText(sim.data)}
                </Box>
                <br />
                {t('sim.dataVolume.used')}
              </Text>
            </CircularProgressLabel>
          </CircularProgress>
          {hasRequiredRole(UserGroup.SystemAdmin) && (
            <ExceedDataVolume
              iccid={sim.iccid}
              canExceedDataVolume={sim.canExceedDataVolume}
              changeSimState={changeState}
            />
          )}

          <Spacer />

          {process.env.REACT_APP_TOGGLE_RELOAD_DATA_VOLUME === 'true' && (
            <VStack spacing="3">
              <Text justifySelf="center">{t('sim.dataVolume.volumeReload')}</Text>
              <HStack>
                <InputGroup w={volumeReloadInputWidth} variant="userData">
                  <NumberInput
                    step={1}
                    min={minVolumeReloadAmount}
                    max={maxVolumeReloadAmount}
                    defaultValue={minVolumeReloadAmount}
                    onChange={prefetchCheckoutData}
                    {...readonlyProps}
                  >
                    <NumberInputField h="8" ref={gbInputRef} />
                    <CustomNumberInputStepper />
                  </NumberInput>
                </InputGroup>
                <strong>GB</strong>
              </HStack>
              <Button
                w={volumeReloadInputWidth}
                alignSelf="flex-start"
                onMouseEnter={prefetchCheckoutData}
                onFocus={prefetchCheckoutData}
                onClick={() => {
                  // selectedSimCardsContext will not be used to keep the current selection
                  if (gbInputRef.current?.value) {
                    setOrderItems([
                      {
                        iccid: sim.iccid,
                        sku: ShopProductSku.Datavolume_1gb,
                        quantity: Number(gbInputRef.current.value),
                      },
                    ]);
                    navigate(routes.shopCheckout);
                  }
                }}
                {...readonlyProps}
              >
                {t('common.apply')}
              </Button>
            </VStack>
          )}
        </VStack>
      </SimpleGrid>
    </BaseModal>
  );
};
