import { useDisclosure } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { LogoutAction, LogoutModal } from './components';
import { SideMenuLinks } from './utils/sideMenuLinks';
import { SideMenu, SideMenuWrapper } from 'components';

export const AccountPage: FunctionComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <SideMenuWrapper>
      <LogoutModal isOpen={isOpen} onClose={onClose} />
      <SideMenu links={SideMenuLinks()} action={<LogoutAction onOpen={onOpen} />} />

      <Outlet />
    </SideMenuWrapper>
  );
};
