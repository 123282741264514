import {
  FormLabel,
  type UseDisclosureProps,
  FormErrorMessage,
  chakra,
  FormControl,
  Input,
  Button,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseModal, ShowSecretButton, InputContainer } from 'components';
import {
  type Header,
  useUpdateIotConnectorGroupMutation,
  useGetIotConnectorGroupsQuery,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

const addNewHeaderFormId = 'addNewHeaderForm';

interface AddHeaderModalFormData {
  key: string;
  value: string;
}

export interface AddHeaderModalProps extends UseDisclosureProps {
  groupId: string;
  httpHeaders: Header[];
}

export const AddHeaderModal: FunctionComponent<AddHeaderModalProps> = ({
  isOpen = false,
  onClose = () => {},
  groupId,
  httpHeaders,
}) => {
  const { t } = useTranslation();
  const [shouldShowValue, setShouldShowValue] = useState(false);

  const queryClient = useQueryClient();

  const onModalClose = () => {
    onClose();
    reset();
  };

  const { mutateAsync } = useUpdateIotConnectorGroupMutation();

  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<AddHeaderModalFormData>({});

  const onSubmit = handleSubmit(async ({ key, value }) => {
    try {
      await mutateAsync({
        groupId,
        headers: [...httpHeaders, { key, value }],
      });

      await queryClient.refetchQueries({ queryKey: useGetIotConnectorGroupsQuery.getKey() });

      successToast({ title: t('config.iotConnectorProxy.addHeaderSuccess') });
    } catch (e) {
      errorToast({
        title:
          (e instanceof Error && t('config.iotConnectorProxy.addHeaderError')) ||
          t('error.generic'),
        ...(e instanceof Error && { description: e.message }),
      });
    }

    onModalClose();
  });

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onModalClose}
      header={t('config.iotConnectorProxy.addNewHeader')}
      footer={
        <>
          <Button variant="secondary" onClick={onModalClose} isDisabled={isSubmitting}>
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            form={addNewHeaderFormId}
            isDisabled={isSubmitting}
            isLoading={isSubmitting}
          >
            {t('common.saveChanges')}
          </Button>
        </>
      }
    >
      <chakra.form id={addNewHeaderFormId} onSubmit={onSubmit}>
        <InputContainer>
          <FormControl isInvalid={!!errors.key}>
            <FormLabel>{t('config.iotConnectorProxy.key')}</FormLabel>
            <Input
              size="sm"
              variant="userData"
              {...register('key', {
                validate: (value) =>
                  value?.trim() ? true : t('config.iotConnectorProxy.error.keyRequired'),
              })}
            />
            <FormErrorMessage whiteSpace="pre-line">{errors.key?.message}</FormErrorMessage>
          </FormControl>
        </InputContainer>

        <InputContainer>
          <FormControl isInvalid={!!errors.value}>
            <FormLabel>{t('config.iotConnectorProxy.value')}</FormLabel>

            <InputGroup>
              <Input
                pr="14"
                size="sm"
                variant="userData"
                type={shouldShowValue ? 'text' : 'password'}
                {...register('value', {
                  validate: (value) =>
                    value?.trim() ? true : t('config.iotConnectorProxy.error.valueRequired'),
                })}
              />
              <InputRightElement height="100%" mr="2">
                <ShowSecretButton show={shouldShowValue} setShow={setShouldShowValue} />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage whiteSpace="pre-line">{errors.value?.message}</FormErrorMessage>
          </FormControl>
        </InputContainer>
      </chakra.form>
    </BaseModal>
  );
};
