import type { ComponentStyleConfig } from '@chakra-ui/react';
import { userDataInputStyles } from './input';

export const Textarea: ComponentStyleConfig = {
  baseStyle: {
    _placeholder: {
      color: 'qGray.400',
    },
  },
  sizes: {
    sm: {
      fontSize: 'md',
      borderRadius: 1,
    },
  },
  variants: {
    userData: {
      ...userDataInputStyles,
      paddingX: 4,
      paddingY: 2,
    },
  },
};
