import {
  Box,
  Button,
  chakra,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Heading,
  IconButton,
  Input,
  Tooltip,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { InputContainer, SectionError, SectionLoading, SectionStack } from 'components';
import type { UpdateCompanyAttributesMutationVariables } from 'utils/graphql/hooks';
import {
  useCompanyQuery,
  UserGroup,
  useUpdateCompanyAttributesMutation,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';
import { getCountryNameOfCode } from 'utils/i18n';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

const DisabledInputInfo: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Tooltip label={t('account.company.fieldDisabledMessage')}>
      <Box mb="2" alignSelf="center">
        <InfoIcon />
      </Box>
    </Tooltip>
  );
};

// these fields need to be 100% correct for creation of valid invoices
const criticalFieldsRequiredRole = UserGroup.SystemAdmin;

export const CompanyAccountPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { hasRequiredRole } = useAuthUserContext();
  const { readonlyTitleProp, readonlyDisabledProp } = useAuthUtils();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateCompanyAttributesMutation();
  const { data, isLoading, isError, error } = useCompanyQuery();
  const { company } = data || {};

  const [isInEditMode, setIsInEditMode] = useState<boolean>(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isDirty },
  } = useForm<UpdateCompanyAttributesMutationVariables>({
    defaultValues: company,
  });

  const onSubmit = handleSubmit(
    async ({
      name,
      streetAddress,
      additionalAddressInfo,
      postalCode,
      city,
      country,
      vatId,
      internalReference,
    }) => {
      try {
        if (company) {
          const updatedCompany = await mutateAsync({
            id: company.id,
            streetAddress: streetAddress.trim(),
            additionalAddressInfo: additionalAddressInfo?.trim(),
            postalCode: postalCode.trim(),
            city: city.trim(),
            internalReference: internalReference?.trim(),
            ...(hasRequiredRole(UserGroup.SystemAdmin) && {
              name: name?.trim(),
              // make sure ISO code is in uppercase, just for consistency
              country: country?.trim().toUpperCase(),
              vatId: vatId?.trim(),
            }),
          });

          setIsInEditMode(false);
          successToast({ title: t('common.updateAttributesSuccess') });
          queryClient.setQueryData(useCompanyQuery.getKey(), {
            company: {
              id: company.id,
              ...updatedCompany.updateCompanyAttributes,
            },
            done: true,
          });
        }
      } catch (e) {
        errorToast({
          title: (e instanceof Error && t('common.updateAttributesError')) || t('error.generic'),
          ...(e instanceof Error && { description: e.message }),
        });
      }
    },
  );

  useEffect(() => {
    setIsSubmitDisabled(!isDirty);
  }, [isDirty, setIsSubmitDisabled]);

  useEffect(() => {
    // reset after company query is done
    reset(company);
  }, [reset, company]);

  return (
    <SectionStack type="outer" flex="1">
      <Heading as="h2" size="lg" px="12" py="7" display="flex" justifyContent="space-between">
        {t('account.company.companyAccount')}
        <IconButton
          aria-label={t('company.editCompanyAttributes').toLowerCase()}
          icon={<EditIcon title={readonlyTitleProp || t('common.edit')} />}
          alignSelf="center"
          variant="ghost"
          onClick={() => setIsInEditMode(true)}
          title={readonlyTitleProp}
          isDisabled={readonlyDisabledProp || isInEditMode}
        />
      </Heading>

      {isLoading && <SectionLoading />}
      {isError && (
        <SectionError title={t('account.company.error.companyQuery')} message={error.message} />
      )}

      {!isLoading && !isError && (
        <chakra.form m={[null, 6]} my={[6, null]} onSubmit={onSubmit}>
          <Grid templateColumns={['1fr', null, '1fr 1fr']} {...(!isInEditMode && { pb: '14' })}>
            <InputContainer>
              <FormControl isRequired={isInEditMode && hasRequiredRole(criticalFieldsRequiredRole)}>
                <Flex>
                  <FormLabel mr="2">{t('account.company.companyName')}</FormLabel>
                  {isInEditMode && <DisabledInputInfo />}
                </Flex>
                <Input
                  {...register('name', {
                    validate: (value) =>
                      value?.trim() ? true : t('account.company.error.nameRequired'),
                  })}
                  size="sm"
                  variant="userData"
                  isDisabled={!isInEditMode || !hasRequiredRole(criticalFieldsRequiredRole)}
                />
              </FormControl>
            </InputContainer>

            <InputContainer>
              <FormControl isRequired={isInEditMode} isInvalid={!!errors?.streetAddress}>
                <FormLabel>{t('account.company.streetAddress')}</FormLabel>
                <Input
                  {...register('streetAddress', {
                    validate: (value) =>
                      value?.trim() ? true : t('account.company.error.streetAddressRequired'),
                  })}
                  size="sm"
                  variant="userData"
                  isDisabled={!isInEditMode}
                />
                <FormErrorMessage whiteSpace="pre-line">
                  {errors?.streetAddress?.message}
                </FormErrorMessage>
              </FormControl>
            </InputContainer>

            <InputContainer>
              <FormControl isInvalid={!!errors.additionalAddressInfo}>
                <FormLabel>{t('account.company.additionalAddressInfo')}</FormLabel>
                <Input
                  {...register('additionalAddressInfo')}
                  size="sm"
                  variant="userData"
                  isDisabled={!isInEditMode}
                />
                <FormErrorMessage whiteSpace="pre-line">
                  {errors.additionalAddressInfo?.message}
                </FormErrorMessage>
              </FormControl>
            </InputContainer>

            <InputContainer>
              <FormControl isRequired={isInEditMode} isInvalid={!!errors.postalCode}>
                <FormLabel>{t('account.company.postalCode')}</FormLabel>
                <Input
                  {...register('postalCode', {
                    validate: (value) =>
                      value?.trim() ? true : t('account.company.error.postalCodeRequired'),
                  })}
                  size="sm"
                  variant="userData"
                  isDisabled={!isInEditMode}
                />
                <FormErrorMessage whiteSpace="pre-line">
                  {errors.postalCode?.message}
                </FormErrorMessage>
              </FormControl>
            </InputContainer>

            <InputContainer>
              <FormControl isRequired={isInEditMode} isInvalid={!!errors.city}>
                <FormLabel>{t('account.company.city')}</FormLabel>
                <Input
                  {...register('city', {
                    validate: (value) =>
                      value?.trim() ? true : t('account.company.error.cityRequired'),
                  })}
                  size="sm"
                  variant="userData"
                  isDisabled={!isInEditMode}
                />
                <FormErrorMessage whiteSpace="pre-line">{errors.city?.message}</FormErrorMessage>
              </FormControl>
            </InputContainer>

            <InputContainer>
              <FormControl
                isRequired={isInEditMode && hasRequiredRole(criticalFieldsRequiredRole)}
                isInvalid={!!errors.country}
              >
                <Flex>
                  <FormLabel mr="2">{t('account.company.country')}</FormLabel>
                  {isInEditMode && <DisabledInputInfo />}
                </Flex>
                <Input
                  {...(hasRequiredRole(criticalFieldsRequiredRole) &&
                    register('country', {
                      validate: (value) => {
                        const countryName = value ? getCountryNameOfCode(value) : undefined;

                        return value?.trim() &&
                          typeof countryName === 'string' &&
                          countryName !== value
                          ? true
                          : t('account.company.error.countryRequired');
                      },
                    }))}
                  value={
                    !hasRequiredRole(criticalFieldsRequiredRole) && company?.country
                      ? getCountryNameOfCode(company.country)
                      : undefined
                  }
                  size="sm"
                  variant="userData"
                  isDisabled={!isInEditMode || !hasRequiredRole(criticalFieldsRequiredRole)}
                />
                <FormErrorMessage whiteSpace="pre-line">{errors.country?.message}</FormErrorMessage>
              </FormControl>
            </InputContainer>

            <InputContainer>
              <FormControl
                isRequired={isInEditMode && hasRequiredRole(criticalFieldsRequiredRole)}
                isInvalid={!!errors.vatId}
              >
                <Flex>
                  <FormLabel mr="2">{t('account.company.vatId')}</FormLabel>
                  {isInEditMode && <DisabledInputInfo />}
                </Flex>
                <Input
                  {...register('vatId', {
                    validate: (value) =>
                      value?.trim() ? true : t('account.company.error.vatIdRequired'),
                  })}
                  size="sm"
                  variant="userData"
                  isDisabled={!isInEditMode || !hasRequiredRole(criticalFieldsRequiredRole)}
                />
                <FormErrorMessage whiteSpace="pre-line">{errors.vatId?.message}</FormErrorMessage>
              </FormControl>
            </InputContainer>

            <InputContainer>
              <FormControl isInvalid={!!errors.internalReference}>
                <FormLabel>{t('account.company.internalReference')}</FormLabel>
                <Input
                  {...register('internalReference')}
                  size="sm"
                  variant="userData"
                  isDisabled={!isInEditMode}
                />
                <FormErrorMessage whiteSpace="pre-line">
                  {errors.internalReference?.message}
                </FormErrorMessage>
              </FormControl>
            </InputContainer>
          </Grid>

          {isInEditMode && (
            <Box mt="6">
              <Button
                ml="6"
                mr="12"
                variant="outline"
                onClick={() => {
                  setIsInEditMode(false);
                  reset();
                }}
              >
                {t('common.cancel')}
              </Button>
              <Button
                type="submit"
                isDisabled={isSubmitDisabled}
                isLoading={isLoading}
                loadingText={t('common.submitting')}
              >
                {t('common.submit')}
              </Button>
            </Box>
          )}
        </chakra.form>
      )}
    </SectionStack>
  );
};
