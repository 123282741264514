import { t } from 'i18next';
import type { Maybe, Quota, Sim } from './graphql/hooks';
import { SimState, SimStateReason } from './graphql/hooks';
import { byteToGigaByte } from 'utils';

interface SimStateAttributes extends Pick<Sim, 'state' | 'stateReason'> {}

export const simStatusText = (state: SimState, reason?: Maybe<SimStateReason> | undefined) =>
  state === SimState.Suspended && reason ? t(`sim.stateReason.${reason}`) : t(`sim.state.${state}`);

export const dataVolumeUsedText = (dataVolume: Quota) =>
  dataVolume.limit
    ? t('sim.dataVolume.usedOfLimit', {
        used: byteToGigaByte(dataVolume.count),
        limit: byteToGigaByte(dataVolume.limit),
      })
    : t('sim.dataVolume.usedWithoutLimit', {
        used: byteToGigaByte(dataVolume.count),
      });

export const smsVolumeUsedText = (smsVolume: Quota) =>
  smsVolume.limit
    ? t('sim.smsVolume.usedOfLimit', {
        used: smsVolume.count,
        limit: smsVolume.limit,
      })
    : t('sim.smsVolume.usedWithoutLimit', {
        used: smsVolume.count,
      });

export const userCanActivateSim = (simStateAttributes: SimStateAttributes) =>
  simStateAttributes.state === SimState.Suspended &&
  (simStateAttributes.stateReason === SimStateReason.User ||
    simStateAttributes.stateReason === SimStateReason.ActivationFailed ||
    !simStateAttributes.stateReason);

export const userCanNotActivateSim = (simStateAttributes: SimStateAttributes) =>
  simStateAttributes.state === SimState.Suspended && !userCanActivateSim(simStateAttributes);

export const userCanDeactivateSim = (simStateAttributes: SimStateAttributes) =>
  simStateAttributes.state === SimState.Active ||
  simStateAttributes.state === SimState.ActivationPending;

export const isSimActive = (simStateAttributes: SimStateAttributes) =>
  simStateAttributes.state === SimState.Active ||
  simStateAttributes.state === SimState.SuspendPending;
