import { Box, Center, Heading, Link, Text, VStack } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { t } from 'i18next';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { SectionStack } from './SectionStack';

export const ErrorBoundary: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

  return (
    <Sentry.ErrorBoundary
      // eslint-disable-next-line react/no-unstable-nested-components
      fallback={({ error }) => (
        <Box h="100vh" bg="gray.50">
          <Center h="50vh">
            <SectionStack type="outer" px="7" py="5">
              <VStack>
                <Heading>
                  {t('errorBoundary.unexpectedError', { errorName: error.name }) as string}
                </Heading>
                <Text>{error.message}</Text>
                <br />
                <Text>
                  <Trans i18nKey="errorBoundary.refreshOrContact" values={{ supportEmail }}>
                    <Link
                      href={`mailto:${supportEmail}`}
                      textDecoration="underline"
                      variant="hoverBrand"
                    />
                  </Trans>
                </Text>
              </VStack>
            </SectionStack>
          </Center>
        </Box>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};
