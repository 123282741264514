import {
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, type FunctionComponent } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/ChevronUp.svg';
import { LoadingOverlay, UserRoleRadioGroup } from 'components';
import {
  useUpdateUserGroupMutation,
  type UpdateUserGroupMutationVariables,
  type User,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

const FORM_NAME = 'newGroup';

const iconButtonToggleProps = {
  bgColor: 'black',
  borderColor: 'black',
  _hover: { bgColor: 'black', borderColor: 'black' },
};

interface UserRoleFormProps {
  currentUser: User;
}

export const UserRoleForm: FunctionComponent<UserRoleFormProps> = ({ currentUser }) => {
  const { t } = useTranslation();
  const { isOpen, onToggle, onClose } = useDisclosure();
  const { mutateAsync, isPending } = useUpdateUserGroupMutation();

  const queryClient = useQueryClient();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const {
    handleSubmit,
    register,
    control,
    formState: { isSubmitting },
  } = useForm<Pick<UpdateUserGroupMutationVariables, 'newGroup'>>({
    defaultValues: { newGroup: '' },
  });

  const newGroup = useWatch({
    control,
    name: FORM_NAME,
  });

  const onSubmit = async () => {
    try {
      await mutateAsync({
        username: currentUser.id,
        oldGroup: currentUser.group,
        newGroup,
      });

      await queryClient.refetchQueries({ queryKey: ['UserList'] });
      successToast({ title: t('user.editRoleSuccess') });
    } catch (error) {
      errorToast({
        title: (error instanceof Error && t('user.editRoleError')) || t('error.generic'),
        description: error instanceof Error && error.message,
      });
    }

    onClose();
  };

  useEffect(() => {
    if (newGroup) {
      onSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newGroup]);

  return (
    <Popover placement="bottom-end" isLazy isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <IconButton
          variant="sort"
          size="xxs"
          aria-label={t('user.editRole')}
          icon={
            isOpen ? (
              <ChevronUpIcon color="white" title={t('user.closeRoleEdit')} />
            ) : (
              <ChevronDownIcon title={t('user.openRoleEdit')} />
            )
          }
          onClick={onToggle}
          {...(isOpen && iconButtonToggleProps)}
        />
      </PopoverTrigger>
      <PopoverContent p="5" w="32" as="form" onSubmit={handleSubmit(onSubmit)}>
        {isPending && <LoadingOverlay marginLeft="-5" marginTop="-5" />}
        <UserRoleRadioGroup
          register={register(FORM_NAME)}
          isDisabled={isSubmitting || isPending}
          defaultValue={currentUser.group}
          filter={isPending ? 'blur(2px)' : undefined}
        />
      </PopoverContent>
    </Popover>
  );
};
