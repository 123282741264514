// only used as brand color
const qbeyondBrand = {
  '50': '#FFECE5',
  '100': '#FFCAB8',
  '200': '#FFA78A',
  '300': '#FF855C',
  '400': '#FF622E',
  '500': '#FF4000', // this is the q.beyond orange
  '600': '#CC3300',
  '700': '#992600',
  '800': '#661A00',
  '900': '#330D00',
};

const qbeyondColors = {
  // qbeyond specific colors are at 500 - added a whole palette
  // around it to be flexible with colorSchemes
  qGray: {
    '50': '#F5F6F6',
    '100': '#E2E4E4',
    '200': '#ADB3B3',
    '300': '#797D7D',
    '400': '#565A5A',
    '500': '#191E1E',
    '600': '#151A1A',
    '700': '#0D1111',
    '800': '#080A0A',
    '900': '#000000',
  },
  qGreen: {
    '50': '#E5F3EE',
    '100': '#CAE3DB',
    '200': '#98C8B9',
    '300': '#74B5A1',
    '400': '#3F997D',
    '500': '#007852',
    '600': '#00573C',
    '700': '#00422E',
    '800': '#002F21',
    '900': '#001F16',
  },
  qCyan: {
    '50': '#EBFBFC',
    '100': '#CFF4F7',
    '200': '#B2EDF3',
    '300': '#80E1EB',
    '400': '#44D3E2',
    '500': '#00C3D7',
    '600': '#0096A6',
    '700': '#007C89',
    '800': '#00626C',
    '900': '#004046',
  },
};

export const colors = {
  brand: qbeyondBrand,
  red: {
    '50': '#FEE7EB',
    '100': '#FCBBC6',
    '200': '#FA8FA1',
    '300': '#F7637D',
    '400': '#F53858',
    '500': '#F30C34',
    '600': '#C30929',
    '700': '#92071F',
    '800': '#610515',
    '900': '#31020A',
  },
  gray: {
    '50': '#F2F2F2',
    '100': '#DBDBDB',
    '200': '#C4C4C4',
    '300': '#ADADAD',
    '400': '#969696',
    '500': '#808080',
    '600': '#666666',
    '700': '#4D4D4D',
    '800': '#333333',
    '900': '#1A1A1A',
  },
  lightGreen: '#79992B',
  ...qbeyondColors,
};

export const defaultFontColor = colors.qGray[500];
export const defaultBgColor = colors.qGray[50];
