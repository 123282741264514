import { Container, Spacer, VStack } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { MaintenanceAlert } from '../components/MaintenanceAlert';
import { Footer } from '../Footer';
import { footerItems } from '../Footer/footerItemsConfig';
import { Header } from './components/Header';
import { useBlurContext } from 'utils/provider/BlurProvider';

export const AuthenticatedLayout: FunctionComponent = () => {
  const { isBlurred } = useBlurContext();

  return (
    <VStack spacing={0} minH="100vh" w="full" bg="gray.50">
      <Header />
      <Container
        as="main"
        w="full"
        maxW="fullWidth"
        px="8"
        py="5"
        filter={isBlurred ? 'blur(8px)' : undefined}
      >
        <MaintenanceAlert />
        <Outlet />
      </Container>
      <Spacer />
      <Footer mode="light" items={footerItems} />
    </VStack>
  );
};
