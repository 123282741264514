import { updatePassword } from '@aws-amplify/auth';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  type UseDisclosureProps,
} from '@chakra-ui/react';
import { useState, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseModal, InputContainer, ShowSecretButton } from 'components';
import { InputValidation } from 'utils';
import { useIotSimToast } from 'utils/hooks';

interface ChangePasswordInput {
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;
}

const formId = 'changePasswordForm';

export const ChangePasswordModal: FunctionComponent<UseDisclosureProps> = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const successToast = useIotSimToast({ status: 'success' });

  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showNewPasswordRepeat, setShowNewPasswordRepeat] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    reset,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<ChangePasswordInput>({
    defaultValues: { oldPassword: '', newPassword: '', newPasswordRepeat: '' },
  });

  const onModalClose = () => {
    onClose();
    reset();
  };

  const onSubmit = handleSubmit(async ({ oldPassword, newPassword }) => {
    await updatePassword({ oldPassword, newPassword });
    successToast({ title: t('account.user.changePassword.success') });
    onModalClose();
  });

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onModalClose}
      header={t('account.user.changePassword.title')}
      footer={
        <>
          <Button variant="secondary" onClick={onModalClose}>
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            form={formId}
            isLoading={isSubmitting}
            loadingText={t('account.user.changePassword.changing')}
          >
            {t('account.user.changePassword.title')}
          </Button>
        </>
      }
    >
      <form onSubmit={onSubmit} id={formId}>
        <InputContainer>
          <FormControl isRequired isInvalid={!!errors.oldPassword}>
            <FormLabel htmlFor="password">{t('account.user.changePassword.oldPassword')}</FormLabel>
            <InputGroup>
              <Input
                id="oldPassword"
                type={showOldPassword ? 'text' : 'password'}
                {...register('oldPassword')}
                variant="userData"
                autoFocus
              />
              <InputRightElement minW="16">
                <ShowSecretButton show={showOldPassword} setShow={setShowOldPassword} />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage whiteSpace="pre-line">{errors.oldPassword?.message}</FormErrorMessage>
          </FormControl>
        </InputContainer>

        <InputContainer>
          <FormControl isRequired isInvalid={!!errors.newPassword}>
            <FormLabel htmlFor="password">{t('account.user.changePassword.newPassword')}</FormLabel>
            <InputGroup>
              <Input
                id="newPassword"
                type={showNewPassword ? 'text' : 'password'}
                {...register('newPassword', {
                  validate: (value) =>
                    InputValidation.password.test(value) ||
                    t('account.user.changePassword.requirements'),
                })}
                variant="userData"
              />
              <InputRightElement minW="16">
                <ShowSecretButton show={showNewPassword} setShow={setShowNewPassword} />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage whiteSpace="pre-line">{errors.newPassword?.message}</FormErrorMessage>
          </FormControl>
        </InputContainer>

        <InputContainer>
          <FormControl isRequired isInvalid={!!errors.newPasswordRepeat}>
            <FormLabel htmlFor="passwordRepeat">
              {t('account.user.changePassword.repeatNewPassword')}
            </FormLabel>
            <InputGroup>
              <Input
                id="newPasswordRepeat"
                type={showNewPasswordRepeat ? 'text' : 'password'}
                {...register('newPasswordRepeat', {
                  validate: (value) =>
                    value === getValues('newPassword') || t('auth.passwordMismatch'),
                })}
                variant="userData"
              />
              <InputRightElement minW="16">
                <ShowSecretButton show={showNewPasswordRepeat} setShow={setShowNewPasswordRepeat} />
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{errors.newPasswordRepeat?.message}</FormErrorMessage>
          </FormControl>
        </InputContainer>
      </form>
    </BaseModal>
  );
};
