import type { ChakraTheme } from '@chakra-ui/react';
import { extendTheme } from '@chakra-ui/react';
import { radii } from './borderRadius';
import { breakpoints } from './breakpoints';
import { colors, defaultBgColor, defaultFontColor } from './colors';
import * as components from './components';
import { config } from './config';
import { fonts } from './fonts';
import { shadows } from './shadows';
import { sizes } from './sizes';

export const chakraComponents = components;

export const theme: Partial<ChakraTheme> = extendTheme({
  breakpoints,
  colors,
  components: { ...chakraComponents },
  config,
  fonts,
  radii,
  shadows,
  sizes,
  styles: {
    global: {
      // it is easier to define the base font styles here than in all
      // components that need that style prop, e.g. Text, Heading, Link
      body: { color: defaultFontColor, bgColor: defaultBgColor, fontSize: 'md' },
      button: { outline: 'none' },
    },
  },
});
