import { ArrowDownIcon, ExternalLinkIcon } from '@chakra-ui/icons';
import { Button, SimpleGrid } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { SimulateProxyQuery } from '../../../../../utils/graphql/hooks';

interface SimulateResultProps {
  result: SimulateProxyQuery | undefined;
}
export const SimulateResult: FunctionComponent<SimulateResultProps> = ({ result }) => {
  const { t } = useTranslation();
  const results = useMemo(
    () =>
      `{\n "body":"${result?.simulateProxy.body}",\n"code":"${result?.simulateProxy.code}",\n"media_type":"${result?.simulateProxy.mediaType}",\n}`,
    [result],
  );
  const downloadFile = (type: number) => {
    const fileData = results;
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    if (type === 0) {
      window.open(url, '_blank', 'noreferrer');

      return;
    }
    link.download = 'response.json';
    link.href = url;
    link.click();
  };

  return (
    <SimpleGrid minChildWidth="9rem" spacing="1rem">
      <Button
        rightIcon={<ArrowDownIcon />}
        onClick={() => {
          downloadFile(-1);
        }}
      >
        {t('config.iotConnectorProxy.simulateResult.download')}
      </Button>
      <Button rightIcon={<ExternalLinkIcon />} onClick={() => downloadFile(0)}>
        {t('config.iotConnectorProxy.simulateResult.view')}
      </Button>
    </SimpleGrid>
  );
};
