import { Center, Text, Stack, Box, Link } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { SectionStack } from 'components';

export const WireGuardConfigPagePlaceholder: FunctionComponent = () => {
  const { t } = useTranslation();

  const iotSimConnectEmail = process.env.REACT_APP_IOT_SIM_CONNECT_EMAIL;

  return (
    <Center>
      <SectionStack type="outer">
        <Stack
          direction={['column', 'row']}
          alignItems="center"
          spacing={['2', '4']}
          mx={['6', '12']}
          my="7"
        >
          <Box boxSize="5">
            <InfoIcon />
          </Box>

          <Box>
            <Text>{t('config.wireGuard.availableOnRequest')}</Text>
            <Text>
              <Trans i18nKey="common.pleaseContactEmail" values={{ email: iotSimConnectEmail }}>
                <Link href={`mailto:${iotSimConnectEmail}`} variant="brand" />
              </Trans>
            </Text>
          </Box>
        </Stack>
      </SectionStack>
    </Center>
  );
};
