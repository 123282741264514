import { Link, Text } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ignoreInvalidVatIdCountryCodes, shopSectionStyles } from '../utils';
import { SectionError, SectionStack } from 'components';
import type { Company } from 'utils/graphql/hooks';
import { routes } from 'utils/routes';

interface MissingCompanyAttributeProps {
  company: Company;
}

export const MissingCompanyAttributes: FunctionComponent<MissingCompanyAttributeProps> = ({
  company,
}) => {
  const { t } = useTranslation();

  const isMissingVatId = !company.vatId && !ignoreInvalidVatIdCountryCodes.has(company.country);
  const isMissingCountry = !company.country;

  const missingAttributes = [
    isMissingVatId ? t('account.company.vatId') : '',
    isMissingCountry ? t('account.company.country').toLowerCase() : '',
  ].filter(Boolean); // filter empty strings, i18n list does not ignore empty string or undefined

  return (
    <SectionStack flex="1" type="outer" {...shopSectionStyles} mx="auto">
      <SectionError
        title={t('shopCheckout.error.missingCompanyAttributeTitle', { missingAttributes })}
      >
        <Text whiteSpace="pre-line" maxW={[null, null, null, '80%']}>
          <Trans
            i18nKey="shopCheckout.error.missingCompanyAttributeDescription"
            values={{
              missingAttributes,
              additionalVatIdDescription: isMissingVatId
                ? t('shopCheckout.error.additionalVatIdDescription')
                : undefined,
            }}
          >
            <Link href={routes.account.support} textDecoration="underline" variant="hoverBrand" />
          </Trans>
        </Text>
      </SectionError>
    </SectionStack>
  );
};
