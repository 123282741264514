import { Center, Flex, Table, Tbody, Td, Th, Thead, Tr, type TableProps } from '@chakra-ui/react';
import {
  type Cell,
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  flexRender,
  getSortedRowModel,
  type SortingState,
  type Column,
} from '@tanstack/react-table';
import {
  useCallback,
  useMemo,
  useReducer,
  type FunctionComponent,
  type Reducer,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  UserActionConfirmationModal,
  UserActionsCell,
  UserGroupCell,
  UserStatusCell,
} from './components';
import { EmptyTableMessage, SectionLoading, SortButton } from 'components';
import { useUserListQuery, type User, type Maybe } from 'utils/graphql/hooks';

export interface UsersTableRow extends Omit<User, 'company'> {}

const initialSortBy: SortingState = [{ id: 'email', desc: false }];

const filterUsers = (users: UsersTableRow[], filterValue: string) => {
  const caseInsensitiveFilterValue = filterValue.toLowerCase();

  return users.filter(
    (user) =>
      user.state.toLowerCase().startsWith(caseInsensitiveFilterValue) ||
      user.email.toLowerCase().includes(caseInsensitiveFilterValue) ||
      user.givenName?.toLowerCase().includes(caseInsensitiveFilterValue) ||
      user.familyName?.toLowerCase().includes(caseInsensitiveFilterValue) ||
      user.group?.toLowerCase().includes(`company_${caseInsensitiveFilterValue}`),
  );
};

type UserActionType = 'delete' | 'resendInvitation' | undefined;

export interface SelectedUserActionState {
  user?: UsersTableRow;
  actionType?: UserActionType;
  isModalOpen: boolean;
}

export type UserReduceAction =
  | { type: 'delete'; user: UsersTableRow }
  | { type: 'resendInvitation'; user: UsersTableRow }
  | { type: 'close' };

const initialSelectedUserAction = { isModalOpen: false };

const reducer: Reducer<SelectedUserActionState, UserReduceAction> = (
  _prevState: SelectedUserActionState,
  action: UserReduceAction,
) => {
  switch (action.type) {
    case 'delete':
      return { actionType: action.type, user: action.user, isModalOpen: true };
    case 'resendInvitation':
      return { actionType: action.type, user: action.user, isModalOpen: true };
    case 'close':
    default:
      return initialSelectedUserAction;
  }
};

interface UsersTableProps extends TableProps {
  filterValue: string;
}

export const UsersTable: FunctionComponent<UsersTableProps> = ({ filterValue, ...rest }) => {
  const { t } = useTranslation();
  const { data, isLoading, isError } = useUserListQuery();
  const [selectedUserAction, dispatch] = useReducer(reducer, initialSelectedUserAction);

  const tableData = useMemo<UsersTableRow[]>(
    () =>
      data?.userList && filterValue
        ? filterUsers(data.userList, filterValue)
        : data?.userList || [],
    [data?.userList, filterValue],
  );

  const headerCell = useCallback(
    (header: string, column: Column<UsersTableRow, Maybe<string> | undefined>) => (
      <>
        {header}
        <SortButton
          isSorted={!!column.getIsSorted()}
          isSortedDesc={column.getIsSorted() === 'desc'}
          isDisabled={isLoading}
          onClick={column.getToggleSortingHandler()}
        />
      </>
    ),
    [isLoading],
  );

  const statusCell = useCallback(
    (cell: Cell<UsersTableRow, string>) => <UserStatusCell cell={cell} dispatch={dispatch} />,
    [],
  );

  const groupCell = useCallback(
    (cell: Cell<UsersTableRow, string>) => <UserGroupCell cell={cell} />,
    [],
  );

  const actionsCell = useCallback(
    (cell: Cell<UsersTableRow, unknown>) => <UserActionsCell cell={cell} dispatch={dispatch} />,
    [],
  );

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<UsersTableRow>();

    return [
      columnHelper.accessor('state', {
        id: 'state',
        header: ({ column }) => headerCell(t('common.state'), column),
        cell: ({ cell }) => statusCell(cell),
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: ({ column }) => headerCell(t('common.email'), column),
      }),
      columnHelper.accessor('givenName', {
        id: 'givenName',
        header: ({ column }) => headerCell(t('user.firstName'), column),
      }),
      columnHelper.accessor('familyName', {
        id: 'familyName',
        header: ({ column }) => headerCell(t('user.lastName'), column),
      }),
      columnHelper.accessor('group', {
        id: 'group',
        header: ({ column }) => headerCell(t('common.role'), column),
        cell: ({ cell }) => groupCell(cell),
      }),
      columnHelper.display({
        id: 'actions',
        cell: ({ cell }) => actionsCell(cell),
      }),
    ];
  }, [actionsCell, groupCell, headerCell, statusCell, t]);

  const [sorting, setSorting] = useState<SortingState>(initialSortBy);

  const table = useReactTable({
    columns,
    data: tableData,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    enableMultiSort: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isError) {
    return <Center py="20">{t('error.generic')}</Center>;
  }

  return (
    <>
      <UserActionConfirmationModal
        user={selectedUserAction.user}
        actionType={selectedUserAction.actionType}
        isModalOpen={selectedUserAction.isModalOpen}
        onClose={() => dispatch({ type: 'close' })}
      />

      <Table {...rest}>
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  <Flex alignItems="center">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
      {isLoading && <SectionLoading />}
      {!isLoading && !tableData.length && <EmptyTableMessage />}
    </>
  );
};
