import { FormControl, FormErrorMessage, FormLabel, Input, Stack, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import type { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { InputContainer } from 'components';
import { InputValidation } from 'utils';
import {
  useVerifyUserAttributeMutation,
  type VerifyUserAttributeMutationVariables,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

interface VerifyEmailModalContentProps {
  formId: string;
  setFormIsSubmitting: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}

export const VerifyEmailModalContent: FunctionComponent<VerifyEmailModalContentProps> = ({
  formId,
  setFormIsSubmitting,
  onClose,
}) => {
  const { t } = useTranslation();
  const { mutateAsync } = useVerifyUserAttributeMutation();
  const { refetchUser } = useAuthUserContext();

  const queryClient = useQueryClient();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<VerifyUserAttributeMutationVariables>({
    defaultValues: { attributeName: 'email', code: '' },
  });

  useEffect(() => {
    setFormIsSubmitting(isSubmitting);
  }, [isSubmitting, setFormIsSubmitting]);

  const onSubmit = handleSubmit(async ({ attributeName, code }) => {
    try {
      await mutateAsync({ attributeName, code });

      successToast({ title: t('account.user.changeEmail.success') });

      await refetchUser();
      await queryClient.invalidateQueries({ queryKey: ['UserList'] });
      onClose();
    } catch (error) {
      errorToast({
        title: (error instanceof Error && t('common.updateAttributesError')) || t('error.generic'),
        ...(error instanceof Error && { description: error.message }),
      });
    }
  });

  return (
    <Stack as="form" id={formId} px="6" spacing="4" onSubmit={onSubmit}>
      <Text>{t('account.user.changeEmail.verifyEmailMessage')}</Text>
      <InputContainer>
        <FormControl isRequired isInvalid={!!errors.code}>
          <FormLabel htmlFor="code">{t('account.user.changeEmail.verificationCode')}</FormLabel>
          <Input
            id="code"
            type="number"
            {...register('code', {
              validate: (value: string) =>
                InputValidation.code.test(value) || t('auth.error.codeLength'),
            })}
            variant="userData"
            autoFocus
          />
          <FormErrorMessage>{errors.code?.message}</FormErrorMessage>
        </FormControl>
      </InputContainer>
    </Stack>
  );
};
