import type { Company } from 'utils/graphql/hooks';

export const MALTESE_VAT_RATE = 0.18;

export const vatRate = (country: string) => {
  switch (country) {
    // WooCommerce says it uses ISO 3166-1 alpha-2 codes but in reality it uses on some countries
    // their names depending on the shops language, see:
    // https://woocommerce.github.io/woocommerce-rest-api-docs/#order-billing-properties
    case 'Malta': // DE + EN
    case 'MT': // ISO 3166-1 alpha-2
    case 'MLT': // ISO 3166-1 alpha-3
      return MALTESE_VAT_RATE;

    // all other countries are not charged VAT because of tax-free intra-community supply,
    // which means we sell only to countries in the EU
    default:
      return 0;
  }
};

// on this countries we do not collect the VAT ID on the shop, so we ignore the validation
export const ignoreInvalidVatIdCountryCodes = new Set<Company['vatId']>(['CH']);

export const shopSectionStyles = {
  borderRadius: '2xl',
  px: '10',
  py: '7',
  w: 'full',
  maxW: '1140px',
  alignSelf: 'center',
  divider: undefined,
};
