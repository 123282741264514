import { Grid, HStack, Image, Text, Tooltip, VStack } from '@chakra-ui/react';
import i18next from 'i18next';
import { type FunctionComponent } from 'react';
import infoIcon from 'assets/icons/Info.svg';
import { BlockClickEventPropagation } from 'components';

const SessionTooltipLabel: FunctionComponent = () => (
  <VStack>
    <Grid gap="1" templateColumns="1fr auto">
      <Text fontWeight="bold" color="green.500">{`${i18next
        .t('sim.session.online')
        .toString()}:`}</Text>
      <Text>{i18next.t('sim.session.onlineInfo').toString()}</Text>
      <Text fontWeight="bold" color="red.500">{`${i18next
        .t('sim.session.offline')
        .toString()}:`}</Text>
      <Text>{i18next.t('sim.session.offlineInfo').toString()}</Text>
      <Text fontWeight="bold" color="qGray.100">{`${i18next
        .t('sim.session.unknown')
        .toString()}:`}</Text>
      <Text>{i18next.t('sim.session.unknownInfo').toString()}</Text>
    </Grid>
  </VStack>
);

export const SessionColumnHeader: FunctionComponent = () => (
  <HStack>
    <Text>{i18next.t('sim.col.session').toString()}</Text>
    {process.env.REACT_APP_SESSION_INFO_TOOLTIP === 'true' && (
      <BlockClickEventPropagation>
        <Tooltip label={<SessionTooltipLabel />}>
          <Image src={infoIcon} cursor="initial" minW="5" alt="info" />
        </Tooltip>
      </BlockClickEventPropagation>
    )}
  </HStack>
);
