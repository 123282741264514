import { ArrowUpIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Button,
  chakra,
  HStack,
  IconButton,
  Input,
  Select,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import type { Dispatch, FunctionComponent, ChangeEvent } from 'react';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CoapRequest } from '../../../../../utils/graphql/hooks';

const mediaType = new Map<string, number>([
  ['Text Plain', 0],
  ['App Cose Encrypt0', 96],
  ['App Cose Mac0', 97],
  ['App Cose Sign1', 98],
  ['App Link Format', 40],
  ['App XML', 41],
  ['App Octets', 42],
  ['App Exi', 47],
  ['App JSON', 50],
  ['App JSON Patch', 51],
  ['App JSON Merge Patch', 52],
  ['App CBOR', 60],
  ['App CWT', 61],
  ['App Cose Encrypt', 96],
  ['App Cose Mac', 97],
  ['App Cose Sign', 98],
  ['App Cose Key', 101],
  ['App Cose Key Set', 102],
  ['App Senml JSON', 110],
  ['App Senml Cbor', 112],
  ['App Coap Group', 256],
  ['App Senml Etch JSON', 320],
  ['App Senml Etch Cbor', 322],
  ['App Ocf Cbor', 10000],
  ['App Lwm2m TLV', 11542],
  ['App Lwm2m JSON', 11543],
  ['App Lwm2m Cbor', 11544],
]);

const methods = new Map<string, number>([
  ['GET', 1],
  ['POST', 2],
  ['PUT', 3],
  ['DELETE', 4],
]);

interface CoapType {
  coapRequestInput: CoapRequest;
  setConfig: Dispatch<React.SetStateAction<CoapRequest>>;
}

interface CoapRequestConfigProps {
  coapType: CoapType;
}

const getFileName = (targetFile: File) => {
  const fileName = targetFile.name;
  if (fileName.length > 20) {
    const fileType = targetFile.type;

    return `${fileName.substring(0, 20)}...${fileType.substring(
      fileType.indexOf('/') + 1,
      fileType.length,
    )}`;
  }

  return fileName;
};

export const CoapRequestConfig: FunctionComponent<CoapRequestConfigProps> = ({ coapType }) => {
  const { t } = useTranslation();
  const [file, setFile] = useState<File>();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const targetFile = e.target.files[0];
    setFile(targetFile);

    const reader = new FileReader();
    reader.readAsText(targetFile);
    reader.onload = () => {
      if (reader.result !== null) {
        if (typeof reader.result === 'string') {
          coapType.setConfig({
            ...coapType.coapRequestInput,
            body: reader.result.split(',')[1],
          });
        }
      }
    };
  };

  return (
    <VStack spacing={3}>
      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Text width="10rem" fontSize="xl">
          {t('config.iotConnectorProxy.coapRequest')}
        </Text>
      </HStack>
      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <chakra.label width="10rem" htmlFor="Path">
          {t('config.iotConnectorProxy.coapRequestConfig.path')}
        </chakra.label>
        <Input
          placeholder="Path"
          width="17rem"
          value={coapType.coapRequestInput.path}
          onChange={(e) => {
            coapType.setConfig({
              ...coapType.coapRequestInput,
              path: e.target.value,
            });
          }}
        />
      </HStack>

      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <chakra.label width="10rem" htmlFor="Method">
          {t('config.iotConnectorProxy.coapRequestConfig.method')}
        </chakra.label>

        <Select
          placeholder="Method"
          variant="outline"
          width="17rem"
          onChange={(e) => {
            coapType.setConfig({
              ...coapType.coapRequestInput,
              code: Number(e.target.value),
            });
          }}
        >
          {[...methods.entries()].map(([key, value]) => (
            <option key={key} value={value}>
              {key}
            </option>
          ))}
        </Select>
      </HStack>
      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <chakra.label width="10rem" htmlFor="Media Type">
          {t('config.iotConnectorProxy.coapRequestConfig.mediaType')}
        </chakra.label>
        <Select
          placeholder="Media Type"
          variant="outline"
          width="17rem"
          onChange={(e) => {
            coapType.setConfig({
              ...coapType.coapRequestInput,
              media_type: Number(e.target.value),
            });
          }}
        >
          {[...mediaType.entries()].map(([key, value]) => (
            <option key={key} value={value}>
              {key}
            </option>
          ))}
        </Select>
      </HStack>

      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <chakra.label width="10rem" htmlFor="body">
          {t('config.iotConnectorProxy.coapRequestConfig.body')}
        </chakra.label>

        {file ? (
          <Stack flexDirection="row" alignItems="center" justifyContent="center">
            <Text>{getFileName(file)}</Text>
            <IconButton
              onClick={() => {
                setFile(undefined);
              }}
              aria-label={t('config.iotConnectorProxy.removeFileArialLabel')}
              variant="unstyled"
              size="xs"
              icon={<CloseIcon />}
            />
          </Stack>
        ) : (
          <Button width="17rem" rightIcon={<ArrowUpIcon />} onClick={handleUploadClick}>
            {t('config.iotConnectorProxy.uploadBody')}
          </Button>
        )}
        <Input type="file" onChange={handleFileChange} ref={inputRef} style={{ display: 'none' }} />
      </HStack>
    </VStack>
  );
};
