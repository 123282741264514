import { Authenticator, ThemeProvider as AuthenticatorThemeProvider } from '@aws-amplify/ui-react';
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Amplify } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SSHConfigurationPage } from './pages/ConfigurationsPage/SSHConfigurationPage/SSHConfigurationPage';
import { BlurProvider } from './utils/provider/BlurProvider';
import { AuthenticatedLayout, AuthenticatedRoute, Fonts, UnauthenticatedLayout } from 'components';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { AccountPage } from 'pages/AccountPage';
import { CompanyAccountPage } from 'pages/AccountPage/CompanyAccount';
import { SupportPage } from 'pages/AccountPage/Support';
import { UserAccountPage } from 'pages/AccountPage/UserAccount';
import { ConfigurationsPage } from 'pages/ConfigurationsPage';
import { IotConnectorAzureConfigurationPage } from 'pages/ConfigurationsPage/IotConnectorAzureConfigurationPage';
import { IotConnectorProxyConfigurationPage } from 'pages/ConfigurationsPage/IotConnectorProxyConfigurationPage';
import { OpenVpnConfigurationPage } from 'pages/ConfigurationsPage/OpenVpnConfigurationPage';
import { WireGuardConfigurationPage } from 'pages/ConfigurationsPage/WireGuardConfigurationPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { ScannedSimPage } from 'pages/ScannedSimPage';
import { ShopCheckoutPage } from 'pages/ShopCheckoutPage';
import { SimCardRegisterPage } from 'pages/SimCardRegisterPage';
import { SimCardsPage } from 'pages/SimCardsPage';
import { UsersPage } from 'pages/UsersPage';
import { getConfig, type Configuration } from 'utils';
import { authenticatorTheme, authenticatorComponents } from 'utils/authenticatorUtils';
import { AuthUserProvider } from 'utils/provider/AuthUserProvider';
import { SelectedSimCardProvider } from 'utils/provider/SelectedSimProvider';
import { ShopCheckoutProvider } from 'utils/provider/ShopCheckoutProvider';
import { routes } from 'utils/routes';
import { theme } from 'utils/theme';
import '@aws-amplify/ui-react/styles.css';
import './utils/i18n';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  const [configuration, setConfiguration] = useState<Configuration | null>(null);

  useEffect(() => {
    if (!configuration) {
      (async () => setConfiguration(await getConfig()))();
    }
  }, [configuration]);

  if (configuration) {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: configuration.userpoolId,
          userPoolClientId: configuration.userpoolClientId,
        },
      },
      API: {
        GraphQL: {
          endpoint: configuration.apiEndpoint,
          defaultAuthMode: 'userPool',
        },
      },
    });
  }

  if (!configuration) {
    return null;
  }

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <ColorModeProvider options={{ initialColorMode: 'light' }}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <BlurProvider>
              <BrowserRouter>
                <Routes>
                  <Route element={<UnauthenticatedLayout />}>
                    {/* these Routes are public */}
                    <Route path={routes.sim} element={<ScannedSimPage />} />
                  </Route>

                  <Route
                    element={
                      <AuthenticatorThemeProvider theme={authenticatorTheme}>
                        <Authenticator
                          loginMechanisms={['email']}
                          components={authenticatorComponents}
                          hideSignUp
                        >
                          <AuthUserProvider>
                            <SelectedSimCardProvider>
                              <ShopCheckoutProvider>
                                <AuthenticatedRoute>
                                  <AuthenticatedLayout />
                                </AuthenticatedRoute>
                              </ShopCheckoutProvider>
                            </SelectedSimCardProvider>
                          </AuthUserProvider>
                        </Authenticator>
                      </AuthenticatorThemeProvider>
                    }
                  >
                    <Route path={routes.simCards} element={<SimCardsPage />} />
                    <Route path={routes.shopCheckout} element={<ShopCheckoutPage />} />
                    <Route path={routes.root} element={<Navigate to={routes.simCards} replace />} />
                    <Route path={routes.simCardRegister} element={<SimCardRegisterPage />} />

                    <Route element={<ConfigurationsPage />}>
                      <Route
                        path={routes.config.root}
                        element={<Navigate to={routes.config.iotConnectorProxy} replace />}
                      />
                      <Route
                        path={routes.config.iotConnectorProxy}
                        element={<IotConnectorProxyConfigurationPage />}
                      />
                      <Route
                        path={routes.config.iotConnectorAzure}
                        element={<IotConnectorAzureConfigurationPage />}
                      />
                      <Route path={routes.config.openVpn} element={<OpenVpnConfigurationPage />} />
                      <Route
                        path={routes.config.wireGuard}
                        element={<WireGuardConfigurationPage />}
                      />
                      <Route path={routes.config.ssh} element={<SSHConfigurationPage />} />
                    </Route>

                    <Route path={routes.users} element={<UsersPage />} />

                    <Route element={<AccountPage />}>
                      <Route path={routes.account.root} element={<UserAccountPage />} />
                      <Route path={routes.account.support} element={<SupportPage />} />
                      <Route
                        path={routes.account.companyAccount}
                        element={<CompanyAccountPage />}
                      />
                    </Route>

                    <Route
                      path={routes.login}
                      element={<Navigate to={routes.simCards} replace />}
                    />
                    <Route path="*" element={<NotFoundPage />} />
                  </Route>
                </Routes>
              </BrowserRouter>
            </BlurProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ColorModeProvider>
    </ChakraProvider>
  );
};
