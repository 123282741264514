import { Button, Stack, Text } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ScannedSimCardOverview, SimCardImage, UnauthenticatedCard } from 'components';
import { useSimActivationUrlParams } from 'utils/hooks';
import { routes } from 'utils/routes';

export const ScannedSimPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { iccid } = useParams();
  const [searchParams] = useSearchParams();
  if (iccid) {
    searchParams.set('iccid', iccid);
  }
  const { simAmount, isEmbeddedSim } = useSimActivationUrlParams();

  return (
    <UnauthenticatedCard
      title={t('common.mySimCard', { count: simAmount })}
      w={['90%', null, 'full']}
    >
      <Stack align="center">
        <SimCardImage isEmbeddedSim={isEmbeddedSim} />
        <ScannedSimCardOverview />
        <Text pt="12" pb="6">
          {t('scannedSim.moreDetails')}
        </Text>
        <Button
          variant="primary"
          onClick={() =>
            navigate({
              pathname: routes.simCardRegister,
              search: searchParams.toString(),
            })
          }
        >
          {t('scannedSim.toPortal')}
        </Button>
      </Stack>
    </UnauthenticatedCard>
  );
};
