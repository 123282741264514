import { Box, Center, VStack } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { UnauthenticatedHeader } from './components';
import { SectionStack } from 'components/SectionStack';
import { defaultBgColor } from 'utils/theme/colors';

export const UnauthenticatedLayout: FunctionComponent = () => (
  <VStack spacing="0" bg={defaultBgColor} minH="100vh">
    <Box mx="auto" w="full" maxW="xl" pt={['14', null, '28']}>
      <UnauthenticatedHeader />
      <SectionStack type="outer" bg={defaultBgColor}>
        <Center flex="1" flexDir="column">
          <Outlet />
        </Center>
      </SectionStack>
    </Box>
  </VStack>
);
