import { Button, Center, HStack, Text } from '@chakra-ui/react';
import { useCallback, useEffect, useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeDataVolumePredicateMutation } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useSelectedSimCardsContext } from 'utils/provider/SelectedSimProvider';

interface MassExceedVolumeToggleProps {
  onClose: () => void;
}

export const MassExceedVolumeToggle: FunctionComponent<MassExceedVolumeToggleProps> = ({
  onClose,
}) => {
  const { t } = useTranslation();
  const { selectedSimCards, updateSelectedSimCard } = useSelectedSimCardsContext();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const [canExceedCount, setCanExceedCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: toggleDataVolumeExceed } = useChangeDataVolumePredicateMutation();

  useEffect(() => {
    setCanExceedCount(selectedSimCards.filter((c) => c.canExceedDataVolume).length);
  }, [selectedSimCards]);

  const setAllExceedVolume = useCallback(
    async (canExceedDataVolume: boolean) => {
      setIsLoading(true);
      const res = await Promise.allSettled(
        selectedSimCards
          .filter((c) => c.canExceedDataVolume !== canExceedDataVolume)
          .map(async (sim) => {
            await toggleDataVolumeExceed({ iccid: sim.iccid });
            updateSelectedSimCard({ ...sim, canExceedDataVolume });
          }),
      );

      setIsLoading(false);

      const successCount = res.filter(({ status }) => status === 'fulfilled').length;
      const failedCount = res.filter(({ status }) => status === 'rejected').length;

      if (!failedCount) {
        successToast({
          title: t('sim.bulk.exceedVolumeChangeSuccess', { count: successCount }),
        });
        onClose();
      } else {
        errorToast({
          title: t('sim.bulk.exceedVolumeChangeFailed', { failedCount, count: res.length }),
        });
      }
    },
    [
      errorToast,
      onClose,
      selectedSimCards,
      successToast,
      t,
      toggleDataVolumeExceed,
      updateSelectedSimCard,
    ],
  );

  return (
    <>
      <Text>{t('sim.bulk.toggleExceedVolumeSelection', { count: selectedSimCards.length })}</Text>

      <Center maxW="28">
        <HStack>
          <Button
            onClick={() => setAllExceedVolume(false)}
            isLoading={isLoading}
            variant={canExceedCount === selectedSimCards.length ? 'primary' : 'secondary'}
          >
            {t('sim.bulk.denyAll')}
          </Button>
          <Button
            onClick={() => setAllExceedVolume(true)}
            isLoading={isLoading}
            variant={canExceedCount === 0 ? 'primary' : 'secondary'}
          >
            {t('sim.bulk.allowAll')}
          </Button>
        </HStack>
      </Center>
      <Text as="i" fontSize="xs">
        {t('sim.bulk.exceedVolumeInfo', { count: selectedSimCards.length, canExceedCount })}
      </Text>
    </>
  );
};
