import { HStack, Text, Tooltip } from '@chakra-ui/react';
import i18next from 'i18next';
import { type FunctionComponent } from 'react';
import { BlockClickEventPropagation, SimSessionIndicator } from 'components';
import { formatToDateTimeString, minifiedDateTimeDifference } from 'utils/dateUtils';
import type { Sim } from 'utils/graphql/hooks';
import { SimSession } from 'utils/graphql/hooks';

export const SessionColumnAccessor: FunctionComponent<Sim> = ({ session }) => {
  // TODO(ABQ-195): remove dummy date
  const dummyDate = '2022-09-26 11:11:11.123';

  return (
    <HStack>
      <BlockClickEventPropagation>
        <Tooltip label={i18next.t(`sim.session.${session}`).toString()}>
          <SimSessionIndicator cursor="initial" session={session} />
        </Tooltip>
      </BlockClickEventPropagation>
      {session === SimSession.Offline && (
        <BlockClickEventPropagation>
          <Tooltip label={formatToDateTimeString(dummyDate)}>
            <Text cursor="initial">
              {i18next
                .t('sim.session.lastOnline', {
                  lastOnline: minifiedDateTimeDifference(dummyDate),
                })
                .toString()}
            </Text>
          </Tooltip>
        </BlockClickEventPropagation>
      )}
    </HStack>
  );
};
