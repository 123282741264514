import type { Theme } from '@aws-amplify/ui-react';
import type { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import { I18n } from 'aws-amplify/utils';
import { enAuthenticatorTranslations } from './i18n/en';
import { colors } from './theme/colors';
import { UnauthenticatedHeader } from 'components/layout/UnauthenticatedLayout/components';

// see https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#labels--text
I18n.putVocabulariesForLanguage('en', {
  ...enAuthenticatorTranslations,
});

// see https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#headers--footers
export const authenticatorComponents: DefaultComponents = {
  Header() {
    return <UnauthenticatedHeader />;
  },
};

export const authenticatorTheme: Theme = {
  name: 'q-auth-theme',
  tokens: {
    components: {
      authenticator: {
        router: {
          borderWidth: '0',
        },
      },
      button: {
        primary: {
          backgroundColor: { value: colors.qGray[500] },

          _hover: {
            backgroundColor: { value: colors.brand[500] },
          },
        },
        link: {
          color: { value: colors.qGray[500] },
          _hover: {
            color: { value: colors.qGray[300] },
            backgroundColor: { value: 'white' },
          },
        },
      },
    },
    fonts: {
      default: {
        variable: { value: 'Brix Sans, sans-serif' },
        static: { value: 'Brix Sans, sans-serif' },
      },
    },
  },
};
