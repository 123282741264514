import { FormControl, FormLabel, Spinner, Stack, Switch } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useChangeDataVolumePredicateMutation } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

interface ExceedDataVolumeProps {
  iccid: string;
  canExceedDataVolume: boolean;
  changeSimState: (value: boolean) => void;
}

export const ExceedDataVolume: FunctionComponent<ExceedDataVolumeProps> = ({
  iccid,
  canExceedDataVolume,
  changeSimState,
}) => {
  const { t } = useTranslation();
  const errorToast = useIotSimToast({ status: 'error' });
  const successToast = useIotSimToast({ status: 'success' });

  const [enabled, setEnabled] = useState(canExceedDataVolume);

  const { mutateAsync: enableDataVolumeExceed, isPending: isChanging } =
    useChangeDataVolumePredicateMutation();

  const changeExceedDataVolumePredicate = async () => {
    try {
      await enableDataVolumeExceed(
        { iccid },
        {
          onError: () => {
            errorToast({
              title: enabled
                ? t('sim.exceedDataVolumeDisableFailed')
                : t('sim.exceedDataVolumeEnableFailed'),
            });
          },
          onSuccess: () => {
            changeSimState(!enabled);
            setEnabled((value) => !value);
            successToast({
              title: enabled ? t('sim.exceedDataVolumeDisable') : t('sim.exceedDataVolumeEnabled'),
            });
          },
        },
      );
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  return (
    <FormControl>
      <Stack justifyContent="center" alignContent="center" direction="row">
        <FormLabel>{t('sim.enableExceedDataVolumeLabel')}</FormLabel>
        {isChanging && <Spinner size="md" />}
        {!isChanging && (
          <Switch
            data-testid="switch-id"
            isChecked={enabled}
            onChange={changeExceedDataVolumePredicate}
            colorScheme="gray"
            size="md"
          />
        )}
      </Stack>
    </FormControl>
  );
};
