import {
  Center,
  Divider,
  InputGroup,
  NumberInput,
  NumberInputField,
  Text,
  VStack,
} from '@chakra-ui/react';
import { type FunctionComponent, type RefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { MassExceedVolumeToggle } from './MassExceedVolumeToggle';
import { CustomNumberInputStepper, SelectedIccidsAccordion } from 'components';
import { type Sim, UserGroup, ShopProductSku } from 'utils/graphql/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';
import { useShopCheckoutContext } from 'utils/provider/ShopCheckoutProvider';

interface VolumeReloadModalContentProps {
  simCards: Sim[];
  inputRef: RefObject<HTMLInputElement>;
  onClose: () => void;
}

export const VolumeReloadModalContent: FunctionComponent<VolumeReloadModalContentProps> = ({
  simCards,
  inputRef,
  onClose,
}) => {
  const { t } = useTranslation();
  const { hasRequiredRole } = useAuthUserContext();
  const { prefetchShopCheckoutPageData } = useShopCheckoutContext();

  prefetchShopCheckoutPageData(ShopProductSku.Datavolume_1gb);

  return (
    <VStack spacing={4}>
      <Text pb="2">{t('sim.bulk.reloadSelection', { count: simCards.length })}</Text>

      <Center maxW="28">
        <InputGroup pr="2" variant="userData">
          <NumberInput step={1} min={1} max={999} defaultValue={1}>
            <NumberInputField h="8" ref={inputRef} />
            <CustomNumberInputStepper />
          </NumberInput>
        </InputGroup>
        <Text fontWeight="bold">GB</Text>
      </Center>

      {hasRequiredRole(UserGroup.SystemAdmin) && (
        <>
          <Divider />
          <MassExceedVolumeToggle onClose={onClose} />
          <Divider />
        </>
      )}

      <SelectedIccidsAccordion
        pt="4"
        iccids={simCards.map((c) => c.iccid)}
        accordionButtonContent={t('sim.bulk.showIccids', { count: simCards.length })}
      />
    </VStack>
  );
};
