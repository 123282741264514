import { Box, Button, Text } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal } from './BaseModal';
import { SectionLoading } from './SectionLoading';
import { useUpdateSubscriptionMutation } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { defaultFontColor } from 'utils/theme/colors';

const unzerApiKey = process.env.REACT_APP_UNZER_PUB_KEY;

const unzerInputIdPrefix = 'card-element-id-';
const unzerInputClassName = 'unzerInput';

enum InputType {
  NUMBER = 'number',
  EXPIRY = 'expiry',
  CVC = 'cvc',
  HOLDER = 'holder',
}

const numberElementId = `${unzerInputIdPrefix}${InputType.NUMBER}`;
const expiryDateElementId = `${unzerInputIdPrefix}${InputType.EXPIRY}`;
const cvcElementId = `${unzerInputIdPrefix}${InputType.CVC}`;
const holderElementId = `${unzerInputIdPrefix}${InputType.HOLDER}`;
const errorHolderId = 'card-form-error-holder';

interface CardInputEvent {
  type?: InputType;
  success?: boolean;
}

type FormValidationConditions = {
  [key in InputType]?: boolean;
};

interface UpdateCreditCardModalProps {
  isOpen: boolean;
  onClose: () => void;
  subscriptionId: string;
}

// Unzer documentation for using their UI components can be found here: https://docs.unzer.com/payment-methods/card/accept-card-ui-component/
// code for init of the form is partially from the page source of the unzer example: https://sbx-static.unzer.com/demo/resources/cardholder.html
// test data for the sandbox env: https://docs.unzer.com/reference/test-data/#credit-card
export const UpdateCreditCardModal: FunctionComponent<UpdateCreditCardModalProps> = ({
  isOpen,
  onClose,
  subscriptionId,
}) => {
  const { t } = useTranslation();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const { mutateAsync } = useUpdateSubscriptionMutation();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isFormInvalid, setIsFormInvalid] = useState<boolean>(true);
  const [unzerError, setUnzerError] = useState<Error | null>(null);

  const unzerInstanceRef = useRef<any>(null);
  const cardRef = useRef<any>(null);

  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = 'https://static.unzer.com/v1/unzer.css';
    document.head.appendChild(link);

    const script = document.createElement('script');
    script.src = 'https://static.unzer.com/v1/unzer.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // cast to any to ignore TS error
      const windowAny = window as any;

      // make sure constructor exists
      if (typeof windowAny.unzer !== 'function') {
        setUnzerError(new Error('Payment provider class not defined'));

        return;
      }

      try {
        if (unzerInstanceRef.current === null) {
          // eslint-disable-next-line new-cap
          unzerInstanceRef.current = new windowAny.unzer(unzerApiKey);
        }

        if (cardRef.current === null) {
          // Create a card element and store it in cardRef
          cardRef.current = unzerInstanceRef.current?.Card({
            numberElementId,
            cvcElementId,
            expiryDateElementId,
            cvcOptional: false,
            cvcSubmitted: true,
            holderEnabled: true,
            holderSubmitted: true,
          });

          Object.values(InputType).forEach((value) => {
            // make sure it will rendered only once on rerendering
            if (document.getElementById(`${unzerInputIdPrefix}${value}`)?.children.length === 0) {
              cardRef.current.create(value, {
                containerId: `${unzerInputIdPrefix}${value}`,
                onlyIframe: false,
                fontSize: '16px',
                fontColor: defaultFontColor,
              });
            }
          });

          const formValidationConditions: FormValidationConditions = {};
          const eventHandlerCardInput = (event: CardInputEvent) => {
            if (event.type) {
              if (event.success) {
                formValidationConditions[event.type] = true;
              } else {
                formValidationConditions[event.type] = false;
              }
              if (
                formValidationConditions.number &&
                formValidationConditions.expiry &&
                formValidationConditions.cvc &&
                formValidationConditions.holder
              ) {
                setIsFormInvalid(false);
              } else {
                setIsFormInvalid(true);
              }
            }
          };

          cardRef.current.addEventListener('change', eventHandlerCardInput);
        }
      } catch (error) {
        if (error instanceof Error) {
          setUnzerError(error);
        } else {
          setUnzerError(new Error('Unknown error type', { cause: error }));
        }
      }
      setIsLoading(false);
    };

    return () => {
      unzerInstanceRef.current = null;
      cardRef.current = null;
      document.head.removeChild(script);
      document.head.removeChild(link);

      // prevent duplicate elements, will only displayed on pub key of
      // unzer sandbox (dev + test env) account
      const sandboxNotifier = document.getElementsByClassName('unzerSandboxNotify');
      while (sandboxNotifier.length > 0) {
        sandboxNotifier[0].remove();
      }
    };
  }, [t]);

  const handleSubmit = useCallback(
    async (event: any) => {
      event.preventDefault();
      setIsSubmitting(true);

      const errorHolder = document.getElementById(errorHolderId);
      if (errorHolder) {
        errorHolder.innerText = ''; // reset on submit
      }

      if (cardRef.current !== null) {
        let typeId = null;
        await cardRef.current
          .createResource()
          .then((result: any) => {
            typeId = result?.id;
          })
          .catch((error: any) => {
            if (errorHolder) {
              errorHolder.innerText = error.customerMessage || error.message || 'Error';
            }
          });

        if (!typeId) {
          errorToast({ title: t('subscription.changePaymentMethodError') });
        } else {
          try {
            await mutateAsync({ subscriptionId, typeId });

            successToast({ title: t('subscription.changePaymentMethodSuccess') });
            onClose();
          } catch (error) {
            errorToast({ title: t('subscription.changePaymentMethodError') });
          }
        }
      }
      setIsSubmitting(false);
    },
    [errorToast, mutateAsync, onClose, subscriptionId, successToast, t],
  );

  if (unzerError) {
    // message of outer error will be displayed in the error boundary, inner error will be visible in sentry
    throw new Error(t('subscription.unzerCreditCardFormInitError'), { cause: unzerError });
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      header={t('subscription.changePaymentMethod')}
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            {t('common.close')}
          </Button>
          <Button
            type="submit"
            isLoading={isSubmitting}
            isDisabled={isFormInvalid}
            onClick={handleSubmit}
          >
            {t('common.submit')}
          </Button>
        </>
      }
    >
      <Box minH="20rem">
        {isLoading ? (
          <SectionLoading />
        ) : (
          <Text pb="4">{t('subscription.changePaymentMethodDescription')}</Text>
        )}
        <Box as="form" id="payment-form" className="unzerUI form" noValidate>
          <div className="field">
            <div id={holderElementId} className={unzerInputClassName}>
              {/* Card holder UI Element will be inserted here. */}
            </div>
          </div>
          <div className="field">
            <div id={numberElementId} className={unzerInputClassName}>
              {/* Card number UI Element will be inserted here. */}
            </div>
          </div>
          <div className="two fields">
            <div className="field ten wide">
              <div id={expiryDateElementId} className={unzerInputClassName}>
                {/* Card expiry date UI Element will be inserted here. */}
              </div>
            </div>
            <div className="field six wide">
              <div id={cvcElementId} className={unzerInputClassName}>
                {/* Card CVC UI Element will be inserted here. */}
              </div>
            </div>
          </div>
          <div className="field" id={errorHolderId} style={{ color: '#9f3a38' }}>
            {/* Error messages will be displayed here */}
          </div>
        </Box>
      </Box>
    </BaseModal>
  );
};
