import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  type TableProps,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import type { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import {
  type Cell,
  type Header,
  type Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
  type Dispatch,
  type FunctionComponent,
  type SetStateAction,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  ColumnConfigPopover,
  DetailsModal,
  initialColumnVisibility,
  nonSelectableColumns,
  rowSelectColumnKey,
  SimSelectAllRowsCell,
  simTableColumns,
  sortableColumns,
} from './components';
import { EmptyTableMessage, SectionError, SectionLoading, SortButton } from 'components';
import { getFromLocalStorage, resetPaginationPages, setToLocalStorage } from 'utils';
import {
  SimSortBy,
  SortDirection,
  type Sim,
  type SimPageQuery,
  type SimPageQueryVariables,
} from 'utils/graphql/hooks';
import { useSelectedSimCardsContext } from 'utils/provider/SelectedSimProvider';
import { routes } from 'utils/routes';

const HIDDEN_COLUMNS_STORAGE_KEY = 'qbSimTableHiddenColumns';

const getTableCellPadding = (cell: Cell<Sim, unknown>) => {
  let padding;

  if (cell.column.id === rowSelectColumnKey) {
    padding = '0';
  } else if (
    cell.column.id === 'state' && // state is the first column
    process.env.REACT_APP_TOGGLE_SIM_MULTISELECT !== 'true' // When no multiselect, the first column needs more padding left
  ) {
    padding = '0.75rem 0.5rem 0.75rem 0.75rem';
  } else {
    padding = '0.75rem 0.5rem';
  }

  return padding;
};

// provides type safety and prevents errors due to users manipulation of content in local storage
const getHiddenColumnsFromStorage = () => {
  const content = getFromLocalStorage(HIDDEN_COLUMNS_STORAGE_KEY);

  if (!content || !Array.isArray(content)) {
    return null;
  }

  if (process.env.REACT_APP_TOGGLE_SIM_MULTISELECT === 'true') content.push(rowSelectColumnKey);

  const columns = content.filter(
    (col) => typeof col === 'string' && !nonSelectableColumns.includes(col),
  ) as string[];

  return columns.reduce((acc: { [col: string]: boolean }, col) => {
    acc[col] = false;

    return acc;
  }, {});
};

interface SimTableProps extends TableProps {
  simPageQueryResult: UseInfiniteQueryResult<InfiniteData<SimPageQuery>, Error>;
  paginationParams: SimPageQueryVariables;
  setPaginationParams: Dispatch<SetStateAction<SimPageQueryVariables>>;
}

export const SimTable: FunctionComponent<SimTableProps> = ({
  simPageQueryResult,
  paginationParams,
  setPaginationParams,
  ...rest
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { selectedSimCardsMap, addSelectedSimCards, removeSelectedSimCards } =
    useSelectedSimCardsContext();

  const [detailsModalData, setDetailsModalData] = useState<Sim | null>(null);
  const [columnVisibility, setColumnVisibility] = useState<{ [column_id: string]: boolean }>(
    getHiddenColumnsFromStorage() || initialColumnVisibility,
  );
  const [tableData, setTableData] = useState<Sim[]>([]);

  const { isOpen: isOpenDetails, onOpen: onOpenDetails, onClose: onCloseDetails } = useDisclosure();

  const { data, isLoading, isError, error, hasNextPage, fetchNextPage, isFetchingNextPage } =
    simPageQueryResult;

  const onSortClick = useCallback(
    (header: Header<Sim, unknown>) => {
      resetPaginationPages(queryClient, ['SimPage.infinite', paginationParams]);

      if (Object.values<string>(SimSortBy).includes(header.id)) {
        const toggledDirection =
          paginationParams.sortDirection === SortDirection.Desc
            ? SortDirection.Asc
            : SortDirection.Desc;

        setPaginationParams({
          ...paginationParams,
          sortBy: header.id as SimSortBy,
          // toggle direction or default to desc
          sortDirection:
            paginationParams.sortBy === header.id ? toggledDirection : SortDirection.Desc,
        });
      }
    },
    [paginationParams, queryClient, setPaginationParams],
  );

  useEffect(() => {
    const mergedSimCardPages: Sim[] = [];

    data?.pages.forEach((page) => {
      mergedSimCardPages.push(...page.simPage.items);
    });

    setTableData(mergedSimCardPages);
  }, [data?.pages]);

  useEffect(() => {
    const iccidFromSearch = new URLSearchParams(location.search).get('iccid');
    const selectedSimCard = tableData.find((sim) => sim.iccid === iccidFromSearch);

    if (selectedSimCard) {
      setDetailsModalData(selectedSimCard);
      onOpenDetails();
    }
  }, [location.search, onOpenDetails, tableData]);

  useEffect(() => {
    setToLocalStorage(
      HIDDEN_COLUMNS_STORAGE_KEY,
      Object.keys(columnVisibility).filter((key) => columnVisibility[key] === false),
    );
  }, [columnVisibility]);

  const rowSelection = useMemo(
    () =>
      tableData.reduce<Record<number, boolean>>(
        (acc, sim, index) => ({ ...acc, [index]: selectedSimCardsMap.has(sim.iccid) }),
        {},
      ),
    [selectedSimCardsMap, tableData],
  );

  const table = useReactTable<Sim>({
    columns: simTableColumns,
    data: tableData,
    state: {
      rowSelection,
      columnVisibility,
    },
    enableRowSelection: true,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
  });

  useEffect(() => {
    if (
      selectedSimCardsMap.size === 0 &&
      table.getRowModel().rows.some((row) => row.getIsSelected())
    ) {
      // deselect all displayed rows
      table.toggleAllRowsSelected(false);
    }
  }, [table, selectedSimCardsMap.size]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allRowsSelected = useMemo(() => table.getIsAllRowsSelected(), [table, rowSelection]);

  const handleTableRowClick = useCallback(
    (row: Row<Sim>) => {
      navigate(`${routes.simCards}?iccid=${row.original.iccid}`);
    },
    [navigate],
  );

  return (
    <>
      {detailsModalData && (
        <DetailsModal sim={detailsModalData} isOpen={isOpenDetails} onClose={onCloseDetails} />
      )}

      <ColumnConfigPopover>
        {table.getAllColumns().map(
          (column) =>
            !nonSelectableColumns.includes(column.id) && (
              <Checkbox
                key={column.id}
                defaultChecked={columnVisibility[column.id] !== false}
                onChange={() => column.toggleVisibility()}
              >
                {t(`sim.col.${column.id}`)}
              </Checkbox>
            ),
        )}
      </ColumnConfigPopover>

      <Box overflowX="auto" w="full">
        <Table {...rest} variant="clickable" id="simCardsTable">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {header.id === rowSelectColumnKey ? (
                      <SimSelectAllRowsCell
                        checked={allRowsSelected}
                        onChange={(e) => {
                          table.toggleAllRowsSelected(e.target.checked);
                          table.getIsAllRowsSelected()
                            ? removeSelectedSimCards(tableData)
                            : addSelectedSimCards(tableData);
                        }}
                      />
                    ) : (
                      <Flex
                        alignItems="center"
                        cursor={sortableColumns.includes(header.id) ? 'pointer' : undefined}
                        onClick={() => onSortClick(header)}
                        data-testid={`sort button ${header.id}`}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {sortableColumns.includes(header.id) && (
                          <SortButton
                            isSorted={paginationParams.sortBy === header.id}
                            isSortedDesc={paginationParams.sortDirection === SortDirection.Desc}
                            isDisabled={isLoading || isFetchingNextPage}
                          />
                        )}
                      </Flex>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr h="14" key={row.id} onClick={() => handleTableRowClick(row)}>
                {row.getVisibleCells().map((cell) => (
                  <Td p={getTableCellPadding(cell)} key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {isLoading && <SectionLoading />}
      {!isLoading && !tableData.length && !isError && (
        <EmptyTableMessage
          text={
            data?.pages[0]?.simPage.filterCount?.all ? t('sim.noSearchResult') : t('sim.noSimCards')
          }
        />
      )}
      {isError && <SectionError title={t('sim.error.simPageQuery')} message={error.message} />}

      <Center my="8">
        {!isLoading && (
          <Tooltip label={t('sim.showMoreDisabled')} shouldWrapChildren isDisabled={hasNextPage}>
            <Button onClick={() => fetchNextPage()} isDisabled={!hasNextPage || isFetchingNextPage}>
              {t('sim.showMore')}
            </Button>
          </Tooltip>
        )}
      </Center>
    </>
  );
};
