import { useTranslation } from 'react-i18next';
import { UserGroup } from 'utils/graphql/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

export const useAuthUtils = () => {
  const { t } = useTranslation();
  const { authUser } = useAuthUserContext();

  const hasReadonlyPermission = authUser?.group === UserGroup.SystemUser;
  const readonlyDisabledProp = hasReadonlyPermission ? true : undefined;
  const readonlyTitleProp = hasReadonlyPermission ? t('common.missingPermission') : undefined;

  return {
    hasReadonlyPermission,
    readonlyDisabledProp,
    readonlyTitleProp,
    readonlyProps: {
      isDisabled: readonlyDisabledProp,
      title: readonlyTitleProp,
    },
  };
};
