import { Center, Text } from '@chakra-ui/react';
import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useEffect, type FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionLoading } from 'components';
import { WireGuardDeploymentStatus, type WireGuardQuery } from 'utils/graphql/hooks';

export interface WireGuardConfigAutomaticReloadProps {
  configId: string;
  refetchConfig: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<WireGuardQuery, Error>>;
}

export const WireGuardConfigAutomaticReload: FunctionComponent<
  WireGuardConfigAutomaticReloadProps
> = ({ configId, refetchConfig }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(30);

  useEffect(() => {
    let timer: number | undefined;

    const updateCounter = async () => {
      if (typeof timer === 'undefined') {
        timer = window.setInterval(() => {
          setCounter((prevCount) => prevCount - 1);
        }, 1000);
      }

      if (counter === 0) {
        setIsLoading(true);
        const result = await refetchConfig();
        setIsLoading(false);

        const currentConfig = result.data?.wireGuard.configs?.find(
          (config) => config.uuid === configId,
        );

        if (currentConfig?.status.deploymentStatus === WireGuardDeploymentStatus.Done) {
          clearInterval(timer);
        } else {
          setCounter(30);
        }
      }
    };

    updateCounter();

    return () => clearInterval(timer);
  }, [configId, counter, refetchConfig]);

  return (
    <Center py="7" px={['5', '10']} flexDirection="column">
      <Text fontWeight="bold">{t('config.wireGuard.deploying')}</Text>
      {isLoading ? (
        <SectionLoading />
      ) : (
        <>
          <Text pt="5">{t('config.wireGuard.automaticReload')}</Text>
          <Text fontWeight="bold" fontSize="2xl">
            {counter}
          </Text>
          <Text>{t('config.wireGuard.seconds')}</Text>
        </>
      )}
    </Center>
  );
};
