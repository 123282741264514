import { Center, Heading, Stack, type StackProps } from '@chakra-ui/react';
import type { FunctionComponent, PropsWithChildren } from 'react';

interface UnauthenticatedCardProps extends StackProps {
  title: string;
}

export const UnauthenticatedCard: FunctionComponent<
  PropsWithChildren<UnauthenticatedCardProps>
> = ({ title, children, ...rest }) => (
  <Stack
    p={['8', null, '16']}
    w="80%"
    spacing="6"
    bg="white"
    borderRadius="Q"
    shadow="base"
    {...rest}
  >
    <Center>
      <Heading as="h1" size="lg" textAlign="center">
        {title}
      </Heading>
    </Center>
    {children}
  </Stack>
);
