import { Button, chakra, Grid, Input } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { Sim } from 'utils/graphql/hooks';
import { useUpdateSimAttributesMutation } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';
import { useSelectedSimCardsContext } from 'utils/provider/SelectedSimProvider';

const FORM_ID = 'renameSimDetailsForm';
const LABEL_INPUT_ID = 'labelInput';
const COST_CENTER_ID_INPUT_ID = 'costCenterIdInput';

const maxInputLength = 50;

const removeDuplicates = (array: string[]) => [...new Set(array)];

interface EditableDetails {
  label: string;
  costCenterId: string;
}

interface RenameSimFormProps {
  sims?: Sim[];
  refetchSimsOnSuccess?: boolean;
}

export const RenameSimForm: FunctionComponent<RenameSimFormProps> = ({
  sims,
  refetchSimsOnSuccess = true,
}) => {
  const { t } = useTranslation();
  const { readonlyProps } = useAuthUtils();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });
  const queryClient = useQueryClient();
  const { mutateAsync } = useUpdateSimAttributesMutation();
  const { selectedSimCards, selectedIccids, addSelectedSimCards } = useSelectedSimCardsContext();

  const [submittingField, setSubmittingField] = useState<'label' | 'costCenterId'>();

  const simCards = sims ?? selectedSimCards;

  const iccids = useMemo(
    () => (sims ? sims.map((sim) => sim.iccid) : selectedIccids),
    [sims, selectedIccids],
  );
  const uniqueLabels = useMemo(
    () => removeDuplicates(simCards.map((sim) => sim.label)),
    [simCards],
  );
  const uniqueCostCenterIds = useMemo(
    () => removeDuplicates(simCards.map((sim) => sim.costCenterId)),
    [simCards],
  );

  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting, dirtyFields },
    resetField,
    getValues,
  } = useForm<EditableDetails>({
    defaultValues: {
      label: uniqueLabels.length === 1 ? uniqueLabels[0] : '',
      costCenterId: uniqueCostCenterIds.length === 1 ? uniqueCostCenterIds[0] : '',
    },
  });

  const submitCallback = async (
    label: string,
    costCenterId: string,
    fieldToSubmit: 'label' | 'costCenterId',
  ) => {
    try {
      if (fieldToSubmit === 'label') {
        setSubmittingField('label');

        await mutateAsync({ label: label.trim(), iccids });

        resetField('label', { defaultValue: getValues('label') });
        setSubmittingField(undefined);

        addSelectedSimCards(selectedSimCards.map((sim) => ({ ...sim, label: label.trim() })));
      }
      if (fieldToSubmit === 'costCenterId') {
        setSubmittingField('costCenterId');

        await mutateAsync({ costCenterId: costCenterId.trim(), iccids });

        resetField('costCenterId', { defaultValue: getValues('costCenterId') });
        setSubmittingField(undefined);
      }

      if (refetchSimsOnSuccess) {
        await queryClient.refetchQueries({ queryKey: ['SimPage.infinite'] });
      }
      successToast({ title: t('sim.updateAttributeSuccess') });
    } catch (error) {
      errorToast({
        title: (error instanceof Error && t('sim.updateAttributeError')) || t('error.generic'),
        ...(error instanceof Error && { description: error.message }),
      });
    }
  };

  return (
    <chakra.form id={FORM_ID} width="full">
      <Grid templateColumns="1fr 2fr 1fr" rowGap={2} alignItems="center">
        <chakra.label htmlFor={LABEL_INPUT_ID}>{t('sim.col.label')}</chakra.label>
        <Input
          id={LABEL_INPUT_ID}
          variant="userData"
          size="sm"
          maxLength={maxInputLength}
          {...register('label')}
          isInvalid={!!errors.label}
          placeholder={t('sim.bulk.renameLabelPlaceholder')}
          {...readonlyProps}
        />
        <Button
          type="submit"
          form={FORM_ID}
          mx={3}
          isDisabled={!dirtyFields.label || submittingField === 'costCenterId'}
          isLoading={isSubmitting && submittingField === 'label'}
          loadingText={t('common.apply')}
          // This form is manually submitted, because it needs to know whether to update the lable or costCenterId
          onClick={handleSubmit(({ label, costCenterId }) =>
            submitCallback(label, costCenterId, 'label'),
          )}
        >
          {t('common.apply')}
        </Button>

        <chakra.label htmlFor={COST_CENTER_ID_INPUT_ID}>{t('sim.col.costCenterId')}</chakra.label>
        <Input
          id={COST_CENTER_ID_INPUT_ID}
          variant="userData"
          size="sm"
          maxLength={maxInputLength}
          {...register('costCenterId')}
          isInvalid={!!errors.costCenterId}
          placeholder={t('sim.bulk.renameCostCenterIdPlaceholder')}
          {...readonlyProps}
        />
        <Button
          type="submit"
          form={FORM_ID}
          mx={3}
          isDisabled={!dirtyFields.costCenterId || submittingField === 'label'}
          isLoading={isSubmitting && submittingField === 'costCenterId'}
          loadingText={t('common.apply')}
          // This form is manually submitted, because it needs to know whether to update the lable or costCenterId
          onClick={handleSubmit(({ label, costCenterId }) =>
            submitCallback(label, costCenterId, 'costCenterId'),
          )}
        >
          {t('common.apply')}
        </Button>
      </Grid>
    </chakra.form>
  );
};
