import { FormControl, FormErrorMessage, FormLabel, Input, Stack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidHttpUrl } from 'utils';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

interface OauthFieldProps {
  fieldValue: string;
  setFieldValue: (newValue: string) => void;
  oauthKey: string;
}

export const OauthField: FunctionComponent<OauthFieldProps> = ({
  fieldValue,
  setFieldValue,
  oauthKey,
}) => {
  const { t } = useTranslation();
  const { readonlyProps } = useAuthUtils();

  const conditionValue = useMemo(() => {
    if (oauthKey === 'tokenUrl') {
      return {
        id: 'token-url-id',
        placeholder: 'https://',
        label: t('config.iotConnectorProxy.oauthConfig.tokenUrl'),
      };
    }

    return {
      id: oauthKey,
      placeholder: oauthKey,
      label: t(`config.iotConnectorProxy.oauthConfig.${oauthKey}`),
    };
  }, [oauthKey, t]);

  return (
    <FormControl isInvalid={oauthKey === 'tokenUrl' ? !isValidHttpUrl(fieldValue) : false}>
      <Stack
        spacing="4"
        direction={['column', null, 'row']}
        alignItems={['stretch', null, 'center']}
      >
        <FormLabel fontWeight="bold" fontSize="md" width={28}>
          {conditionValue.label}
        </FormLabel>
        <Input
          flex={[null, null, 1]}
          id={conditionValue.id}
          placeholder={conditionValue.placeholder}
          variant="userData"
          size="sm"
          value={fieldValue}
          onChange={(e) => setFieldValue(e.target.value)}
          {...readonlyProps}
        />
      </Stack>
      {oauthKey === 'tokenUrl' && !isValidHttpUrl(fieldValue) && (
        <FormErrorMessage>{t('config.iotConnectorProxy.tokenUrlInvalid')}</FormErrorMessage>
      )}
    </FormControl>
  );
};
