import { Text } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { InputContainer, type InputContainerProps } from './InputContainer';

interface AccountDataFieldProps extends InputContainerProps {
  title: string;
  data: string | undefined;
}

export const AccountDataField: FunctionComponent<AccountDataFieldProps> = ({
  title,
  data,
  ...rest
}) => (
  <InputContainer {...rest}>
    <Text pb="2">{title}</Text>
    <Text variant="bordered">{data}</Text>
  </InputContainer>
);
