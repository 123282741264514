import { useTranslation } from 'react-i18next';
import { type SideMenuLink } from 'components';
import { routes } from 'utils/routes';

export const SideMenuLinks = () => {
  const { t } = useTranslation();
  const sideMenuLinks: SideMenuLink[] = [
    { to: routes.account.root, text: t('account.user.accountInfo') },
    { to: routes.account.support, text: t('account.support.supportRequest') },
    ...(process.env.REACT_APP_TOGGLE_COMPANY_SUB_PAGE === 'true'
      ? [{ to: routes.account.companyAccount, text: t('account.company.companyAccount') }]
      : []),
  ];

  return sideMenuLinks;
};
