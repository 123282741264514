import { Button, Text, VStack } from '@chakra-ui/react';
import { useMemo, useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, CustomSelect } from 'components';
import { formatToDateTimeString } from 'utils/dateUtils';
import { useUpdateSubscriptionMutation } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

const getNextMonthsSelectItems = () => {
  const months = [];
  const minMonthsForCancellation = 1;
  const maxFutureMonths = 3;

  for (let i = 1; i <= maxFutureMonths; i++) {
    const date = new Date();

    // get the last day of the i-th month
    date.setMonth(date.getMonth() + minMonthsForCancellation + i, 0);

    const label = date.toLocaleString('default', { month: 'long', year: 'numeric' });
    const value = `${date.toISOString().split('T')[0]} 23:59:59`;

    months.push({ label, value });
  }

  return months;
};

interface CancelSubscriptionModalProps {
  isOpen: boolean;
  onClose: () => void;
  subscriptionId: string;
  onSuccessAction: () => Promise<void>;
}

export const CancelSubscriptionModal: FunctionComponent<CancelSubscriptionModalProps> = ({
  isOpen,
  onClose,
  subscriptionId,
  onSuccessAction,
}) => {
  const { t } = useTranslation();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const { mutateAsync, isPending } = useUpdateSubscriptionMutation();

  const selectItems = useMemo(() => getNextMonthsSelectItems(), []);

  const [selectedEndDate, setSelectedEndDate] = useState<string>(selectItems[0].value);

  const onCancellation = async () => {
    try {
      const response = await mutateAsync({
        subscriptionId,
        endDate: selectedEndDate,
      });
      await onSuccessAction();

      successToast({
        title: t('subscription.cancel.successTitle', {
          endDate: formatToDateTimeString(response.updateSubscription.endDate, true),
        }),
        description: t('subscription.cancel.successDescription'),
        duration: 10000, // default is 5000ms
      });
      onClose();
    } catch (error) {
      errorToast({ title: t('subscription.cancel.error') });
    }
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      header={t('subscription.cancel.title')}
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            {t('common.close')}
          </Button>
          <Button
            isLoading={isPending}
            loadingText={t('subscription.cancel.buttonLoading')}
            onClick={onCancellation}
          >
            {t('common.submit')}
          </Button>
        </>
      }
    >
      <VStack>
        <Text>{t('subscription.cancel.description')}</Text>
        <CustomSelect
          items={selectItems}
          defaultSelectedValue={selectItems[0].label}
          onClick={(value) => setSelectedEndDate(value)}
        />
      </VStack>
    </BaseModal>
  );
};
