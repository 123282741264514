export const enAuthenticatorTranslations = {
  'Sign In': 'Login', // Tab header
  'Sign in': 'Log in', // Button label
  'Account recovery requires verified contact information':
    'Please verify your contact information', // e.g. on unverified email address
  "1 validation error detected: Value null at 'attributeName' failed to satisfy constraint: Member must not be null":
    'You must select the attribute you want to verify.', // no attribute selected on attribute verification
};

/**
 * basic structure:
 * - common -> common translations that are used on multiple components
 * - page1 -> translations for page 1
 * - page2 -> translations for page 2
 * - error -> translations for errors, e.g. more specific API errors
 */
export const en = {
  translation: {
    authenticator: enAuthenticatorTranslations,
    common: {
      yes: 'Yes',
      no: 'No',
      state: 'State',
      description: 'Description',
      cancel: 'Cancel',
      close: 'Close',
      edit: 'Edit',
      reset: 'Reset',
      resetting: 'Resetting',
      apply: 'Apply',
      submit: 'Submit',
      submitting: 'Submitting',
      deleting: 'Deleting',
      resending: 'Resending',
      areYouSure: 'Are you sure?',
      continue: 'Continue',
      back: 'Back',
      email: '$t(Email, {{format}})',
      email_other: '$t(Emails, {{format}})',
      phone: 'Phone',
      password: 'Password',
      loading: 'Loading',
      error: 'Error',
      success: 'Success',
      portalName: 'q.beyond IoT SIM Portal',
      given_name: 'First name',
      family_name: 'Last name',
      name: 'Name',
      saveChanges: 'Save changes',
      saving: 'Saving',
      send: 'Send',
      sending: 'Sending',
      verifying: 'Verifying',
      amount: 'Amount',
      iccid: 'ICCID',
      iccid_other: 'ICCIDs',
      dataVolume: '$t(Data volume, {{format}})',
      simCard: 'SIM card',
      simCard_other: 'SIM cards',
      mySimCard: 'My $t(common.simCard, {{count}})',
      mySimCard_other: 'My $t(common.simCard_other)',
      activate: 'Activate',
      deactivate: 'Deactivate',
      shop: 'Shop',
      default: 'Default',
      showValue: 'Show value',
      hideValue: 'Hide value',
      updateAttributesSuccess: 'Attributes were updated successfully',
      updateAttributesError: 'Unexpected error while updating attributes',
      privacyLinkText: 'privacy policy',
      role: 'Role',
      roles: {
        system_admin: 'System Admin',
        system_user: 'System User',
        company_owner: 'Owner',
        company_admin: 'Admin',
        company_user: 'User',
        undefined: 'None',
      },
      systemAdminOnly: '$t(common.roles.system_admin) only',
      pleaseContactEmail: 'Please contact <0>{{email}}</0>',
      toggleAllRowsSelected: 'Toggle All Rows Selected',
      toggleRowSelected: 'Toggle Row Selected',
      missingPermission: "You don't have the required permissions to perform this action.",
      deactivated: 'Deactivated',
      wireGuard: 'WireGuard',
      download: 'Download',
      states: {
        active: 'Active',
        inactive: 'Inactive',
        initial: 'Initial',
      },
    },
    scannedSim: {
      firstIccid: 'First $t(common.iccid)',
      lastIccid: 'Last $t(common.iccid)',
      stateError: 'Unable to load state',
      moreDetails: 'For more details please sign in to our portal:',
      toPortal: 'Go to portal',
      scannedSim: 'Scanned $t(common.simCard)',
      assignToCompany: 'Assign to my company',
      classicSimCard: 'Classic $t(common.simCard)',
      embeddedSimCard: 'Embedded $t(common.simCard)',
    },
    sim: {
      search: 'Search $t(common.simCard_other)...',
      selectAColumn: 'Select a column',
      configColumns: 'More columns',
      noSimCards: 'There are no $t(common.simCard_other) allocated to your account at the moment.',
      noSearchResult: 'There are no $t(common.simCard_other) matching your search',
      showMore: 'Show more',
      showMoreDisabled: 'There is no more data to load',
      updateAttributeSuccess: 'Attribute was updated successfully',
      updateAttributeError: 'Could not update $t(common.simCard) attributes',
      filterButtonDisabled: 'No $t(common.simCard) fits this filter',
      simCardUnused: '$t(common.simCard) was not used yet',
      simActivationDisabled: '$t(common.simCard) can not be activated. Reason: {{reason}}',
      action: 'Action',
      ping: 'Ping',
      tools: 'Tools',
      pingError: 'Ping error',
      ssh: 'SSH',
      connectionEstablished: 'Connection established',
      connectionFailed: 'Connection refused',
      connectionTimedOut: 'Connection timed out',
      connectionClosed: 'Connection closed',
      sshAuthenticating: 'Authenticating...',
      sshSessionTimeoutWarning: 'Your session will expire in 1 minute',
      sshSessionExtend: 'Extend your session',
      exceedDataVolumeEnabled: 'Exceed $t(common.dataVolume, lowercase) enabled',
      exceedDataVolumeEnableFailed: 'Failed to enable exceed $t(common.dataVolume, lowercase)',
      exceedDataVolumeDisable: 'Exceed $t(common.dataVolume, lowercase) disabled',
      exceedDataVolumeDisableFailed: 'Failed to disable exceed $t(common.dataVolume, lowercase)',
      enableExceedDataVolumeLabel: 'Exceed $t(common.dataVolume, lowercase)',
      deactivatedBySystem: '$t(common.deactivated) by system',
      simCardType: {
        classic: '2/3/4FF',
        mff2: 'MFF2',
      },
      col: {
        // directly nested common translations are needed for mapping purposes
        state: '$t(common.state)',
        iccid: '$t(common.iccid)',
        dataVolume: '$t(common.dataVolume)',
        session: 'Session',
        ip: 'IP address',
        imsi: 'IMSI',
        msisdn: 'MSISDN',
        imei: 'IMEI',
        label: 'Label',
        costCenterId: 'Cost center ID',
        apn: 'APN',
        lastUse: 'Last use / Last CDR',
        lastUseTooltip:
          'A CDR is a Call Detail Record. CDRs are created by the mobile network operator for every network event, such as mobile data usage.',
        endDate: 'End date',
        type: 'Type',
        smsVolume: 'SMS Volume',
      },
      filter: {
        showAll: 'Show all',
        dataVolumeOver: '$t(common.dataVolume) over {{amount}}',
        noDataVolume: 'No $t(common.dataVolume, lowercase)',
      },
      bulk: {
        selected: '$t(common.simCard_other) selected',
        showDetails: 'Show details',
        hideDetails: 'Hide details',
        deselectAll: 'Deselect all',
        allowAll: 'Allow all',
        denyAll: 'Deny all',
        yesTo: 'Yes, {{operation}}',
        deactivateQuestion: 'Deactivate $t(common.simCard, {{count}})?',
        deactivateCount:
          '{{count}} $t(common.simCard, {{count}}) will be $t(common.deactivated, lowercase).',
        deactivationSuccess:
          'Successfully triggered deactivation process for {{count}} $t(common.simCard, {{count}}).',
        deactivationFailure: 'Could not deactivate $t(common.simCard, {{count}}).',
        activateQuestion: 'Activate $t(common.simCard, {{count}})?',
        activateCount: '{{count}} $t(common.simCard, {{count}}) will be activated.',
        activateAllSystemAdmin:
          'Activate all $t(common.simCard_other) ($t(common.systemAdminOnly))',
        activationSuccess:
          'Successfully triggered activation process for {{count}} $t(common.simCard, {{count}}).',
        activationFailure: 'Could not activate $t(common.simCard, {{count}}).',
        rename: 'Rename',
        renameQuestion: '$t(sim.bulk.rename) $t(common.simCard, {{count}})?',
        renameCount: '{{count}} $t(common.simCard, {{count}}) will be renamed.',
        renameLabelPlaceholder: 'Enter new label',
        renameCostCenterIdPlaceholder: 'Enter new cost center ID',
        systemDeactivatedCardsHint: `The following $t(common.simCard_other) ({{systemDeactivatedCount}}) from your selection ({{selectionCount}}) were $t(common.deactivated, lowercase) by our system 
        and for this reason will not be activated by this action:`,
        reloadQuestion: 'Reload $t(common.simCard, {{count}})?',
        reloadSelection:
          'Select the amount of GB to reload for the selected {{count}} $t(common.simCard, {{count}}).',
        toggleExceedVolumeSelection:
          'Allow selected select $t(common.simCard, {{count}}) to exceed $t(common.dataVolume, lowercase)?',
        exceedVolumeInfo:
          'Currently {{canExceedCount}} of {{count}} $t(common.simCard, {{count}}) can exceed $t(common.dataVolume, lowercase).',
        exceedVolumeChangeSuccess:
          'Successfully changed exceed $t(common.dataVolume, lowercase) for {{count}} $t(common.simCard, {{count}}).',
        exceedVolumeChangeFailed:
          'Failed to change exceed Volume for {{failedCount}} of {{count}} $t(common.simCard, {{count}}).',
        showIccids: 'Show $t(common.iccid, {{count}}) of selected $t(common.simCard, {{count}})',
      },
      state: {
        // directly nested common translations are needed for mapping purposes
        active: '$t(common.states.active)',
        activation_pending: 'Activation pending',
        terminated: 'Terminated',
        suspended: 'Suspended',
        suspend_pending: 'Deactivation pending',
        initial: '$t(common.states.initial)',
      },
      stateReason: {
        contract_duration_exceeded: 'Contract expired',
        data_volume_quota_exceeded: 'No $t(common.dataVolume, lowercase) left',
        sim_unused: '$t(common.simCard) was not used in the last 18 months',
        user: '$t(common.deactivated) by user',
        activation_failed: 'Activation failed, please try again',
        null: '$t(common.deactivated)',
      },
      session: {
        online: 'Online',
        onlineInfo: '$t(common.simCard) has established a data connection',
        offline: 'Offline',
        offlineInfo: '$t(common.simCard) is not connected with a mobile network',
        lastOnline: '{{lastOnline}} ago',
        unknown: 'Unknown',
        unknownInfo: '$t(common.simCard) session is unknown',
      },
      dataVolume: {
        usedOfLimit: '{{used}} / {{limit}} GB',
        usedWithoutLimit: '{{used}} GB',
        used: 'used',
        volumeReload: 'Volume reload',
        exact: '{{used}} bytes used',
        exactByteUsed: 'Exact bytes used',
      },
      smsVolume: {
        usedOfLimit: '{{used}} / {{limit}}',
        usedWithoutLimit: '{{used}}',
      },
      registration: {
        success_one:
          'Activation process of $t(common.simCard) with ICCID {{iccid}} was triggered successfully',
        success_other:
          'Activation process of $t(common.simCard, {{count}}) was triggered successfully',
        error_one: 'Could not register $t(common.simCard) with ICCID {{iccid}}',
        error_other: 'Could not register $t(common.simCard_other)',
        errorDescription: {
          payment_required: 'There are still payments required. $t(common.pleaseContactEmail)',
          unauthorized: "You don't have the required permissions to register a SIM card.",
          invalidUrl: 'The registration URL is invalid. $t(common.pleaseContactEmail)',
        },
        inProgressTitle: 'SIM activation in progress',
        inProgressDescription:
          'Please wait while we are activating your $t(common.simCard, {{count}}).',
        inProgressRedirectMessage: 'You will be redirected.',
      },
      error: {
        simPageQuery: 'An error occurred while fetching $t(common.simCard_other)',
      },
    },
    shopCheckout: {
      orderData: 'order data',
      invoiceDetails: 'Invoice details',
      overwriteCompanyData: 'Set as new default company data on submit of the order.',
      resetCompanyData: "Restore company's default data",
      defaultCompanyAddress: 'This is already the default of your company.',
      overview: {
        title: 'Your order',
        product: 'Product',
        regularPriceHint: '\n(coming months regular {{formattedPrice}})',
        proratedPriceCalculation:
          '{{formattedRegularPrice}} x {{remainingDays}} remaining days (including today) / {{daysInMonth}} days in month',
        subtotal: 'Subtotal',
        iccidList: '$t(common.iccid, {{count}}) of selected $t(common.simCard, {{count}})',
        productDescriptions: {
          datavolume_1gb:
            '{{gb}} GB $t(common.dataVolume, lowercase) x {{price, number(minimumFractionDigits: 2)}} € x {{count}} $t(common.simCard, {{count}})',
          wireguard_base_1m:
            '$t(common.wireGuard) $t(config.wireGuard.instanceTypes.small, lowercase) monthly subscription',
          wireguard_xl_1m:
            '$t(common.wireGuard) $t(config.wireGuard.instanceTypes.xlarge, lowercase) monthly subscription',
        },
        vat: 'VAT',
        total: 'Total',
      },
      privacyInfo:
        'Your personal information will be used to process your order, support your experience on this website and for other purposes described in our <0>$t(common.privacyLinkText)</0>.',
      termsAgreement: 'I have read and agree to the <0>terms and conditions</0>.',
      termsNotAccepted: 'Please accept the terms and conditions to continue.',
      paymentMethod: {
        title: 'Payment method',
        subscriptionPaymentHint:
          '$t(shopCheckout.paymentMethod.bill) is not available as we use this payment method for charges of your subscription in the future.',
        bill: 'Bank transfer',
        billHint:
          'You will receive an invoice at your $t(common.email, lowercase) address on submit. After your payment is received the $t(common.dataVolume, lowercase) will be increased.',
        credit_card: 'Credit / Debit card',
        sepa_direct_debits: 'SEPA direct debits',
        unzerHint:
          'After the order is submitted you will be redirected to our shop to complete the payment.',
        moreOptionsSoon: 'The other payment options from our shop will be available soon.',
      },
      confirmation: {
        title: 'Thank you for your purchase!',
        bankTransferMessage:
          'The invoice was sent to you by $t(common.email, lowercase). After the payment has been received, the $t(common.dataVolume, lowercase) will be increased.',
        unzerMessage:
          'You will be redirected to pay the order. After your payment is received, the invoice will be sent by $t(common.email, lowercase) and the $t(common.dataVolume, lowercase) will be increased.',
        redirectionTime: 'You will be redirected in {{count}} $t(dateTime.second, count).',
        redirectNow: 'Redirect now',
      },
      error: {
        formErrors: "One or more required fields aren't filled out correctly.",
        missingCompanyAttributeTitle: 'Company missing {{missingAttributes, list}}',
        missingCompanyAttributeDescription:
          'Your company is missing the {{missingAttributes, list}} attribute. {{additionalVatIdDescription}}Please create a <0>$t(account.support.supportRequest, lowercase)</0> that we can validate your companies {{missingAttributes, list}}. Then we will add it to your account and contact you when it is done.',
        additionalVatIdDescription:
          'It is required for the calculation of the VAT on your order.\n ',
        loadingOrderDataTitle: 'An error occurred while loading the order data.',
        loadingOrderDataDescription: 'Please try again.',
        orderSubmit: 'An error occurred on submitting your order.',
      },
    },
    config: {
      configurations: 'Configurations',
      moreInfo: 'For more details visit the article in the <0>Developer Hub</0>.',
      iotConnectorProxy: {
        heading: 'IoT Connector Proxy',
        targetUrl: 'Target URL',
        headers: 'HTTP header fields',
        key: 'Key',
        value: 'Value',
        addNewHeader: 'Add new header field',
        error: {
          keyRequired: 'A key is required',
          valueRequired: 'A value is required',
        },
        examplePreviewText: 'Example preview:',
        examplePreview:
          '<strong>{{- proxyBaseUrl}}/sub/path?param=value</strong> will be proxied to <strong>{{- targetUrl}}/sub/path?param=value</strong>',
        removeHeader: 'Remove header field',
        removeHeaderQuestion:
          'Are you sure you want to remove the following header field?\n$t(config.iotConnectorProxy.key): "{{key}}"; $t(config.iotConnectorProxy.value): "{{value}}"',
        headerMaximum: 'You have reached the maximum number of header fields.',
        xForwardedForDescription: 'Private static IP address predefined by IoT SIM',
        xSourceDescription: 'Source protocol (e.g. "CoAP")',
        xConnectIccidDescription: 'Integrated Circuit Card Identification Number',
        xConnectImsiDescription: 'International Mobile Subscriber Identity',
        contentTypeDescription: 'Derived from CoAP request',
        messageIdDescription: 'Unique message ID taken over from CoAP request',
        proxyDescription:
          'The IoT Connector Proxy service allows you to easily transfer data from your IoT devices to your own backend system by mapping CoAP to HTTP.',
        technicalProxyDescription: `When the proxy service receives a CoAP request, it will make an HTTP call to your backend system with the exact same payload as included in the CoAP request. The method 
        type of the HTTP request will also correspond to the method type of the CoAP request. Then we map the HTTP response of your backend system back to CoAP and send it to the corresponding IoT device. 
        Use this page to configure the destination and additional metadata for the generated HTTP requests.`,
        targetUrlDescription: `This is the URL, where we will proxy the CoAP requests from your IoT devices to. This means, that the CoAP URL of our proxy server will simply be rewritten with the target 
        URL, which you specify here. The URL path ("/") and the query parameters ("?&") will then be appended.`,
        enterTargetUrlForPreview: 'Enter a valid target URL to show the example preview.',
        httpHeaderFieldsDescription:
          'Here you can add additional HTTP header fields to the predefined fields. All these header fields will be attached to the HTTP requests, which the proxy server sends to your target URL.',

        timeout: 'Timeout',
        disabledHint:
          'This page is disabled, because we are still working on the IoT Connector. It will be released soon.',
        addHeaderSuccess: 'Header field was added successfully',
        addHeaderError: 'Unexpected error while adding header field',
        removeHeaderSuccess: 'Header field was removed successfully',
        removeHeaderError: 'Unexpected error while removing header field',
        simulate: 'Simulate proxy',
        simulateBtn: 'Simulate',
        coapRequest: 'Coap Request',
        coapRequestConfig: {
          path: 'Path',
          method: 'Method',
          mediaType: 'Media type',
          body: 'Body',
        },
        uploadBody: 'Upload body',
        removeFileArialLabel: 'remove selected file',
        oauth: 'Oauth',
        oauthConfig: {
          clientId: 'Client Id',
          clientSecret: 'Client Secret',
          scopes: 'Scopes',
          tokenUrl: 'Token Url',
        },
        oauthFieldsDescription:
          'Here you can configure oauth fields. The authorization data will be automatically generated when you send the request.',
        testOauthConfig: 'Add oauth configuration',
        testingOauthConfig: 'Testing...',
        tokenUrlInvalid: 'Token url must start with http(s) and must not be empty.',
        simulationSuccess: 'Simulation was successful. You can download/view the response',
        simulationFailed: 'Simulation failed',
        simulateResult: {
          download: 'Download Response',
          view: 'View Response',
        },
      },
      iotConnectorAzure: {
        heading: 'IoT Connector Azure',
        availableOnRequest: 'The IoT Connector Azure is available on request.',
      },
      openVpn: {
        heading: 'OpenVPN',
        availableOnRequest: 'OpenVPN is available on request.',
      },
      wireGuard: {
        heading: '$t(common.wireGuard)',
        description: `Easily establish secure connections to your IoT devices using $t(common.wireGuard) as a Service. Our seamless solution simplifies IoT VPN, providing you with convenient access to devices 
        equipped with our IoT $t(common.simCard_other). This section explains how to request a dedicate $t(common.wireGuard) instance. This instance is limited to your own IoT $t(common.simCard_other) and to 
        a single $t(common.wireGuard) client.`,
        peerConfigHeader: 'Peer Configuration',
        peerConfigHeaderDisabled: '$t(config.wireGuard.peerConfigHeader) (disabled)',
        peerConfigDescription: `The following fields are information needed to configure your secure tunnel. These three values $t(config.wireGuard.clientIp), $t(config.wireGuard.publicKey, lowercase) and 
        $t(config.wireGuard.endpoint) and your matching client private key can now be used to create a $t(common.wireGuard) client configuration.`,
        registration: 'Public Key Registration',
        book: 'Book Configuration',
        publicKeyRegistrationDescription:
          'To configure a secure tunnel to your $t(common.simCard_other) please add your own generated client public key.',
        address: 'Address',
        pingableTestIp: 'PingableTestIP',
        publicKey: 'Client public key',
        publicKeyExample: '5Dc3HtVz6XPWbaDoDUVE...',
        peerConfigTempl: 'Peer Configuration Template',
        clientIp: 'Client IP',
        endpoint: 'Endpoint',
        instanceType: 'Instance type',
        instanceTypeAriaLabel: 'Select $t(config.wireGuard.instanceType, lowercase)',
        instanceTypes: {
          description: `The $t(config.wireGuard.instanceType, lowercase) describe the size of your container. It is hard to name a suitable size because the demand depends on various factors. The available 
          options are: $t(config.wireGuard.instanceTypes.small) and $t(config.wireGuard.instanceTypes.xlarge).`,
          small: 'Base',
          xlarge: 'XLarge',
        },
        deploying:
          'This $t(common.wireGuard) Configuration is still deploying. The necessary information to configure your secure tunnel will be available in a few minutes.',
        automaticReload: 'This page will refresh automatically in ',
        seconds: 'seconds',
        subscription: {
          sectionTitle: 'Company subscription',
          endDate: 'End date',
          noSubscriptionInfo:
            'Your company does not currently have an active $t(common.wireGuard) subscription. To get one select the desired $t(config.wireGuard.instanceType, lowercase) below and complete the checkout process.',
          getSubscriptionInfo: `Please notice that the price of the initial order of your subscription is lower than the regular monthly price because we only charge the amount until the end of the 
          month including the invoice day. Furthermore you can use payment methods provided by our payment provider Unzer only, e.g. credit card. After your payment is done it could take up to 15 minutes
          and you can configure your $t(common.wireGuard) instance on this page. From this moment on we will charge you monthly by the end of the month for the following month by the specified price.`,
          goToCheckout: 'Go to checkout',
          states: {
            // directly nested common translations are needed for mapping purposes
            initial: '$t(common.states.initial)',
            active: '$t(common.states.active)',
            canceled: 'Canceled',
            inactive: '$t(common.states.inactive)',
          },
        },
        success: {
          configIsBeingSetUp: '$t(common.wireGuard) configuration is being set up',
          configIsDeploying: ' $t(common.wireGuard) configuration was set up and is now deploying',
        },
        error: {
          configFailed: '$t(common.wireGuard) configuration could not be deployed',
          configFailedMessage:
            'There was an issue deploying the EC2 instance. Please create a <0>$t(account.support.supportRequest, lowercase)</0>.',
          publicKeyRequired: 'A public key is required',
        },
        availableOnRequest: '$t(common.wireGuard) is available on request.',
      },
      ssh: {
        heading: 'SSH-Connector',
        sshKeysHeading: 'SSH Keys',
        sshDescription:
          'This service enables SSH connection to IoT devices equipped with $t(common.simCard_other) through a user-friendly web UI, simplifying remote access from anywhere with ease.',
        user: 'SSH-User',
        privateKey: 'Private-Key',
        publicKeyAndFingerprint: `The $t(config.ssh.publicKey) and the $t(config.ssh.fingerprint) were automatically generated as a result of your currently configured $t(config.ssh.privateKey). If you set a 
        new $t(config.ssh.privateKey), they will change as well.`,
        publicKey: 'Public-Key',
        fingerprint: 'Fingerprint',
      },
      error: {
        query: 'An error occurred while fetching the configuration data',
      },
    },
    user: {
      users: 'Users',
      allUsers: 'All users',
      search: 'Search user...',
      addNew: 'Add new user',
      firstName: '$t(common.given_name)',
      lastName: '$t(common.family_name)',
      editData: 'Edit user attributes',
      editDataSuccess: 'User attributes were changed successfully',
      editRole: 'edit role',
      openRoleEdit: 'Open role edit',
      closeRoleEdit: 'Close role edit',
      editRoleSuccess: 'User role was updated successfully',
      editRoleError: 'Error while updating the users role',
      invite: 'Invite user',
      inviting: 'Inviting user',
      inviteSuccess: 'Invite was sent to {{email}}',
      inviteError: 'Unexpected error while user creation',
      missingDeletionPermission: "You don't have permissions to delete this user",
      delete: 'Delete user',
      deleteDescription:
        'Are you sure you want to delete the user with the following $t(common.email, lowercase)? {{email}}',
      deleteSuccess: 'User was deleted: {{email}}',
      deleteError: 'Unexpected error while user deletion',
      invitationSent: 'Invitation sent',
      resendInvitationDescription:
        'Do you want to resend the invitation to the following $t(common.email, lowercase)? {{email}}',
      resendInvitationSuccess: 'Invitation was resent to {{email}}',
      resendInvitationError: 'Error while resending invitation to {{email}}',
      resendInvitation: 'Resend invitation',
      error: {
        firstNameRequired: 'A first name is required',
        lastNameRequired: 'A last name is required',
        resendInvitation: {
          USER_DISABLED: 'User is disabled - recreation of user required',
          USER_CONFIRMED: 'User account is already confirmed',
          INVALID_CONFIRMATION_STATUS:
            'Invalid user confirmation status - recreation of user required',
        },
      },
    },
    companySelect: {
      chooseCompany: 'Choose a company to continue',
      searchbarPlaceholder:
        'Search for $t(common.email, lowercase), $t(companySelect.companyName, lowercase) or ID',
      companyName: 'Company name',
      companyId: 'Company ID',
      noUser: 'No user found with your search term',
      error: {
        idOverwrite: 'Could not update company ID',
        usersQueryError: 'An error occurred while fetching the users',
      },
    },
    account: {
      accountAndSupport: 'Account & Support',
      logout: 'Log out',
      user: {
        accountInfo: 'Account information',
        deletionInformation:
          'If you wish to delete your account, please contact your administrator. If you are an administrator, please send us an $t(common.email, lowercase) at',
        changeEmail: {
          title: 'Change $t(common.email, lowercase)',
          newEmail: 'New $t(common.email, lowercase)',
          sameEmail: 'This is your current $t(common.email, lowercase) address.',
          verifyTitle: 'Verify new $t(common.email, lowercase)',
          verificationCode: 'Verification code',
          sendCode: 'Send $t(account.user.changeEmail.verificationCode, lowercase)',
          verifyEmailMessage:
            'The verification code was sent to your $t(common.email, lowercase) address. Please enter the code to verify this $t(common.email, lowercase) address.',
          success: '$t(common.email) address changed successfully',
        },
        changePassword: {
          title: 'Change $t(common.password, lowercase)',
          changing: 'Changing $t(common.password, lowercase)',
          oldPassword: 'Old $t(common.password, lowercase)',
          newPassword: 'New $t(common.password, lowercase)',
          repeatNewPassword: 'Repeat new $t(common.password)',
          requirements:
            'The $t(common.password) must contain:\n- a lower case letter\n- an upper case letter\n- a special character\n- a number\n- at least 8 characters.',
          success: '$t(common.password) changed successfully',
        },
      },
      support: {
        support: 'Support',
        supportRequest: 'Support Request',
        affectedSIMCards: 'Affected $t(common.simCard_other)',
        searchForIccids: 'Search for ICCIDs ...',
        noSimCardsFound: 'No $t(common.simCard_other) found',
        timestampOfError: 'Timestamp of occurrence',
        ticketCreated:
          'Thank you for your support request. We will get back to you as soon as possible.',
        error: {
          nameRequired: 'A name is required',
          emailRequired: 'An email is required',
          emailInvalid: 'Invalid email format',
          timestampRequired: 'A timestamp is required',
          descriptionRequired: 'A description is required',
        },
      },
      company: {
        companyAccount: 'Company Account',
        companyName: 'Company name',
        editCompanyAttributes: 'Edit company attributes',
        error: {
          nameRequired: 'A company name is required',
          streetAddressRequired: 'A street address is required',
          postalCodeRequired: 'A postal code is required',
          cityRequired: 'A city is required',
          countryRequired:
            'A country is required, needs to be a valid ISO 3166-1 alpha-2 code, e.g. DE for Germany',
          vatIdRequired: 'A VAT ID is required',
          companyQuery: 'An error occurred while fetching company data',
        },
        streetAddress: 'Street address',
        additionalAddressInfo: 'Additional address info (optional)',
        postalCode: 'Postal code',
        city: 'City',
        country: 'Country',
        vatId: 'VAT ID',
        internalReference: 'Internal reference (optional)',
        fieldDisabledMessage: 'Please contact us to change this attribute',
      },
    },
    subscription: {
      cancel: {
        title: 'Cancel subscription',
        description:
          'Cancellation is possible to the end of a month, starting with the following month.',
        button: '$t(subscription.cancel.title)',
        buttonLoading: 'Canceling...',
        successTitle: 'Subscription successfully canceled with end date: {{endDate}}',
        successDescription: 'You will receive a confirmation email.',
        error: 'Error while canceling subscription. $t(error.raiseSupportRequest)',
      },
      reactivate: {
        button: 'Reactivate',
        buttonLoading: 'Reactivating...',
        success: 'Subscription successfully reactivated',
        error: 'Error while reactivating subscription. $t(error.raiseSupportRequest)',
      },
      changePaymentMethod: 'Change payment method',
      changePaymentMethodDescription:
        'Your credit card information will be sent directly to our payment provider Unzer.',
      changePaymentMethodDisabled:
        'Temporary disabled, in urgent cases please raise a support request.',
      changePaymentMethodSuccess: 'Payment method was changed successfully',
      changePaymentMethodError:
        'Error while changing payment method. $t(error.raiseSupportRequest)',
      unzerCreditCardFormInitError: 'Failing to load script from payment provider',
      unpaidSubordersError: 'Too many unpaid invoices regarding your subscriptions.',
      unpaidSubordersErrorDescription: 'An email with more details was sent to you.',
    },

    notFound: {
      ohNo: 'Oh no!',
      404: '404 - page not found :(',
      goBack: 'Go back',
    },
    errorBoundary: {
      unexpectedError: 'An unexpected error occurred: "{{errorName}}"',
      refreshOrContact:
        'Please refresh the page. If you continue to experience issues, please contact <0>{{supportEmail}}</0>',
    },
    error: {
      generic: 'Something went wrong.',
      raiseSupportRequest: 'In case you get the error again, please raise a support request.',
      emptyTable: 'No data to display.',
      maintenance: 'The portal is currently under maintenance, we appreciate your understanding.',
      missingCompanyId:
        'You are not assigned to a company. Please write an email to the address below.',
      missingGroup:
        'You are not assigned to a group. Please ask your companies owner or admin to assign you to a group.',
    },
    dateTime: {
      second_one: 'second',
      second_other: 'seconds',
      days_one: 'day',
      days_other: 'days',
      months_one: 'month',
      months_other: 'months',
      left: 'left',
      expired: 'expired',
    },
    footer: {
      privacyHint:
        'For information on the use of your data, please refer to our <0>$t(footer.nav.privacyPolicy)</0>.',
      shopLink: 'Direction to Shop',
      heading: 'q.beyond AG',
      nav: {
        aboutUs: 'about us',
        imprint: 'imprint',
        privacyPolicy: 'privacy policy',
        shop: 'shop',
        termsOfUse: 'terms of use',
        termsAndConditions: 'terms and conditions',
      },
    },
    a11y: {
      mobileMenuButtonLabel: 'Menu',
      sortingButtonLabel: 'sorting button',
      externalLinkIconTitle: 'Open in new tab',
      clearFilterLabel: 'Clear filter',
    },
    filterBar: {
      filterByPlaceholder: 'Filter by {{terms, list}}',
      noValueFound: 'No dataset associated with {{terms, list}}, and value: {{value}}',
    },
  },
};
