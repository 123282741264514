import { Grid, HStack, Spinner, Text } from '@chakra-ui/react';
import { useCallback, useMemo, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SimStateIndicator } from './SimStateIndicator';
import { useSimStateQuery } from 'utils/graphql/hooks';
import { useSimActivationUrlParams } from 'utils/hooks';
import { simStatusText } from 'utils/simUtils';

const simDetailTextStyles = {
  fontWeight: 'bold',
  pl: [null, '2'],
};

export const ScannedSimCardOverview: FunctionComponent = () => {
  const { t } = useTranslation();
  const { simAmount, firstIccid, lastIccid, hmac, isClassicSim, isEmbeddedSim } =
    useSimActivationUrlParams();

  // this is a low level security check, since this component is used on a public page and could
  // be used to find not claimed sim cards
  const hasValidQueryParams = useMemo(
    () => simAmount >= 1 && (isClassicSim || isEmbeddedSim) && !!hmac,
    [hmac, isClassicSim, isEmbeddedSim, simAmount],
  );

  const { data, isLoading, isError } = useSimStateQuery(
    { iccid: firstIccid as string },
    {
      enabled: !!firstIccid && hasValidQueryParams && simAmount === 1,
    },
  );

  const SimState = useCallback(() => {
    if (isError) {
      return <Text {...simDetailTextStyles}>{t('scannedSim.stateError')}</Text>;
    }

    return data?.simState && !isLoading ? (
      <HStack>
        <Text {...simDetailTextStyles}>{simStatusText(data.simState)}</Text>
        <SimStateIndicator state={data.simState} withoutTooltip />
      </HStack>
    ) : (
      <HStack {...simDetailTextStyles}>
        <Spinner size="sm" />
        <Text>{`${t('common.loading')}...`}</Text>
      </HStack>
    );
  }, [data?.simState, isError, isLoading, t]);

  return (
    <Grid w={[null, '80%']} templateColumns={['1fr', '1fr 2fr']} rowGap="2">
      <>
        {simAmount > 1 ? (
          <>
            <Text>{t('common.amount')}</Text>
            <Text {...simDetailTextStyles}>{simAmount}</Text>
            <Text>{t('scannedSim.firstIccid')}</Text>
            <Text {...simDetailTextStyles}>{firstIccid}</Text>
            <Text>{t('scannedSim.lastIccid')}</Text>
            <Text {...simDetailTextStyles}>{lastIccid}</Text>
          </>
        ) : (
          <>
            <Text>{t('common.iccid', { count: simAmount })}</Text>
            <Text {...simDetailTextStyles}>{firstIccid}</Text>
          </>
        )}

        {hasValidQueryParams && simAmount === 1 && (
          <>
            <Text>{t('common.state')}</Text>
            <SimState />
          </>
        )}
      </>
    </Grid>
  );
};
