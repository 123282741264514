import {
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  type InputGroupProps,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import exitIcon from 'assets/icons/Exit.svg';
import searchIcon from 'assets/icons/Search.svg';

interface SearchbarProps extends Omit<InputGroupProps, 'onChange'> {
  placeholder: string;
  onChange: (searchValue: string) => void;
  autoFocus?: boolean;
  isDisabled?: boolean;
  submitOnEnter?: boolean;
  initialSearchValue?: string;
}

export const Searchbar: FunctionComponent<SearchbarProps> = ({
  placeholder,
  onChange,
  isDisabled = false,
  autoFocus = false,
  submitOnEnter = false,
  initialSearchValue,
  ...rest
}) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(initialSearchValue);

  return (
    <InputGroup size="lg" w="80" {...rest}>
      <Input
        variant="searchbar"
        isDisabled={isDisabled}
        placeholder={placeholder}
        onKeyDown={(event) => {
          if (!submitOnEnter && event.key === 'Enter') {
            event.preventDefault();
          }
        }}
        onChange={(event) => {
          setSearchValue(event.target.value);
          onChange(event.target.value);
        }}
        autoFocus={autoFocus}
        value={searchValue}
      />
      <InputRightElement pointerEvents={searchValue ? 'auto' : 'none'}>
        <IconButton
          variant="outline"
          borderColor="white"
          _hover={{ backgroundColor: 'white' }}
          _active={{ backgroundColor: 'white' }}
          _focus={{ borderColor: 'white' }}
          aria-label="toggles search and exit Icon"
          icon={<Image src={searchValue ? exitIcon : searchIcon} w="5" h="5" role="presentation" />}
          tabIndex={searchValue ? 0 : -1}
          onClick={() => {
            onChange('');
            setSearchValue('');
          }}
        />
      </InputRightElement>
    </InputGroup>
  );
};
