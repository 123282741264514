const account = '/account';
const config = '/configurations';

const qbeyondDomain = 'https://www.qbeyond.de';
const iotSimDomain = 'https://www.iot-sim.tech';
const devHubDomain = 'https://developer.iot-sim.tech';

export const routes = {
  /**  deprecated */
  login: '/login',
  account: {
    root: account,
    support: `${account}/support`,
    companyAccount: `${account}/company`,
  },
  config: {
    root: config,
    iotConnectorProxy: `${config}/iot-connector-proxy`,
    iotConnectorAzure: `${config}/iot-connector-azure`,
    openVpn: `${config}/open-vpn`,
    wireGuard: `${config}/wireguard`,
    ssh: `${config}/ssh`,
  },
  shopCheckout: '/checkout',
  simCards: '/sim-cards',
  simCardRegister: '/sim-cards/register',
  sim: '/sim/:iccid',
  root: '/',
  users: '/users',
  external: {
    imprint: `${qbeyondDomain}/impressum/`,
    privacy: `${qbeyondDomain}/datenschutz/`,
    termsOfUse: `${qbeyondDomain}/nutzungsbedingungen/`,
    termsAndConditions: `${iotSimDomain}/agb/`,
    shop: `${iotSimDomain}/shop/`,
    devHub: {
      wireGuard: `${devHubDomain}/articles/iot-sim-wireguard/#overview`,
      ssh: `${devHubDomain}/articles/iot-ssh/#overview`,
      connector: `${devHubDomain}/articles/iot-connector-proxy/#overview`,
    },
  },
};
