import type { AccordionButtonProps, AccordionProps } from '@chakra-ui/react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
} from '@chakra-ui/react';
import type { FunctionComponent, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface SelectedIccidAccordionProps extends AccordionProps {
  iccids: string[];
  accordionButtonProps?: AccordionButtonProps;
  accordionButtonContent?: ReactNode;
  withDescription?: boolean;
}

export const SelectedIccidsAccordion: FunctionComponent<SelectedIccidAccordionProps> = ({
  iccids,
  accordionButtonProps,
  accordionButtonContent,
  withDescription = false,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Accordion allowToggle {...rest}>
      <AccordionItem border="none" w="full">
        <AccordionButton pt="0" px="0" _hover={{ bg: 'inherit' }} {...accordionButtonProps}>
          {accordionButtonContent}
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel p="0" maxH="60" w="full" overflowY="scroll">
          {withDescription && (
            <Text>{t('shopCheckout.overview.iccidList', { count: iccids.length })}</Text>
          )}
          {iccids.map((iccid) => (
            <Box key={iccid}>{iccid}</Box>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
