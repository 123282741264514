import i18next from 'i18next';
import { SearchableColumn } from '../../../utils/graphql/hooks';

export interface ColumnOption {
  label: string;
  value: SearchableColumn;
}

export const searchableSimTableColumns: ColumnOption[] = [
  { value: SearchableColumn.Iccid, label: i18next.t('common.iccid').toString() },
  { value: SearchableColumn.Ip, label: i18next.t('sim.col.ip').toString() },
  { value: SearchableColumn.Label, label: i18next.t('sim.col.label').toString() },
  { value: SearchableColumn.Imsi, label: i18next.t('sim.col.imsi').toString() },
  { value: SearchableColumn.Msisdn, label: i18next.t('sim.col.msisdn').toString() },
  { value: SearchableColumn.Imei, label: i18next.t('sim.col.imei').toString() },
  { value: SearchableColumn.CostCenterId, label: i18next.t('sim.col.costCenterId').toString() },
];
