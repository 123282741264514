import type { NumberInputStepperProps } from '@chakra-ui/react';
import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInputStepper,
} from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/ChevronUp.svg';

export const CustomNumberInputStepper: FunctionComponent<NumberInputStepperProps> = (rest) => (
  <NumberInputStepper {...rest}>
    <NumberIncrementStepper>
      <ChevronUpIcon />
    </NumberIncrementStepper>
    <NumberDecrementStepper>
      <ChevronDownIcon />
    </NumberDecrementStepper>
  </NumberInputStepper>
);
