import { Stack, StackDivider, type StackProps } from '@chakra-ui/react';
import type { FunctionComponent, PropsWithChildren } from 'react';

interface SectionStackProps extends StackProps {
  type: 'inner' | 'outer';
}

/**
 * This Component can be used as a stack with multiple children or as a Card with
 * only one child element.
 * @param type layer of the stack, 'outer' adds a border radius
 */
export const SectionStack: FunctionComponent<PropsWithChildren<SectionStackProps>> = ({
  type,
  children,
  direction = 'column',
  ...rest
}) => (
  <Stack
    bg="white"
    spacing="0"
    direction={direction}
    divider={
      direction === 'column' ? (
        <StackDivider h="0.5" bg="gray.50" borderColor="gray.50" />
      ) : undefined
    }
    borderRadius={type === 'outer' ? 'base' : undefined}
    {...rest}
  >
    {children}
  </Stack>
);
