import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import type { ButtonProps, MenuItemProps, MenuProps } from '@chakra-ui/react';
import { Button, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import type { FunctionComponent, ReactNode } from 'react';
import { useState } from 'react';
import { defaultFontColor } from 'utils/theme/colors';

const hoverState = {
  color: 'inherit',
  borderColor: 'qGray.200',
  bg: 'white',
};

type SelectItem = string | number | boolean | undefined;

interface SelectItems extends MenuItemProps {
  id?: string;
  label?: ReactNode;
}

interface CustomSelectProps {
  items: SelectItems[];
  defaultSelectedValue?: SelectItem;
  onClick?: (value: string, index: number) => void;
  isDisabled?: boolean;
  menuProps?: MenuProps;
  menuButtonProps?: ButtonProps;
  placeholder?: string;
}

export const CustomSelect: FunctionComponent<CustomSelectProps> = ({
  items,
  defaultSelectedValue,
  isDisabled,
  onClick,
  menuProps,
  menuButtonProps,
  placeholder,
}) => {
  const [selectedValue, setSelectedValue] = useState<ReactNode>(defaultSelectedValue || '');

  return (
    <Menu matchWidth {...menuProps}>
      {({ isOpen }) => (
        <>
          <MenuButton
            as={Button}
            isDisabled={isDisabled}
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
            bg="white"
            color={defaultFontColor}
            border="1px solid"
            borderColor="qGray.100"
            size="md"
            px={3}
            aria-label="search column filter"
            textAlign="left"
            fontWeight={400}
            _hover={hoverState}
            _active={hoverState}
            _disabled={{
              bg: 'rgba(255,255,255,.4)',
              span: { color: 'qGray.200' },
            }}
            {...menuButtonProps}
          >
            {selectedValue ||
              (placeholder && (
                <Text as="span" color="qGray.400" opacity="0.6" aria-placeholder={placeholder}>
                  {placeholder}
                </Text>
              ))}
          </MenuButton>
          <MenuList>
            {items.map(({ id, label, value, ...menuItemProps }, index) => (
              <MenuItem
                key={String(id || value || label)}
                value={value}
                {...menuItemProps}
                onClick={() => {
                  const val = String(value);
                  if (val) {
                    onClick?.(val, index);
                    setSelectedValue(label);
                  }
                }}
              >
                {label}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
