import { Spinner, Stack, Text } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { XTerm } from 'xterm-for-react';
import { TerminalX } from './Terminal';
import { WsStatus } from './types';
import type { ConnectionStatus, WsFuncs } from './types';
import type { WebsocketWrapper } from './websocket';
import { ReactComponent as ConnectionError } from 'assets/icons/ConnectionError.svg';

interface RenderProps {
  conStatus: ConnectionStatus;
  ws: WebsocketWrapper;
  funcs: WsFuncs;
  terminal: React.MutableRefObject<XTerm | null>;
}

export const Renderer: FunctionComponent<RenderProps> = ({ conStatus, ws, funcs, terminal }) => {
  const { t } = useTranslation();

  if (conStatus.status === WsStatus.FAILED) {
    return (
      <Stack align="center">
        <ConnectionError />
      </Stack>
    );
  }

  if (conStatus.status === WsStatus.SUCCESS || conStatus.status === WsStatus.CLOSED) {
    return <TerminalX ws={ws} connect={funcs} terminal={terminal} />;
  }

  return (
    <Stack align="center">
      <Spinner size="xl" label="" />
      <Text fontSize="xl">{t('sim.sshAuthenticating')}</Text>
    </Stack>
  );
};
