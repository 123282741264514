import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useRef, type FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  ActivationModalContent,
  BulkAction,
  SelectedSimTable,
  VolumeReloadModalContent,
} from './components';
import { RenameSimForm } from 'components';
import {
  ShopProductSku,
  type Sim,
  SimState,
  UserGroup,
  useActivateSimCardsMutation,
  useDeactivateSimCardsMutation,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';
import { useSelectedSimCardsContext } from 'utils/provider/SelectedSimProvider';
import { useShopCheckoutContext } from 'utils/provider/ShopCheckoutProvider';
import { routes } from 'utils/routes';
import { userCanActivateSim, userCanNotActivateSim } from 'utils/simUtils';

export interface SelectedSimCardsByState {
  active: Sim[];
  activationPending: Sim[];
  userDeactivated: Sim[];
  suspendPending: Sim[];
  systemDeactivated: Sim[];
}

export type BulkOperation = 'activate' | 'deactivate' | 'rename' | 'reload';

export const BulkMenu: FunctionComponent = () => {
  const { t } = useTranslation();
  const { selectedSimCards, clearSelectedSimCards } = useSelectedSimCardsContext();
  const { setOrderItems } = useShopCheckoutContext();
  const { hasRequiredRole } = useAuthUserContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const { mutateAsync: deactivateAsync, isPending: isDeactivating } =
    useDeactivateSimCardsMutation();
  const { mutateAsync: activateAsync, isPending: isActivating } = useActivateSimCardsMutation();

  const dataVolumeInputRef = useRef<HTMLInputElement>(null);

  const selectedSimCardsByState = useMemo(
    () => ({
      active: selectedSimCards.filter((sim) => sim.state === SimState.Active),
      activationPending: selectedSimCards.filter((sim) => sim.state === SimState.ActivationPending),
      userDeactivated: selectedSimCards.filter((sim) => userCanActivateSim(sim)),
      suspendPending: selectedSimCards.filter((sim) => sim.state === SimState.SuspendPending),
      systemDeactivated: selectedSimCards.filter((sim) => userCanNotActivateSim(sim)),
    }),
    [selectedSimCards],
  );

  const addPendingCardsAmountForSysAdmin = useCallback(
    (pendingCards: Sim[]) => (hasRequiredRole(UserGroup.SystemAdmin) ? pendingCards.length : 0),
    [hasRequiredRole],
  );

  const handleDeactivateClick = useCallback(async () => {
    if (selectedSimCardsByState.active.length) {
      const res = await deactivateAsync({
        iccids: selectedSimCardsByState.active.map((sim) => sim.iccid),
      });

      if (res.changeSimActivationState.length) {
        successToast({
          title: t('sim.bulk.deactivationSuccess', { count: res.changeSimActivationState.length }),
        });
      } else {
        errorToast({
          title: t('sim.bulk.deactivationFailure', {
            count: selectedSimCardsByState.active.length,
          }),
        });
      }

      await queryClient.invalidateQueries({ queryKey: ['SimPage.infinite'] });
    }
  }, [selectedSimCardsByState.active, deactivateAsync, queryClient, successToast, t, errorToast]);

  const handleActivateClick = useCallback(async () => {
    if (selectedSimCardsByState.userDeactivated.length) {
      const res = await activateAsync({
        iccids: selectedSimCardsByState.userDeactivated.map((sim) => sim.iccid),
      });

      if (res.changeSimActivationState.length) {
        successToast({
          title: t('sim.bulk.activationSuccess', { count: res.changeSimActivationState.length }),
        });
      } else {
        errorToast({
          title: t('sim.bulk.activationFailure', {
            count: selectedSimCardsByState.userDeactivated.length,
          }),
        });
      }

      await queryClient.invalidateQueries({ queryKey: ['SimPage.infinite'] });
    }
  }, [
    selectedSimCardsByState.userDeactivated,
    activateAsync,
    queryClient,
    successToast,
    t,
    errorToast,
  ]);

  return (
    <Center>
      <VStack
        mt="4"
        p="8"
        w="80%"
        maxW="60rem"
        spacing="6"
        justify="center"
        bg="white"
        boxShadow="overlay"
        borderRadius="lg"
        role="menubar"
      >
        <Accordion allowToggle w="full">
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <Grid templateColumns={['1fr', null, '1fr 1fr']} gap="2">
                  <Flex gap="3" fontWeight="bold" fontSize="xl">
                    {`${t('sim.bulk.selected')}:`}
                    <Center
                      data-testid="selectedSimCardsCount"
                      boxSize="7"
                      bg="qGray.500"
                      color="white"
                      borderRadius="base"
                    >
                      {selectedSimCards.length}
                    </Center>
                  </Flex>

                  <Flex justify={[null, null, 'end']}>
                    <AccordionButton pt="0" px="0" w="fit-content" _hover={{ bg: 'inherit' }}>
                      {isExpanded ? t('sim.bulk.hideDetails') : t('sim.bulk.showDetails')}
                      <AccordionIcon />
                    </AccordionButton>
                  </Flex>
                </Grid>
                <AccordionPanel mt="4" p="0" maxH="80" w="full" overflowX="auto">
                  <SelectedSimTable />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>

        <Flex gap="4" flexWrap="wrap" w="full" justify={['center', null, 'flex-end']}>
          <Button variant="secondary" onClick={clearSelectedSimCards}>
            {t('sim.bulk.deselectAll')}
          </Button>

          {process.env.REACT_APP_TOGGLE_RELOAD_DATA_VOLUME === 'true' && (
            <BulkAction
              operation="reload"
              applyButtonConfig={{
                displayButton: true,
                onApply: () => {
                  if (dataVolumeInputRef.current?.value) {
                    const quantity = Number(dataVolumeInputRef.current.value);
                    setOrderItems(
                      selectedSimCards.map((sim) => ({
                        iccid: sim.iccid,
                        sku: ShopProductSku.Datavolume_1gb,
                        quantity,
                      })),
                    );
                    navigate(routes.shopCheckout);
                  }
                },
              }}
              renderModalBody={(onClose) => (
                <VolumeReloadModalContent
                  simCards={selectedSimCards}
                  inputRef={dataVolumeInputRef}
                  onClose={onClose}
                />
              )}
              onCloseAction={() =>
                queryClient.invalidateQueries({ queryKey: ['SimPage.infinite'] })
              }
              simCount={selectedSimCards.length}
            >
              {t('sim.dataVolume.volumeReload')}
            </BulkAction>
          )}

          <BulkAction
            operation="rename"
            renderModalBody={
              <VStack spacing={4}>
                <Text pb="2">{t('sim.bulk.renameCount', { count: selectedSimCards.length })}</Text>
                <Box px={[null, '8']} w="full">
                  <RenameSimForm refetchSimsOnSuccess={false} />
                </Box>
              </VStack>
            }
            applyButtonConfig={{ displayButton: false }}
            onCloseAction={() => queryClient.invalidateQueries({ queryKey: ['SimPage.infinite'] })}
            simCount={selectedSimCards.length}
          >
            {t('sim.bulk.rename')}
          </BulkAction>

          <BulkAction
            operation="activate"
            applyButtonConfig={{ onApply: handleActivateClick }}
            renderModalBody={(onClose) => (
              <ActivationModalContent
                selectedSimCardsByState={selectedSimCardsByState}
                onClose={onClose}
              />
            )}
            isLoading={isActivating}
            simCount={
              selectedSimCardsByState.userDeactivated.length +
              addPendingCardsAmountForSysAdmin(selectedSimCardsByState.activationPending)
            }
          >
            {t('common.activate')}
          </BulkAction>

          <BulkAction
            operation="deactivate"
            applyButtonConfig={{ onApply: handleDeactivateClick }}
            renderModalBody={
              <Text textAlign="center">
                {t('sim.bulk.deactivateCount', {
                  count:
                    selectedSimCardsByState.active.length +
                    addPendingCardsAmountForSysAdmin(selectedSimCardsByState.suspendPending),
                })}
              </Text>
            }
            isLoading={isDeactivating}
            simCount={
              selectedSimCardsByState.active.length +
              addPendingCardsAmountForSysAdmin(selectedSimCardsByState.suspendPending)
            }
          >
            {t('common.deactivate')}
          </BulkAction>
        </Flex>
      </VStack>
    </Center>
  );
};
