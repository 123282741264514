import { useQueryClient } from '@tanstack/react-query';
import type { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { OrderItem } from 'utils/graphql/hooks';
import { useCompanyQuery, useShopProductQuery, ShopProductSku } from 'utils/graphql/hooks';

const subscriptionProductSkus = [ShopProductSku.WireguardBase_1m, ShopProductSku.WireguardXl_1m];

interface ShopCheckoutContextContent {
  orderItems: OrderItem[];
  setOrderItems: Dispatch<SetStateAction<OrderItem[]>>;
  subscriptionProductSkus: ShopProductSku[];
  containsSubscriptionProducts: boolean;
  prefetchShopCheckoutPageData: (sku: ShopProductSku) => void;
}

export const ShopCheckoutContext = createContext<ShopCheckoutContextContent>({
  orderItems: [],
  setOrderItems: () => {},
  subscriptionProductSkus,
  containsSubscriptionProducts: false,
  prefetchShopCheckoutPageData: () => {},
});

export const useShopCheckoutContext = () => useContext(ShopCheckoutContext);

export const ShopCheckoutProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();

  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);

  const containsSubscriptionProducts = useMemo(
    () => orderItems.some((item) => subscriptionProductSkus.includes(item.sku)) ?? false,
    [orderItems],
  );

  const prefetchShopCheckoutPageData = useCallback(
    (sku: ShopProductSku) => {
      // not awaited on purpose, we only want to decrease loading time if possible
      queryClient.prefetchQuery({
        queryKey: useCompanyQuery.getKey(),
        queryFn: useCompanyQuery.fetcher(),
        staleTime: 30000,
        retry: false,
      });
      queryClient.prefetchQuery({
        queryKey: useShopProductQuery.getKey({ sku }),
        queryFn: useShopProductQuery.fetcher({ sku }),
        staleTime: 30000,
        retry: false,
      });
    },
    [queryClient],
  );

  const contextValue = useMemo(
    () => ({
      orderItems,
      setOrderItems,
      subscriptionProductSkus,
      containsSubscriptionProducts,
      prefetchShopCheckoutPageData,
    }),
    [containsSubscriptionProducts, orderItems, prefetchShopCheckoutPageData],
  );

  return (
    <ShopCheckoutContext.Provider value={contextValue}>{children}</ShopCheckoutContext.Provider>
  );
};
