import { Text } from '@chakra-ui/react';
import type { Cell } from '@tanstack/react-table';
import type { FunctionComponent } from 'react';
import type { HttpHeaderViewModel } from '..';

interface ValueHidingCellProps {
  cell: Cell<HttpHeaderViewModel, unknown>;
}

export const ValueHidingCell: FunctionComponent<ValueHidingCellProps> = ({
  cell,
}: ValueHidingCellProps) =>
  cell.row.original.isDefault || cell.row.original.isValueShown ? (
    <Text>{cell.row.original.value}</Text>
  ) : (
    <Text>⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁⦁</Text>
  );
