import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import i18next from 'i18next';
import { SessionColumnAccessor } from './SessionColumnAccessor';
import { SessionColumnHeader } from './SessionColumnHeader';
import { SimSelectRowCell } from './SimSelectRowCell';
import { StateColumnAccessor } from './StateColumnAccessor';
import { SimSortBy, SortDirection, type Sim } from 'utils/graphql/hooks';
import { dataVolumeUsedText, smsVolumeUsedText } from 'utils/simUtils';

export const rowSelectColumnKey = 'rowSelect';

// has to be a key of SimCard - strict typing is not advantageous
type SimCardKey = string;

// has to be a key of SimCard - strict typing is not advantageous due to react table
// takes and provide the values only as a key-value object
export const initialColumnVisibility: { [column_id: SimCardKey]: boolean } = {
  [rowSelectColumnKey]: process.env.REACT_APP_TOGGLE_SIM_MULTISELECT === 'true',
  imsi: false,
  msisdn: false,
  imei: false,
  costCenterId: false,
  apn: false,
  type: false,
  smsVolume: false,
};

export const nonSelectableColumns: SimCardKey[] = [
  rowSelectColumnKey,
  'state',
  'session',
  'iccid',
  'ip',
  'label',
  'dataVolume',
];

export const sortableColumns: SimCardKey[] = [
  'iccid',
  'ip',
  'label',
  'dataVolume',
  'imsi',
  'msisdn',
  'imei',
  'costCenterId',
  'apn',
  'type',
  'smsVolume',
];

export const defaultSimPagination = {
  first: 10,
  offset: 0,
  sortBy: SimSortBy.DataVolume,
  sortDirection: SortDirection.Desc,
};

const columnHelper = createColumnHelper<Sim>();

export const simTableColumns: ColumnDef<Sim, any>[] = [
  columnHelper.accessor('id', {
    id: rowSelectColumnKey,
    header: () => '',
    cell: (cell) => SimSelectRowCell(cell),
  }),
  columnHelper.accessor('state', {
    id: 'state',
    header: () => i18next.t('common.state').toString(),
    cell: ({ row }) => StateColumnAccessor(row.original),
  }),
  columnHelper.accessor('session', {
    id: 'session',
    header: ({ header }) => SessionColumnHeader(header),
    cell: ({ row }) => SessionColumnAccessor(row.original),
  }),
  columnHelper.accessor('iccid', {
    id: 'iccid',
    header: () => i18next.t('common.iccid').toString(),
  }),
  columnHelper.accessor('ip', {
    id: 'ip',
    header: () => i18next.t('sim.col.ip').toString(),
  }),
  columnHelper.accessor('label', {
    id: 'label',
    header: () => i18next.t('sim.col.label').toString(),
  }),
  columnHelper.accessor('data', {
    id: 'dataVolume',
    header: () => i18next.t('sim.col.dataVolume').toString(),
    cell: ({ row }) => dataVolumeUsedText(row.original.data),
  }),
  columnHelper.accessor('imsi', {
    id: 'imsi',
    header: () => i18next.t('sim.col.imsi').toString(),
  }),
  columnHelper.accessor('msisdn', {
    id: 'msisdn',
    header: () => i18next.t('sim.col.msisdn').toString(),
  }),
  columnHelper.accessor('imei', {
    id: 'imei',
    header: () => i18next.t('sim.col.imei').toString(),
  }),
  columnHelper.accessor('costCenterId', {
    id: 'costCenterId',
    header: () => i18next.t('sim.col.costCenterId').toString(),
  }),
  columnHelper.accessor('apn', {
    id: 'apn',
    header: () => i18next.t('sim.col.apn').toString(),
  }),
  columnHelper.accessor('type', {
    id: 'type',
    header: () => i18next.t('sim.col.type').toString(),

    cell: ({ row }) => i18next.t(`sim.simCardType.${row.original.type}`).toString(),
  }),
  columnHelper.accessor('sms.count', {
    id: 'smsVolume',
    header: () => i18next.t('sim.col.smsVolume').toString(),
    cell: ({ row }) => smsVolumeUsedText(row.original.sms),
  }),
];
