import { Spinner, Stack, Text, type StackProps } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export interface LoadingProps {
  loadingResourceName?: string;
}

interface LoadingWithStackProps extends LoadingProps, StackProps {}

export const Loading: FunctionComponent<LoadingWithStackProps> = ({
  loadingResourceName,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Stack align="center" {...rest}>
      {/* default label is 'Loading...' - no label needed because a descriptive text is already rendered below */}
      <Spinner size="xl" label="" />
      <Text fontSize="xl">{`${t('common.loading')}${
        loadingResourceName ? ` ${loadingResourceName}` : ''
      }...`}</Text>
    </Stack>
  );
};
