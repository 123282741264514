import type { UseDisclosureProps } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'components';
import {
  type Header,
  useGetIotConnectorGroupsQuery,
  useUpdateIotConnectorGroupMutation,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

export interface DeleteHeaderModalProps extends UseDisclosureProps {
  httpHeaders: Header[];
  header: Header | null;
  groupId: string;
}

export const RemoveHeaderModal: FunctionComponent<DeleteHeaderModalProps> = ({
  isOpen = false,
  onClose = () => {},
  header,
  httpHeaders,
  groupId,
}) => {
  const { t } = useTranslation();

  const { mutateAsync, isPending } = useUpdateIotConnectorGroupMutation();

  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const queryClient = useQueryClient();

  const deleteHeader = async () => {
    try {
      await mutateAsync({
        groupId,
        headers: [...httpHeaders.filter((httpHeader) => httpHeader.key !== header?.key)],
      });

      await queryClient.refetchQueries({ queryKey: useGetIotConnectorGroupsQuery.getKey() });

      successToast({ title: t('config.iotConnectorProxy.removeHeaderSuccess') });
    } catch (e) {
      errorToast({
        title:
          (e instanceof Error && t('config.iotConnectorProxy.removeHeaderError')) ||
          t('error.generic'),
        ...(e instanceof Error && { description: e.message }),
      });
    }

    onClose();
  };

  return (
    <ConfirmModal
      translations={{
        header: t('config.iotConnectorProxy.removeHeader'),
        content: `${t('config.iotConnectorProxy.removeHeaderQuestion', {
          key: header?.key,
          value: header?.value,
        })}`,
      }}
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={deleteHeader}
      isConfirmationLoading={isPending}
    />
  );
};
