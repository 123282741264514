import { type UseDisclosureProps } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import i18next from 'i18next';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import type { SelectedUserActionState } from '..';
import { ConfirmModal } from 'components/ConfirmModal';
import { useResendUserInvitationMutation, useSoftDeleteUserMutation } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

// declared in our APIs lambda function
const errorReasons = ['USER_DISABLED', 'USER_CONFIRMED', 'INVALID_CONFIRMATION_STATUS'];

const errorDescription = (error: unknown) => {
  if (error instanceof Error && errorReasons.includes(error.message)) {
    return i18next.t(`user.error.resendInvitation.${error.message}`);
  }

  return undefined;
};

interface UserActionConfirmationModalInterface
  extends Omit<UseDisclosureProps, 'isOpen'>,
    SelectedUserActionState {}

export const UserActionConfirmationModal: FunctionComponent<
  UserActionConfirmationModalInterface
> = ({ user, actionType, isModalOpen, onClose = () => {} }) => {
  const { t } = useTranslation();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });
  const queryClient = useQueryClient();

  const { mutateAsync: mutateDeleteAsync, isPending: isDeleteLoading } =
    useSoftDeleteUserMutation();
  const { mutateAsync: mutateResendInvitationAsync, isPending: isResendInvitationLoading } =
    useResendUserInvitationMutation();

  const resendInvitation = async () => {
    if (user) {
      try {
        await mutateResendInvitationAsync({ email: user.email });

        successToast({ title: t('user.resendInvitationSuccess', { email: user.email }) });
        onClose();
      } catch (error) {
        errorToast({
          title: t('user.resendInvitationError', { email: user.email }),
          description: errorDescription(error),
        });

        // get updated data in case the user was activated in the meantime
        await queryClient.refetchQueries({ queryKey: ['UserList'] });
      }
    }
  };

  const deleteUser = async () => {
    if (user) {
      try {
        const { softDeleteUser } = await mutateDeleteAsync({
          username: user.id,
        });

        if (softDeleteUser) {
          successToast({ title: t('user.deleteSuccess', { email: user.email }) });
          await queryClient.refetchQueries({ queryKey: ['UserList'] });
          onClose();
        } else {
          errorToast({ title: t('user.deleteError') });
        }
      } catch (error) {
        errorToast({ title: (error instanceof Error && error.message) || t('error.generic') });
      }
    }
  };

  if (actionType === 'delete') {
    return (
      <ConfirmModal
        isOpen={isModalOpen}
        translations={{
          header: t('user.delete'),
          content: t('user.deleteDescription', { email: user?.email }),
          apply: t('user.delete'),
          applyLoading: t('common.deleting'),
        }}
        isConfirmationLoading={isDeleteLoading}
        onConfirm={deleteUser}
        onCancel={onClose}
      />
    );
  }

  return (
    <ConfirmModal
      isOpen={isModalOpen}
      translations={{
        header: t('user.resendInvitation'),
        content: t('user.resendInvitationDescription', { email: user?.email }),
        apply: t('user.resendInvitation'),
        applyLoading: t('common.resending'),
      }}
      isConfirmationLoading={isResendInvitationLoading}
      onConfirm={resendInvitation}
      onCancel={onClose}
    />
  );
};
