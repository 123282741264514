import { Box, Link, Stack, Text } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { Trans } from 'react-i18next';
import { ExternalLinkIcon } from 'components';

export interface MoreInfoTextProps {
  link: string;
}
export const MoreInfoText: FunctionComponent<MoreInfoTextProps> = ({ link }) => (
  <Stack direction="row">
    <Text>
      <Trans
        i18nKey="config.moreInfo"
        components={[
          <Link
            href={link}
            key={link}
            textDecoration="underline"
            variant="hoverBrand"
            isExternal
          />,
        ]}
      />
    </Text>
    <Box boxSize="4" alignSelf="center">
      <ExternalLinkIcon />
    </Box>
  </Stack>
);
