import { Button, Grid, HStack, Heading, Link, Text, VStack } from '@chakra-ui/react';
import type { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import i18next from 'i18next';
import { useMemo, type FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { WireGuardConfigAutomaticReload } from './WireGuardConfigAutomaticReload';
import { AccountDataField, InputContainer, SectionError } from 'components';
import { downloadTextAsTxtFile } from 'utils';
import {
  WireGuardDeploymentStatus,
  type WireGuardConfig,
  type WireGuardQuery,
} from 'utils/graphql/hooks';
import { routes } from 'utils/routes';

const createPeerConfigTemplate = (config: WireGuardConfig) =>
  `[Interface]
PrivateKey = YOUR_CLIENT_PRIVATE_KEY
ListenPort = 51820
${i18next.t('config.wireGuard.address')} = ${config.status.clientIp}/32

[Peer]
PublicKey = ${config.status.wgPublicKey}
AllowedIPs = 10.90.0.0/16
${i18next.t('config.wireGuard.pingableTestIp')} = 169.254.13.37/32
${i18next.t('config.wireGuard.endpoint')} = ${config.status.wgUrl}:51820
PersistentKeepalive = 25`;

export interface WireGuardConfigInfoProps {
  wireGuardConfigs: WireGuardConfig[];
  hasActiveSubscription: boolean;
  refetchConfig: (
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<WireGuardQuery, Error>>;
}

export const WireGuardConfigInfo: FunctionComponent<WireGuardConfigInfoProps> = ({
  wireGuardConfigs,
  hasActiveSubscription,
  refetchConfig,
}) => {
  const { t } = useTranslation();
  const peerConfigTemplates = useMemo(
    () => wireGuardConfigs.map(createPeerConfigTemplate),
    [wireGuardConfigs],
  );

  return (
    <VStack alignItems="normal" color={!hasActiveSubscription ? 'blackAlpha.500' : 'inherit'}>
      <Heading fontSize="lg">
        {t(`config.wireGuard.peerConfigHeader${hasActiveSubscription ? '' : 'Disabled'}`)}
      </Heading>
      <Text>{t('config.wireGuard.peerConfigDescription')}</Text>
      {wireGuardConfigs.map((config, index) => (
        <Grid key={config.uuid} mt={4}>
          {config.status.deploymentStatus === WireGuardDeploymentStatus.Deploying && (
            <WireGuardConfigAutomaticReload configId={config.uuid} refetchConfig={refetchConfig} />
          )}
          {config.status.deploymentStatus === WireGuardDeploymentStatus.Failed && (
            <SectionError title={t('config.wireGuard.error.configFailed')}>
              <Text>
                <Trans i18nKey="config.wireGuard.error.configFailedMessage">
                  <Link
                    href={routes.account.support}
                    textDecoration="underline"
                    variant="hoverBrand"
                  />
                </Trans>
              </Text>
            </SectionError>
          )}
          {config.status.deploymentStatus === WireGuardDeploymentStatus.Done && (
            <>
              <AccountDataField
                title={t('config.wireGuard.clientIp')}
                data={config.status.clientIp}
                mx="0"
              />
              <AccountDataField
                title={t('config.wireGuard.publicKey')}
                data={config.spec.clientPublicKey}
                mx="0"
              />
              <AccountDataField
                title={t('config.wireGuard.endpoint')}
                data={config.status.wgUrl}
                mx="0"
              />

              <InputContainer mx="0">
                <HStack pb="2">
                  <Text>{t('config.wireGuard.peerConfigTempl')}</Text>
                  <Button
                    variant="outline"
                    onClick={() =>
                      downloadTextAsTxtFile(peerConfigTemplates[index], 'wg_config.txt')
                    }
                  >
                    {t('common.download')}
                  </Button>
                </HStack>
                <Text
                  px="4"
                  py="1"
                  w="full"
                  border="1px"
                  borderColor="qGray.100"
                  borderRadius="base"
                  fontWeight="bold"
                  whiteSpace="pre"
                  data-testid="peer-config-template"
                >
                  {peerConfigTemplates[index]}
                </Text>
              </InputContainer>
            </>
          )}
        </Grid>
      ))}
    </VStack>
  );
};
