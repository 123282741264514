import { Link as ChakraLink } from '@chakra-ui/react';
import type { FunctionComponent, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SectionStack } from 'components/SectionStack';
import { colors } from 'utils/theme/colors';

export const hoverSideMenuItemStyle = {
  boxShadow: 'none',
  color: 'qGray.300',
};

const focusSideMenuItemStyle = {
  boxShadow: 'sideMenuInset',
};

const activeSideMenuItemStyle = {
  ...focusSideMenuItemStyle,
  color: colors.brand[500],
};

export interface SideMenuLink {
  to: string;
  text: string;
}

interface SideMenuProps {
  links: SideMenuLink[];
  action?: ReactNode;
}

/**
 * @param links array of links which should be displayed in the side menu
 * @param action ReactNode for e.g. a logout button
 */
export const SideMenu: FunctionComponent<SideMenuProps> = ({ links, action }) => {
  const location = useLocation();

  return (
    <SectionStack type="outer" maxW="48" h="fit-content">
      {links.map((link) => {
        const isCurrentPage = location.pathname.replace(/\/$/, '') === link.to; // Cut last "/" and compare

        return (
          <ChakraLink
            as={Link}
            key={link.text}
            to={link.to}
            py="4"
            px="5"
            fontWeight="bold"
            {...(isCurrentPage && activeSideMenuItemStyle)}
            _focus={focusSideMenuItemStyle}
            _hover={hoverSideMenuItemStyle}
          >
            {link.text}
          </ChakraLink>
        );
      })}
      {action}
    </SectionStack>
  );
};
