// eslint-disable-next-line import/no-extraneous-dependencies
import { Box, Button, SimpleGrid, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import type { FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { CoapRequest, Header, ProxyConfigInput } from '../../../../utils/graphql/hooks';
import { useSimulateProxyQuery } from '../../../../utils/graphql/hooks';
import { useIotSimToast } from '../../../../utils/hooks';
import { CoapRequestConfig } from './SimulateConfig/CoapRequestConfig';
import { ProxyConfig } from './SimulateConfig/ProxyConfig';
import { SimulateResult } from './SimulateConfig/SimulateResult';
import { BaseModal } from 'components';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

interface SimulateProxyProps {
  targetUrl: string;
  httpHeaders: Header[];
  openSimulateProxy: boolean;
  onClose: () => void;
}
export const SimulateProxy: FunctionComponent<SimulateProxyProps> = ({
  targetUrl,
  httpHeaders,
  openSimulateProxy,
  onClose,
}) => {
  const { t } = useTranslation();
  const { readonlyProps } = useAuthUtils();
  const errorToast = useIotSimToast({
    status: 'error',
  });
  const successToast = useIotSimToast({
    status: 'success',
  });
  const [simulate, setSimulate] = useState<boolean>(false);
  const [proxyConfig, setProxyConfig] = useState<ProxyConfigInput>({
    headers: httpHeaders,
    oauth: { client_id: '', client_secret: '', scopes: '', token_url: '' },
    target_url: targetUrl,
  });
  const [coapRequest, setCoapRequest] = useState<CoapRequest>({
    path: '',
    body: '',
    code: 1,
    media_type: 0,
  });
  const { data, isError } = useSimulateProxyQuery(
    {
      coapRequest,
      proxyConfigInput: proxyConfig,
    },
    {
      enabled: simulate,
    },
  );

  useEffect(() => {
    if (data) {
      setSimulate(false);
      successToast({
        title: t('config.iotConnectorProxy.simulationSuccess'),
      });
    }
    if (isError) {
      errorToast({
        title: t('config.iotConnectorProxy.simulationFailed'),
      });
    }
  }, [data, isError, errorToast, successToast, t]);

  const queryClient = useQueryClient();

  return (
    <BaseModal
      size={['xs', 'md', '2xl', '3xl']}
      isOpen={openSimulateProxy}
      header={<Text fontSize="xl">{t('config.iotConnectorProxy.simulate')}</Text>}
      footer={
        <Button
          onClick={async () => {
            await queryClient.resetQueries({
              queryKey: useSimulateProxyQuery.getKey({
                proxyConfigInput: proxyConfig,
                coapRequest,
              }),
            });
            onClose();
          }}
        >
          {t('common.close')}
        </Button>
      }
      onClose={onClose}
    >
      <Box flexDirection="column">
        <SimpleGrid minChildWidth="10rem" spacing="1rem">
          <CoapRequestConfig
            coapType={{
              coapRequestInput: coapRequest,
              setConfig: setCoapRequest,
            }}
          />

          <ProxyConfig
            proxyConfig={{
              proxyConfigInput: proxyConfig,
              setConfig: setProxyConfig,
            }}
          />
        </SimpleGrid>
        <Box mt="2rem" width="100%" display="flex" justifyContent="center">
          <Button
            isLoading={simulate}
            loadingText="Simulating"
            maxW="max-content"
            variant="secondary"
            spinnerPlacement="end"
            onClick={() => setSimulate(true)}
            {...readonlyProps}
          >
            {t('config.iotConnectorProxy.simulateBtn')}
          </Button>
          {data && (
            <Box ml={5} width="25rem">
              <SimulateResult result={data} />
            </Box>
          )}
        </Box>
      </Box>
    </BaseModal>
  );
};
