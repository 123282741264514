import { Heading, Stack, Text } from '@chakra-ui/react';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

interface SectionErrorProps {
  title?: string;
  message?: string;
}

export const SectionError: FunctionComponent<PropsWithChildren<SectionErrorProps>> = ({
  title,
  message,
  children,
}) => {
  const { t } = useTranslation();

  return (
    <Stack py="20" align="center">
      <Heading as="h3" size="md">
        {title || t('error.generic')}
      </Heading>
      {message && <Text>{message}</Text>}
      {children}
    </Stack>
  );
};
