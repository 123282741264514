import { Heading, Text, VStack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionLoading, SectionStack } from '../../../components';
import { useGetIotConnectorGroupsQuery } from '../../../utils/graphql/hooks';
import { MoreInfoText } from '../components/MoreInfoText';
import { SSHConfigurationForm } from './components/SSHConfigurationForm';
import { routes } from 'utils/routes';

export const SSHConfigurationPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetIotConnectorGroupsQuery();

  return (
    <VStack alignItems="stretch">
      <SectionStack type="outer">
        <VStack alignItems="normal" py={[4, 7]} px={[5, 10]}>
          <Heading size="lg">{t('config.ssh.heading')}</Heading>

          <Text>{t('config.ssh.sshDescription')}</Text>

          <MoreInfoText link={routes.external.devHub.ssh} />
        </VStack>
        {isLoading && <SectionLoading />}
      </SectionStack>
      {data && (
        <SSHConfigurationForm
          groupId={data.getIotConnectorGroups.defaultGroup.id}
          ssh={data.getIotConnectorGroups.defaultGroup.ssh || undefined}
        />
      )}
    </VStack>
  );
};
