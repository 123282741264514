import { type ComponentStyleConfig } from '@chakra-ui/react';

export const Table: ComponentStyleConfig = {
  baseStyle: {
    th: {
      textTransform: 'none',
      letterSpacing: 'none',
    },
    td: {
      whiteSpace: 'nowrap',
    },
  },
  sizes: {
    md: {
      th: {
        fontSize: 'md',
        padding: '0.5rem',
      },
      td: {
        padding: '1rem 0.5rem',
      },
    },
  },
  variants: {
    simple: {
      th: {
        borderColor: 'gray.400',
      },
      td: {
        borderColor: 'qGray.100',
      },
    },
    clickable: {
      th: {
        borderBottom: '1px',
        borderColor: 'gray.400',
      },
      td: {
        borderBottom: '1px',
        borderColor: 'qGray.100',
      },
      tbody: {
        tr: {
          cursor: 'pointer',
          _hover: {
            backgroundColor: 'qGray.50',
          },
        },
      },
    },
  },
  defaultProps: {
    colorScheme: 'qGray',
  },
};
