import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { InputValidation, luhnCalculate, melitaIccidPrefix } from 'utils';

const classicSim = 'c';
const embeddedSim = 'm';

const expandICCID = (iccid: string) =>
  iccid.length === 13 ? `${melitaIccidPrefix}${iccid}` : iccid;
const nextIccid = (start: string, count: number = 1) =>
  luhnCalculate(start.substring(0, 6) + (count + Number(start.substring(6, 18))));

export const useSimActivationUrlParams = () => {
  const { iccid } = useParams();
  const [searchParams] = useSearchParams({
    c: '1',
    t: classicSim,
  });
  const simAmount = Number(searchParams.get('c'));
  const simType = searchParams.get('t');
  const hmac = searchParams.get('m');
  const searchIccid = searchParams.get('iccid');

  const fullIccidFromQrLink = useMemo(() => {
    let completeIccid: string | null = null;

    if (iccid) {
      completeIccid = expandICCID(iccid);
    } else if (searchIccid) {
      completeIccid = expandICCID(searchIccid);
    }

    return completeIccid && InputValidation.iccid.test(completeIccid) ? completeIccid : null;
  }, [iccid, searchIccid]);

  const lastIccid =
    fullIccidFromQrLink && simAmount > 1 ? nextIccid(fullIccidFromQrLink, simAmount - 1) : null;

  return {
    simAmount,
    type: simType,
    isClassicSim: simType === classicSim,
    isEmbeddedSim: simType === embeddedSim,
    hmac,
    firstIccid: fullIccidFromQrLink,
    lastIccid,
  };
};
