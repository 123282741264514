import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Text,
  Tooltip,
  type GridProps,
} from '@chakra-ui/react';
import { useEffect, useRef, type FunctionComponent, type PropsWithChildren } from 'react';
import { type UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import type { EditableCreateOrderMutationVariables } from '..';
import { ReactComponent as UpdateIcon } from 'assets/icons/Update.svg';
import { InputContainer } from 'components';
import type { Company } from 'utils/graphql/hooks';
import { getCountryNameOfCode } from 'utils/i18n';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

const CheckoutInputContainer: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <InputContainer mx="0">{children}</InputContainer>
);

const CheckoutFormLabel: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <FormLabel mb="1">{children}</FormLabel>
);

const inputProps = {
  size: 'sm',
  variant: 'shop',
};

interface InvoiceAddressProps extends GridProps {
  company: Company;
  formReturn: UseFormReturn<EditableCreateOrderMutationVariables>;
  isFormSubmitting: boolean;
}

export const InvoiceAddress: FunctionComponent<InvoiceAddressProps> = ({
  company,
  formReturn,
  isFormSubmitting,
  ...rest
}) => {
  const { t } = useTranslation();
  const { authUser } = useAuthUserContext();

  const {
    register,
    getValues,
    resetField,
    formState: { dirtyFields, errors },
  } = formReturn;

  const tooltipCheckboxWrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // removes tab index from tooltip wrapper span
    if (tooltipCheckboxWrapperRef.current) {
      tooltipCheckboxWrapperRef.current
        .querySelector(':scope > span[tabIndex]')
        ?.setAttribute('tabIndex', '-1');
    }
  }, [dirtyFields.invoiceDetails]);

  useEffect(() => {
    if (!dirtyFields.invoiceDetails) {
      // reset checkbox
      resetField('overwriteCompanyAttributes');
    }
  }, [dirtyFields.invoiceDetails, resetField]);

  return (
    <>
      <Grid templateColumns="1fr" {...rest}>
        <CheckoutInputContainer>
          <FormControl variant="shop">
            <CheckoutFormLabel>{t('common.given_name')}</CheckoutFormLabel>
            <Input
              {...inputProps}
              name="user.firstName"
              value={authUser?.attributes.given_name}
              disabled
            />
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop">
            <CheckoutFormLabel>{t('common.family_name')}</CheckoutFormLabel>
            <Input
              {...inputProps}
              name="user.lastName"
              value={authUser?.attributes.family_name}
              disabled
            />
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop">
            <CheckoutFormLabel>{t('account.company.companyName')}</CheckoutFormLabel>
            <Input
              {...inputProps}
              name="invoiceDetails.companyName"
              value={company?.name}
              disabled
            />
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop">
            <CheckoutFormLabel>{t('account.company.vatId')}</CheckoutFormLabel>
            <Input name="invoiceDetails.vatId" value={company?.vatId} {...inputProps} disabled />
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop" isInvalid={!!errors.invoiceDetails?.internalReference}>
            <CheckoutFormLabel>{t('account.company.internalReference')}</CheckoutFormLabel>
            <Input
              {...register('invoiceDetails.internalReference')}
              {...inputProps}
              isDisabled={isFormSubmitting}
            />
            <FormErrorMessage whiteSpace="pre-line">
              {errors.invoiceDetails?.internalReference?.message}
            </FormErrorMessage>
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop" isInvalid={!!errors.invoiceDetails?.streetAddress}>
            <CheckoutFormLabel>{t('account.company.streetAddress')}</CheckoutFormLabel>
            <Input
              {...register('invoiceDetails.streetAddress', {
                validate: (value) =>
                  value?.trim() ? true : t('account.company.error.streetAddressRequired'),
              })}
              {...inputProps}
              isDisabled={isFormSubmitting}
            />
            <FormErrorMessage whiteSpace="pre-line">
              {errors.invoiceDetails?.streetAddress?.message}
            </FormErrorMessage>
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop" isInvalid={!!errors.invoiceDetails?.additionalAddressInfo}>
            <CheckoutFormLabel>{t('account.company.additionalAddressInfo')}</CheckoutFormLabel>
            <Input
              {...register('invoiceDetails.additionalAddressInfo')}
              {...inputProps}
              isDisabled={isFormSubmitting}
            />
            <FormErrorMessage whiteSpace="pre-line">
              {errors.invoiceDetails?.additionalAddressInfo?.message}
            </FormErrorMessage>
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop" isInvalid={!!errors.invoiceDetails?.postalCode}>
            <CheckoutFormLabel>{t('account.company.postalCode')}</CheckoutFormLabel>
            <Input
              {...register('invoiceDetails.postalCode', {
                validate: (value) =>
                  value?.trim() ? true : t('account.company.error.postalCodeRequired'),
              })}
              {...inputProps}
              isDisabled={isFormSubmitting}
            />
            <FormErrorMessage whiteSpace="pre-line">
              {errors.invoiceDetails?.postalCode?.message}
            </FormErrorMessage>
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop" isInvalid={!!errors.invoiceDetails?.city}>
            <CheckoutFormLabel>{t('account.company.city')}</CheckoutFormLabel>
            <Input
              {...register('invoiceDetails.city', {
                validate: (value) =>
                  value?.trim() ? true : t('account.company.error.cityRequired'),
              })}
              {...inputProps}
              isDisabled={isFormSubmitting}
            />
            <FormErrorMessage whiteSpace="pre-line">
              {errors.invoiceDetails?.city?.message}
            </FormErrorMessage>
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop">
            <CheckoutFormLabel>{t('account.company.country')}</CheckoutFormLabel>
            <Input
              {...inputProps}
              name="invoiceDetails.country"
              value={getCountryNameOfCode(company.country)}
              disabled
            />
          </FormControl>
        </CheckoutInputContainer>

        <CheckoutInputContainer>
          <FormControl variant="shop">
            <CheckoutFormLabel>{t('common.email')}</CheckoutFormLabel>
            <Input {...inputProps} name="user.email" value={authUser?.attributes.email} disabled />
          </FormControl>
        </CheckoutInputContainer>
      </Grid>

      <Flex align="center" ref={tooltipCheckboxWrapperRef}>
        <Tooltip
          label={t('shopCheckout.defaultCompanyAddress')}
          isDisabled={!!dirtyFields.invoiceDetails}
          shouldWrapChildren
        >
          <Checkbox
            pr="2"
            isChecked={getValues('overwriteCompanyAttributes')}
            isDisabled={!dirtyFields.invoiceDetails}
            {...register('overwriteCompanyAttributes')}
          >
            <Text>{t('shopCheckout.overwriteCompanyData')}</Text>
          </Checkbox>
        </Tooltip>

        <IconButton
          aria-label={t('shopCheckout.resetCompanyData').toLowerCase()}
          variant="ghost"
          alignSelf="center"
          size="xs"
          icon={<UpdateIcon title={t('shopCheckout.resetCompanyData')} />}
          isDisabled={!dirtyFields.invoiceDetails}
          onClick={() => resetField('invoiceDetails')}
        />
      </Flex>
    </>
  );
};
