import { Button, Center, Heading, Text, VStack } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SectionStack } from 'components';

export const NotFoundPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Center h="50vh">
      <SectionStack type="outer" mx="auto" w="md">
        <VStack my="5">
          <Heading color="brand.500">{t('notFound.ohNo')}</Heading>
          <Text>{t('notFound.404')}</Text>
        </VStack>
        <Center>
          <Button variant="primary" my="5" onClick={() => navigate(-1)}>
            <Text>{t('notFound.goBack')}</Text>
          </Button>
        </Center>
      </SectionStack>
    </Center>
  );
};
