import { Button, HStack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SimActive } from 'assets/icons/SimActive.svg';
import { ReactComponent as SimDeactivated } from 'assets/icons/SimDeactivated.svg';
import type { PingResult } from 'utils/graphql/hooks';
import { usePingQuery } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

interface PingProps {
  iccid: string;
}

export const Ping: FunctionComponent<PingProps> = ({ iccid }) => {
  const errorToast = useIotSimToast({
    status: 'error',
  });
  const { t } = useTranslation();
  const [execPing, setExecPing] = useState(false);
  const { data, isRefetching, error } = usePingQuery(
    { iccid },
    {
      enabled: execPing,
    },
  );

  useEffect(() => {
    if (data) {
      setExecPing(false);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setExecPing(false);
      errorToast({
        title: t('sim.pingError'),
      });
    }
  }, [error, errorToast, t]);

  const renderPingResponse = (ping: PingResult) => {
    if (ping.success) return <SimActive data-testid="ping-success" />;

    return <SimDeactivated data-testid="ping-failed" />;
  };

  return (
    <HStack>
      <Button
        isLoading={execPing}
        loadingText="Pinging"
        maxW="max-content"
        variant="secondary"
        onClick={() => setExecPing(true)}
        spinnerPlacement="end"
      >
        {t('sim.ping')}
      </Button>
      {data && !isRefetching && renderPingResponse(data.ping)}
    </HStack>
  );
};
