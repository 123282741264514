import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Link, Flex, Box, Heading } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface FooterProps {
  mode: 'dark' | 'light';
  items: { href: string; i18nKey: string }[];
}

export const Footer: FunctionComponent<FooterProps> = ({ mode = 'light', items }) => {
  const { t } = useTranslation();

  return (
    <Box
      w="full"
      padding={8}
      margin="auto"
      maxWidth="fullWidth"
      {...(mode === 'dark' && {
        backgroundColor: 'black',
        color: 'white',
      })}
    >
      <Heading
        as="h6"
        size="md"
        fontFamily="Brix Sans Black,Brix Sans,sans-serif"
        marginBlockEnd={4}
      >
        {t('footer.heading')}
      </Heading>
      <Flex justify="space-between" flexWrap="wrap" gap={8}>
        <Box flexBasis="3xl" paddingInlineEnd={4}>
          <Box
            as="nav"
            columnGap="4rem"
            sx={{
              columnCount: 'auto',
              columnFill: 'balanced',
              columnWidth: '11rem',
            }}
          >
            {items.map(({ i18nKey, href }) => {
              const label = t(i18nKey);

              return (
                <Link
                  key={href}
                  variant="hoverBrand"
                  href={href}
                  isExternal={href.startsWith('http')}
                  display="block"
                  lineHeight={1}
                  paddingBlock={2}
                  textTransform="capitalize"
                >
                  <ArrowForwardIcon marginBlockStart={-1} marginInlineEnd={1} />
                  {label}
                </Link>
              );
            })}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};
