import { Box, Button, Flex, Heading, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AddHeaderModal } from './AddHeaderModal';
import { HttpHeadersTable } from './HttpHeadersTable';
import { type Header } from 'utils/graphql/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

export interface HttpHeaderSectionProps {
  httpHeaders: Header[];
  maxHttpHeaderCount: number;
  defaultGroupId: string;
}

export const HttpHeaderSection: FunctionComponent<HttpHeaderSectionProps> = ({
  httpHeaders,
  maxHttpHeaderCount,
  defaultGroupId,
}) => {
  const { t } = useTranslation();
  const { readonlyTitleProp, readonlyDisabledProp } = useAuthUtils();

  const {
    isOpen: isAddHeaderModalOpen,
    onOpen: onOpenAddHeaderModal,
    onClose: onCloseAddHeaderModal,
  } = useDisclosure();

  const canAddHttpHeader = httpHeaders.length < maxHttpHeaderCount;

  return (
    <>
      <AddHeaderModal
        isOpen={isAddHeaderModalOpen}
        onClose={onCloseAddHeaderModal}
        groupId={defaultGroupId}
        httpHeaders={httpHeaders}
      />

      <Box>
        <Flex
          flexDirection={['column', 'row']}
          alignItems={['stretch', 'center']}
          justifyContent="space-between"
          mb="3"
        >
          <Heading as="h3" fontSize="lg" mb={['2', '0']}>
            {t('config.iotConnectorProxy.headers')}
          </Heading>

          <Tooltip
            label={t('config.iotConnectorProxy.headerMaximum')}
            isDisabled={canAddHttpHeader}
          >
            <Box>
              <Button
                onClick={onOpenAddHeaderModal}
                isDisabled={readonlyDisabledProp || !canAddHttpHeader}
                title={readonlyTitleProp}
              >
                {t('config.iotConnectorProxy.addNewHeader')}
              </Button>
            </Box>
          </Tooltip>
        </Flex>

        <Text>{t('config.iotConnectorProxy.httpHeaderFieldsDescription')}</Text>

        <Box width="full" overflowX="auto">
          <HttpHeadersTable httpHeaders={httpHeaders} groupId={defaultGroupId} />
        </Box>
      </Box>
    </>
  );
};
