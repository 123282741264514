import { IconButton, type IconButtonProps } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronDownIcon } from 'assets/icons/ChevronDown.svg';
import { ReactComponent as ChevronUpIcon } from 'assets/icons/ChevronUp.svg';
import { SortDirection } from 'utils/graphql/hooks';

interface SortingButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  sort?: SortDirection;
}

const SortingButton: FunctionComponent<SortingButtonProps> = ({ sort, ...rest }) => {
  const { t } = useTranslation();

  const iconButtonProps = sort
    ? { bgColor: 'black', borderColor: 'black', _hover: { bgColor: 'black', borderColor: 'black' } }
    : { bgColor: 'white' };
  const svgProps = sort ? { color: 'white' } : { color: 'black' };

  return (
    <IconButton
      variant="sort"
      size="xxs"
      aria-label={t('a11y.sortingButtonLabel')}
      icon={
        sort === SortDirection.Asc ? (
          <ChevronDownIcon {...svgProps} />
        ) : (
          <ChevronUpIcon {...svgProps} />
        )
      }
      {...iconButtonProps}
      {...rest}
    />
  );
};

interface SortButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  isSorted: boolean;
  isSortedDesc: boolean;
}

export const SortButton: FunctionComponent<SortButtonProps> = ({
  isSorted,
  isSortedDesc,
  ...rest
}) => {
  if (!isSorted) {
    return <SortingButton {...rest} />;
  }

  return <SortingButton sort={isSortedDesc ? SortDirection.Asc : SortDirection.Desc} {...rest} />;
};
