import { Button, Heading, Stack, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useCallback, useId, useState, type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate } from 'react-router-dom';
import {
  InvoiceAddress,
  OrderConfirmationModal,
  OrderOverview,
  PaymentMethod,
  MissingCompanyAttributes,
} from './components';
import { ignoreInvalidVatIdCountryCodes, shopSectionStyles } from './utils';
import { SectionLoading, SectionStack } from 'components';
import type {
  Company,
  CreateOrderMutationVariables,
  InvoiceDetails,
  OrderItem,
  ShopProduct,
} from 'utils/graphql/hooks';
import { useShopProductQuery, useCompanyQuery, useCreateOrderMutation } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useShopCheckoutContext } from 'utils/provider/ShopCheckoutProvider';
import { routes } from 'utils/routes';

type EditableInvoiceDetails = Omit<InvoiceDetails, 'vatId' | 'countryOrRegion'>;

export interface EditableCreateOrderMutationVariables
  extends Omit<CreateOrderMutationVariables, 'invoiceDetails'> {
  invoiceDetails: EditableInvoiceDetails;
}

interface ShopCheckoutContentProps {
  company: Company;
  shopProduct: ShopProduct;
  orderItems: OrderItem[];
}

export const ShopCheckoutContent: FunctionComponent<ShopCheckoutContentProps> = ({
  company,
  shopProduct,
  orderItems,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const errorToast = useIotSimToast({ status: 'error' });
  const { setOrderItems } = useShopCheckoutContext();

  const [paymentUrl, setPaymentUrl] = useState<string | undefined>();

  const {
    isOpen: isOrderConfirmationOpen,
    onOpen: onOrderConfirmationOpen,
    onClose,
  } = useDisclosure();

  const onOrderConfirmationClose = useCallback(() => {
    onClose();

    // only on payment option 'Bank transfer', in case the origin was a sim detail modal it will be opened too
    if (!paymentUrl) {
      setOrderItems([]);
      navigate(-1);
    }
  }, [navigate, onClose, paymentUrl, setOrderItems]);

  const { mutateAsync, isPending: isSubmitting } = useCreateOrderMutation();

  const formReturn = useForm<EditableCreateOrderMutationVariables>({
    defaultValues: {
      overwriteCompanyAttributes: false,
      items: orderItems,
      termsAccepted: false,
      invoiceDetails: company,
    },
  });

  const numberOfFormErrors = Object.keys(formReturn.formState.errors).length;

  const onSubmit = formReturn.handleSubmit(async (values) => {
    try {
      const { additionalAddressInfo, city, internalReference, postalCode, streetAddress } =
        values.invoiceDetails;

      const apiResponse = await mutateAsync({
        ...values,
        invoiceDetails: {
          additionalAddressInfo: additionalAddressInfo?.trim(),
          city: city.trim(),
          internalReference: internalReference?.trim(),
          postalCode: postalCode.trim(),
          streetAddress: streetAddress.trim(),
          // not editable values:
          countryOrRegion: company.country,
          vatId: company.vatId,
        },
      });

      setPaymentUrl(apiResponse.createOrder || undefined);
      onOrderConfirmationOpen();
    } catch (error) {
      if (
        error instanceof Error &&
        error.message.startsWith('Maximum number of unpaid suborders reached,')
      ) {
        errorToast({
          title: t('subscription.unpaidSubordersError'),
          description: t('subscription.unpaidSubordersErrorDescription'),
        });
      } else {
        errorToast({
          title:
            (error instanceof Error && t('shopCheckout.error.orderSubmit')) || t('error.generic'),
          ...(error instanceof Error && { description: error.message }),
        });
      }
    }
  });

  return (
    <>
      <OrderConfirmationModal
        isOpen={isOrderConfirmationOpen}
        onClose={onOrderConfirmationClose}
        paymentUrl={paymentUrl}
      />

      <Stack as="form" onSubmit={onSubmit} spacing="12" pb="32">
        <SectionStack type="outer" {...shopSectionStyles}>
          <Heading mb="8" alignSelf="center">
            {t('shopCheckout.invoiceDetails')}
          </Heading>
          <InvoiceAddress
            company={company}
            formReturn={formReturn}
            isFormSubmitting={isSubmitting}
          />
        </SectionStack>

        <SectionStack type="outer" {...shopSectionStyles}>
          <OrderOverview
            company={company}
            items={orderItems}
            productPrice={Number(shopProduct.price)}
          />
        </SectionStack>

        <SectionStack type="outer" {...shopSectionStyles}>
          <PaymentMethod formReturn={formReturn} isFormSubmitting={isSubmitting} pb="8" />
          <Tooltip
            isDisabled={numberOfFormErrors === 0}
            label={t('shopCheckout.error.formErrors')}
            shouldWrapChildren
            tabIndex={-1}
          >
            <Button
              type="submit"
              borderRadius="Q"
              h="9"
              w="full"
              isDisabled={numberOfFormErrors > 0}
              isLoading={isSubmitting}
              loadingText={t('common.submitting')}
            >
              {t('common.submit')}
            </Button>
          </Tooltip>
        </SectionStack>
      </Stack>
    </>
  );
};

// wrapper to ensure there are orderItems selected before rendering the page content
export const ShopCheckoutPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { orderItems } = useShopCheckoutContext();
  const errorToast = useIotSimToast({ status: 'error' });
  const navigate = useNavigate();
  const toastId = useId();

  const hasOrderItems = !!orderItems.length;
  const skuToFetch = orderItems[0]?.sku;

  const {
    data: companyData,
    isLoading: isCompanyDataInitialLoading,
    isSuccess: isCompanyQuerySuccess,
  } = useCompanyQuery(undefined, {
    enabled: hasOrderItems,
    staleTime: 30000,
  });
  const {
    data: productData,
    isLoading: isProductDataInitialLoading,
    isSuccess: isProductQuerySuccess,
  } = useShopProductQuery(
    // currently only one WooCommerce product is supported, could be extended on backend
    // to accept multiple SKUs
    { sku: skuToFetch },
    { enabled: hasOrderItems && !!skuToFetch },
  );

  if (!hasOrderItems) {
    // make sure orderItems are selected
    return <Navigate to={routes.simCards} />;
  }

  if (isCompanyDataInitialLoading || isProductDataInitialLoading) {
    const { alignSelf, ...rest } = shopSectionStyles;

    return (
      <SectionStack type="outer" {...rest} mx="auto">
        <SectionLoading loadingResourceName={t('shopCheckout.orderData')} />
      </SectionStack>
    );
  }

  if (
    !isCompanyQuerySuccess ||
    !isProductQuerySuccess ||
    !orderItems ||
    Number(productData.shopProduct.price) <= 0
  ) {
    if (!errorToast.isActive(toastId)) {
      errorToast({
        id: toastId,
        title: t('shopCheckout.error.loadingOrderDataTitle'),
        description: t('shopCheckout.error.loadingOrderDataDescription'),
      });
    }

    navigate(-1);

    // make sure page content will not be rendered on navigate back
    return null;
  }

  if (
    !companyData.company.country ||
    (!companyData.company.vatId && !ignoreInvalidVatIdCountryCodes.has(companyData.company.country))
  ) {
    return <MissingCompanyAttributes company={companyData.company} />;
  }

  return (
    <ShopCheckoutContent
      company={companyData.company}
      shopProduct={productData.shopProduct}
      orderItems={orderItems}
    />
  );
};
