import { Button, Heading, Spacer, useDisclosure } from '@chakra-ui/react';
import { debounce } from 'lodash';
import { useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AddUserModal, UsersTable } from './components';
import { Searchbar, SectionStack } from 'components';
import { UserGroup } from 'utils/graphql/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

export const UsersPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { hasRequiredRole } = useAuthUserContext();
  const [userFilterValue, setUserFilterValue] = useState<string>('');

  return (
    <>
      <AddUserModal isOpen={isOpen} onClose={onClose} />

      <SectionStack type="outer" alignItems="center" direction={['column', null, 'row']} px="10">
        <Heading as="h2" size="lg" py={[4, null, 7]}>
          {t('user.allUsers')}
        </Heading>
        <Spacer />
        <Searchbar
          placeholder={t('user.search')}
          onChange={debounce((filterValue) => setUserFilterValue(filterValue), 500)}
          w={['full', '60%', 80]}
          pb={[4, null, 0]}
        />
      </SectionStack>

      <SectionStack mt="2" type="outer" flex="1" p="10" overflowX="auto">
        <Button
          alignSelf={['center', null, 'flex-end']}
          mb="10"
          onClick={onOpen}
          isDisabled={!hasRequiredRole(UserGroup.CompanyAdmin)}
        >
          {t('user.addNew')}
        </Button>

        <UsersTable mt="4" filterValue={userFilterValue} />
      </SectionStack>
    </>
  );
};
