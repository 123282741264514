import { Flex, Text, type UseDisclosureProps } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoutIcon } from 'assets/icons/Logout.svg';
import { hoverSideMenuItemStyle } from 'components';

const LOGOUT_TEXT_ID = 'signOutButtonText';

const focusSideMenuActionStyle = {
  boxShadow: 'sideMenuInsetRed',
};

export const LogoutAction: FunctionComponent<UseDisclosureProps> = ({ onOpen }) => {
  const { t } = useTranslation();

  return (
    <Flex
      as="button"
      px="5"
      py="4"
      justify="space-between"
      alignItems="center"
      color="red.600"
      onClick={onOpen}
      _focus={focusSideMenuActionStyle}
      _hover={hoverSideMenuItemStyle}
    >
      <Text id={LOGOUT_TEXT_ID} size="sm" fontWeight="bold">
        {t('account.logout')}
      </Text>
      <LogoutIcon aria-labelledby={LOGOUT_TEXT_ID} />
    </Flex>
  );
};
