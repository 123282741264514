import { Radio, RadioGroup, Stack, type RadioGroupProps } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { type UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { UserGroup } from 'utils/graphql/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

interface UserRoleRadioGroupProps extends Omit<RadioGroupProps, 'children'> {
  register: UseFormRegisterReturn<string>;
  isDisabled?: boolean;
  defaultValue?: UserGroup;
}

export const UserRoleRadioGroup: FunctionComponent<UserRoleRadioGroupProps> = ({
  register,
  isDisabled = false,
  defaultValue = UserGroup.CompanyUser,
  ...rest
}) => {
  const { t } = useTranslation();
  const { hasRequiredRole } = useAuthUserContext();

  return (
    <RadioGroup defaultValue={defaultValue} {...rest}>
      <Stack>
        <Radio
          value={UserGroup.CompanyOwner}
          size="sm"
          isDisabled={isDisabled || !hasRequiredRole(UserGroup.CompanyOwner)}
          {...register}
        >
          {t(`common.roles.${UserGroup.CompanyOwner}`)}
        </Radio>
        <Radio
          value={UserGroup.CompanyAdmin}
          size="sm"
          isDisabled={isDisabled || !hasRequiredRole(UserGroup.CompanyAdmin)}
          {...register}
        >
          {t(`common.roles.${UserGroup.CompanyAdmin}`)}
        </Radio>
        <Radio
          value={UserGroup.CompanyUser}
          size="sm"
          isDisabled={isDisabled || !hasRequiredRole(UserGroup.CompanyAdmin)}
          {...register}
        >
          {t(`common.roles.${UserGroup.CompanyUser}`)}
        </Radio>
      </Stack>
    </RadioGroup>
  );
};
