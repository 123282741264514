import { ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import type { GridProps } from '@chakra-ui/react';
import {
  Button,
  Stack,
  Popover,
  PopoverContent,
  PopoverBody,
  PopoverTrigger,
  RadioGroup,
  Radio,
  Grid,
  chakra,
} from '@chakra-ui/react';
import i18next from 'i18next';
import { useRef } from 'react';
import type { SetStateAction, Dispatch, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { WireGuardFormGridItem } from './WireGuardFormGridItem';
import { WireGuardInstanceType } from 'utils/graphql/hooks';

const chakraUiPopoverTriggerIdPrefix = 'popover-trigger-';
const instanceTypeId = 'instanceType';

const wireGuardInstanceTypeSet = new Set(Object.values<string>(WireGuardInstanceType));

interface ColumnOption {
  label: string;
  value: WireGuardInstanceType;
}

const choosableInstanceTypes: ColumnOption[] = [
  {
    label: i18next.t('config.wireGuard.instanceTypes.small'),
    value: WireGuardInstanceType.Small,
  },
  {
    label: i18next.t('config.wireGuard.instanceTypes.xlarge'),
    value: WireGuardInstanceType.Xlarge,
  },
];

interface WireGuardInstanceTypeSelectionProps extends GridProps {
  wireGuardInstance: WireGuardInstanceType;
  setWireGuardInstance: Dispatch<SetStateAction<WireGuardInstanceType>>;
}

export const WireGuardInstanceTypeSelection: FunctionComponent<
  WireGuardInstanceTypeSelectionProps
> = ({ wireGuardInstance, setWireGuardInstance, ...rest }) => {
  const { t } = useTranslation();
  const initialFocusRef = useRef<any>(null); // correct typing is too complicated because of initialFocusRef typing

  return (
    <Grid templateColumns="repeat(6, 1fr)" alignItems="center" {...rest}>
      <WireGuardFormGridItem type="label">
        <chakra.label
          htmlFor={chakraUiPopoverTriggerIdPrefix + instanceTypeId}
          fontWeight="bold"
          fontSize="lg"
        >
          {t('config.wireGuard.instanceType')}
        </chakra.label>
      </WireGuardFormGridItem>

      <WireGuardFormGridItem type="input">
        <Popover initialFocusRef={initialFocusRef} id={instanceTypeId}>
          {({ isOpen }) => (
            <>
              <PopoverTrigger>
                <Button
                  variant="ghost"
                  borderColor="qGray.200"
                  h="10"
                  w={['full', '60%', 40, 60]}
                  display="flex"
                  justifyContent="space-between"
                  aria-label={t('config.wireGuard.instanceTypeAriaLabel')}
                  _hover={{ color: 'inherit', borderColor: 'qGray.200' }}
                >
                  {t(`config.wireGuard.instanceTypes.${wireGuardInstance}`)}
                  {isOpen ? (
                    <ChevronUpIcon width="0.875rem" height="0.875rem" />
                  ) : (
                    <ChevronDownIcon width="0.875rem" height="0.875rem" />
                  )}
                </Button>
              </PopoverTrigger>

              <PopoverContent w={['full', '60%', 40, 60]} m="0">
                <PopoverBody p="5">
                  <RadioGroup
                    value={wireGuardInstance}
                    onChange={(newInstance) => {
                      if (wireGuardInstanceTypeSet.has(newInstance)) {
                        setWireGuardInstance(newInstance as WireGuardInstanceType);
                      }
                    }}
                  >
                    <Stack spacing="3">
                      {choosableInstanceTypes.map((column) => (
                        <Radio
                          key={column.value}
                          value={column.value}
                          ref={wireGuardInstance === column.value ? initialFocusRef : undefined}
                        >
                          {column.label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                </PopoverBody>
              </PopoverContent>
            </>
          )}
        </Popover>
      </WireGuardFormGridItem>
    </Grid>
  );
};
