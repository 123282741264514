import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Heading,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useUpdateIotConnectorGroupMutation } from '../../../../../utils/graphql/hooks';
import { useIotSimToast } from '../../../../../utils/hooks';
import { OauthField } from './OauthField';
import { isValidHttpUrl } from 'utils';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

interface OauthSectionProps {
  groupId: string;
  tokenUrl: string;
}

interface Oauth {
  clientId: string;
  clientSecret: string;
  scopes: string;
  tokenUrl: string;
}

export const OauthSection: FunctionComponent<OauthSectionProps> = ({ groupId, tokenUrl }) => {
  const { t } = useTranslation();
  const { readonlyDisabledProp, readonlyTitleProp } = useAuthUtils();
  const { mutateAsync } = useUpdateIotConnectorGroupMutation();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });
  const [oauth, setOauth] = useState<Oauth>({
    tokenUrl,
    clientId: '',
    clientSecret: '',
    scopes: '',
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleTestOauthConfig = useCallback(async () => {
    setIsLoading(true);
    try {
      await mutateAsync({
        groupId,
        oauth2Config: oauth,
      });
      successToast({ title: t('common.updateAttributesSuccess') });
    } catch (e) {
      errorToast(
        e instanceof Error
          ? { title: t('common.updateAttributesError'), description: e.message }
          : { title: t('error.generic') },
      );
    }
    setIsLoading(false);
  }, [errorToast, groupId, mutateAsync, oauth, successToast, t]);

  const oauthConfiguration = useMemo(
    () => [
      {
        key: 'tokenUrl',
        value: oauth.tokenUrl,
        setFieldValue: (newValue: string) => {
          setOauth({
            ...oauth,
            tokenUrl: newValue,
          });
        },
      },
      {
        key: 'clientId',
        value: oauth.clientId,
        setFieldValue: (newValue: string) => {
          setOauth({
            ...oauth,
            clientId: newValue,
          });
        },
      },
      {
        key: 'clientSecret',
        value: oauth.clientSecret,
        setFieldValue: (newValue: string) => {
          setOauth({
            ...oauth,
            clientSecret: newValue,
          });
        },
      },
      {
        key: 'scopes',
        value: oauth.scopes,
        setFieldValue: (newValue: string) => {
          setOauth({
            ...oauth,
            scopes: newValue,
          });
        },
      },
    ],
    [oauth],
  );

  return (
    <Box>
      <Heading as="h3" fontSize="lg" mb={['2', '0']}>
        {t('config.iotConnectorProxy.oauth')}
      </Heading>
      <Accordion allowToggle defaultIndex={[0]}>
        <AccordionItem borderColor="white">
          <AccordionButton>
            <Box flex="1" textAlign="left">
              {t('config.iotConnectorProxy.oauthFieldsDescription')}
            </Box>
            <AccordionIcon />
          </AccordionButton>

          <AccordionPanel pb={4}>
            {oauthConfiguration.map((item) => (
              <Box pt={3} pb={3} key={item.key}>
                <OauthField
                  oauthKey={item.key}
                  fieldValue={item.value}
                  setFieldValue={item.setFieldValue}
                />
              </Box>
            ))}

            <Button
              onClick={handleTestOauthConfig}
              isLoading={isLoading}
              isDisabled={readonlyDisabledProp || !isValidHttpUrl(oauth.tokenUrl)}
              title={readonlyTitleProp}
              loadingText={t('config.iotConnectorProxy.testingOauthConfig')}
              spinnerPlacement="end"
            >
              {t('config.iotConnectorProxy.testOauthConfig')}
            </Button>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
