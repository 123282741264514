import { Checkbox } from '@chakra-ui/react';
import type { ChangeEvent, FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockClickEventPropagation } from 'components/BlockClickEventPropagation';

interface SimSelectAllRowsCellProps {
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
}

export const SimSelectAllRowsCell: FunctionComponent<SimSelectAllRowsCellProps> = ({
  onChange,
  checked,
}) => {
  const { t } = useTranslation();

  return (
    <BlockClickEventPropagation>
      <Checkbox
        w="full"
        title={t('common.toggleAllRowsSelected')}
        isChecked={checked}
        onChange={onChange}
      />
    </BlockClickEventPropagation>
  );
};
