import { useAuthenticator } from '@aws-amplify/ui-react';
import { type UseDisclosureProps } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from 'components';

export const LogoutModal: FunctionComponent<UseDisclosureProps> = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const { signOut } = useAuthenticator((context) => [context.user]);

  return (
    <ConfirmModal
      translations={{
        header: t('account.logout'),
        content: t('common.areYouSure'),
        cancel: t('common.no'),
        apply: `${t('common.yes')}!`,
      }}
      isOpen={isOpen}
      onCancel={onClose}
      onConfirm={signOut}
    />
  );
};
