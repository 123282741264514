import { Flex, type FlexProps } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';

export type InputContainerProps = FlexProps;

export const InputContainer: FunctionComponent<InputContainerProps> = ({ children, ...rest }) => (
  <Flex mx="6" py="1" direction="column" alignItems="flex-start" {...rest}>
    {children}
  </Flex>
);
