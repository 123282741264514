import {
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
  format,
  getDaysInMonth,
} from 'date-fns';
import i18next, { t } from 'i18next';
import type { Maybe } from './graphql/hooks';
import { dateFnsLocale } from 'utils/i18n';

/* 
  I implemented this function, because "new Date("<someDateTimeString>")", which we used previously returns an invalid date on Safari.
  Also, it confused us between local time and UTC. This function should work in all browsers and return the local time, instead of UTC.
*/
const parseDateTimeString = (dateTimeString: string) => {
  const [dateString, timeString] = dateTimeString.split(/[TZ ]/);

  const [yearAsString, monthAsString, dayAsString] = dateString.split(/[-/.]/); // Allow "-" or "/" or "."

  const year = Number(yearAsString);
  const month = Number(monthAsString) - 1; // The month has to be zero-indexed
  const day = Number(dayAsString);

  if (!timeString) {
    return new Date(Date.UTC(year, month, day));
  }

  const [hoursAsString, minutesAsString, secondsAndMillisecondsString] = timeString.split(':');

  const hours = Number(hoursAsString);
  const minutes = Number(minutesAsString);

  if (!secondsAndMillisecondsString) {
    return new Date(Date.UTC(year, month, day, hours, minutes));
  }

  const [secondsAsString, millisecondsAsString] = secondsAndMillisecondsString.split('.');

  return new Date(
    Date.UTC(
      year,
      month,
      day,
      hours,
      minutes,
      Number(secondsAsString),
      millisecondsAsString ? Number(millisecondsAsString) : 0,
    ),
  );
};

export const formatToDateTimeString = (
  dateTimeString: Maybe<string> | undefined,
  dateOnly = false,
) => {
  if (!dateTimeString) {
    return '';
  }

  // ignore time on 'dateOnly' to prevent wrong date because of time zones
  const date = parseDateTimeString(dateOnly ? dateTimeString.split(/[T\s]/)[0] : dateTimeString);

  return format(date, `MMMM d, yyyy${dateOnly ? '' : ' - HH:mm'}`, { locale: dateFnsLocale });
};

export const formatToDateString = (dateTimeString: string) => {
  const date = parseDateTimeString(dateTimeString);

  return format(date, 'MMMM d, yyyy', { locale: dateFnsLocale });
};

export const getDayCountUntilDate = (dateTimeString: string) => {
  const date = parseDateTimeString(dateTimeString);
  const today = new Date();

  return differenceInCalendarDays(date, today);
};

export const formatSimCardEndDate = (dateTimeString?: Maybe<string>) => {
  if (!dateTimeString) {
    return '';
  }

  const dayCount = getDayCountUntilDate(dateTimeString);
  const formattedDate = formatToDateString(dateTimeString);

  if (dayCount < 0) {
    return `${formattedDate} (${t('dateTime.expired')})`;
  }

  return `${formattedDate} (${dayCount} ${t('dateTime.days', {
    count: dayCount,
  })} ${t('dateTime.left')})`;
};

export const minifiedDateTimeDifference = (dateTimeString: Maybe<string> | undefined) => {
  if (!dateTimeString) {
    return '';
  }

  const date = parseDateTimeString(dateTimeString);
  const now = new Date();

  const min = differenceInMinutes(now, date);

  if (min < 60) {
    return `${min}m`;
  }

  const hours = differenceInHours(now, date);

  if (hours < 24) {
    return `${hours}h`;
  }

  const days = differenceInDays(now, date);

  if (days <= 30) {
    return `${days}d`;
  }

  const months = differenceInMonths(now, date);

  if (months < 12) {
    return `${months} ${i18next.t('dateTime.months', { count: months })}`;
  }

  return `${differenceInYears(now, date)}y`;
};

/**
 * uses todays date if not defined
 */
export const progressOfMonth = (date?: Date, includingToday = false) => {
  const now = date ?? new Date();

  return (includingToday ? now.getDate() - 1 : now.getDate()) / getDaysInMonth(now);
};

/**
 * uses todays date if not defined
 */
export const remainingDaysOfMonth = (date?: Date, includingToday = false) => {
  const now = date ?? new Date();

  return getDaysInMonth(now) - (includingToday ? now.getDate() - 1 : now.getDate());
};
