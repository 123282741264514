import { Box } from '@chakra-ui/react';
import type { FunctionComponent, PropsWithChildren, MouseEvent as ReactMouseEvent } from 'react';

export const BlockClickEventPropagation: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const handleClick = (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target !== event.currentTarget) {
      event.stopPropagation();
    }
  };

  return <Box onClick={(event) => handleClick(event)}>{children}</Box>;
};
