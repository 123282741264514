/* eslint-disable */
// this is an auto generated file. This will be overwritten
import { endpointUrl, fetchParams } from './fetchConfig';
import { useMutation, useQuery, useInfiniteQuery, UseMutationOptions, UseQueryOptions, UseInfiniteQueryOptions, InfiniteData } from '@tanstack/react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch(await endpointUrl() as string, {
    method: "POST",
    ...(await fetchParams()),
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  }
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Long: { input: number; output: number; }
};

export enum ChangeEventNotificationConfigAction {
  Add = 'add',
  Remove = 'remove'
}

export enum ChangeSimActivationStateAction {
  Activate = 'activate',
  Deactivate = 'deactivate'
}

export type ChosenTechnologyAvailability = {
  __typename?: 'ChosenTechnologyAvailability';
  countryName?: Maybe<Scalars['String']['output']>;
  technology?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['String']['output']>;
};

export type CoapRequest = {
  body?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['Int']['input'];
  media_type: Scalars['Int']['input'];
  path: Scalars['String']['input'];
};

export type Company = Node & {
  __typename?: 'Company';
  additionalAddressInfo?: Maybe<Scalars['String']['output']>;
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  internalReference?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  poolingUsage?: Maybe<PoolingUsage>;
  postalCode: Scalars['String']['output'];
  streetAddress: Scalars['String']['output'];
  vatId: Scalars['String']['output'];
};

export type CreatedCompanyPoolingOutput = {
  __typename?: 'CreatedCompanyPoolingOutput';
  dataVolume?: Maybe<Quota>;
  smsVolume?: Maybe<Quota>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export enum EventTrigger {
  Email = 'email'
}

export enum EventType {
  DatavolumeByteBelow = 'datavolume_byte_below'
}

export type Header = {
  __typename?: 'Header';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type HttpHeaderInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type IccidVolumePair = {
  amountInGb?: InputMaybe<Scalars['Int']['input']>;
  iccid: Scalars['String']['input'];
};

export type Interval = {
  endTime?: InputMaybe<Scalars['String']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type InvoiceDetails = {
  additionalAddressInfo?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  countryOrRegion: Scalars['String']['input'];
  internalReference?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  vatId: Scalars['String']['input'];
};

export type IotConnectorGroup = {
  __typename?: 'IotConnectorGroup';
  id: Scalars['String']['output'];
  proxy: IotConnectorProxyConfiguration;
  sims: Array<Sim>;
  ssh?: Maybe<Ssh>;
};

export type IotConnectorGroupGeneralInput = {
  proxyConfig?: InputMaybe<ProxyConfig>;
};

export type IotConnectorGroupOauth2Input = {
  clientId?: InputMaybe<Scalars['String']['input']>;
  clientSecret?: InputMaybe<Scalars['String']['input']>;
  scopes?: InputMaybe<Scalars['String']['input']>;
  tokenUrl?: InputMaybe<Scalars['String']['input']>;
};

export type IotConnectorGroupSshInput = {
  privateKey?: InputMaybe<Scalars['String']['input']>;
  user?: InputMaybe<Scalars['String']['input']>;
};

export type IotConnectorGroups = {
  __typename?: 'IotConnectorGroups';
  defaultGroup: IotConnectorGroup;
  maxHttpHeaderCount: Scalars['Int']['output'];
};

export type IotConnectorProxyConfiguration = {
  __typename?: 'IotConnectorProxyConfiguration';
  headers: Array<Header>;
  oauth2?: Maybe<Oauth2>;
  targetUrl?: Maybe<Scalars['String']['output']>;
};

export type KeyValue = {
  __typename?: 'KeyValue';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type KeyValueHeader = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  changeDataVolumePredicate: PredicateResult;
  changeEventNotificationConfig: Array<Scalars['String']['output']>;
  changeSimActivationState: Array<Scalars['String']['output']>;
  createOrder?: Maybe<Scalars['String']['output']>;
  createPooling?: Maybe<CreatedCompanyPoolingOutput>;
  createSupportTicket: Scalars['Boolean']['output'];
  createWireGuardConfiguration: WireGuardConfig;
  deletePooling?: Maybe<Scalars['String']['output']>;
  inviteUser: User;
  registerSim: Array<Scalars['String']['output']>;
  reloadDataVolume?: Maybe<Scalars['String']['output']>;
  resendUserInvitation: User;
  saveTechnologyAvailabilityChoices: ChosenTechnologyAvailability;
  softDeleteUser: User;
  sshTicketGeneration: TicketOutput;
  updateCompanyAttributes: Company;
  updateIotConnectorGroup: IotConnectorGroup;
  updateSimAttributes: Array<Sim>;
  updateSubscription: UpdateSubscriptionOutput;
  updateSystemAdminCompanyId?: Maybe<Scalars['String']['output']>;
  updateUserAttributes: User;
  updateUserGroup: User;
  verifyUserAttribute: User;
};


export type MutationChangeDataVolumePredicateArgs = {
  iccid: Scalars['String']['input'];
};


export type MutationChangeEventNotificationConfigArgs = {
  action: ChangeEventNotificationConfigAction;
  eventParamValue: Scalars['Long']['input'];
  eventType?: InputMaybe<EventType>;
  trigger?: InputMaybe<EventTrigger>;
  userIds: Array<Scalars['String']['input']>;
};


export type MutationChangeSimActivationStateArgs = {
  action: ChangeSimActivationStateAction;
  iccids: Array<Scalars['String']['input']>;
};


export type MutationCreateOrderArgs = {
  invoiceDetails: InvoiceDetails;
  items: Array<OrderItem>;
  overwriteCompanyAttributes: Scalars['Boolean']['input'];
  paymentType?: InputMaybe<PaymentType>;
  termsAccepted: Scalars['Boolean']['input'];
};


export type MutationCreatePoolingArgs = {
  withDataVolumeLimit: Scalars['Long']['input'];
  withSMSVolumeLimit: Scalars['Long']['input'];
};


export type MutationCreateSupportTicketArgs = {
  description: Scalars['String']['input'];
  email: Scalars['String']['input'];
  firstOccurrenceTimestamp: Scalars['String']['input'];
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  simCards?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  technicalInfo?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateWireGuardConfigurationArgs = {
  instanceType: WireGuardInstanceType;
  publicKey: Scalars['String']['input'];
};


export type MutationInviteUserArgs = {
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  group: UserGroup;
};


export type MutationRegisterSimArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
  iccid: Scalars['String']['input'];
  mac?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReloadDataVolumeArgs = {
  invoiceDetails: InvoiceDetails;
  overwriteCompanyAttributes: Scalars['Boolean']['input'];
  paymentType?: InputMaybe<PaymentType>;
  termsAccepted: Scalars['Boolean']['input'];
  volumes: Array<IccidVolumePair>;
};


export type MutationResendUserInvitationArgs = {
  email: Scalars['String']['input'];
};


export type MutationSaveTechnologyAvailabilityChoicesArgs = {
  countryName: Scalars['String']['input'];
  technology?: InputMaybe<Scalars['String']['input']>;
  timestamp: Scalars['String']['input'];
};


export type MutationSoftDeleteUserArgs = {
  username: Scalars['String']['input'];
};


export type MutationSshTicketGenerationArgs = {
  iccid: Scalars['String']['input'];
};


export type MutationUpdateCompanyAttributesArgs = {
  additionalAddressInfo?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  internalReference?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  streetAddress: Scalars['String']['input'];
  vatId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateIotConnectorGroupArgs = {
  general?: InputMaybe<IotConnectorGroupGeneralInput>;
  groupId: Scalars['String']['input'];
  headers?: InputMaybe<Array<HttpHeaderInput>>;
  oauth2Config?: InputMaybe<IotConnectorGroupOauth2Input>;
  sshConfig?: InputMaybe<IotConnectorGroupSshInput>;
};


export type MutationUpdateSimAttributesArgs = {
  costCenterId?: InputMaybe<Scalars['String']['input']>;
  iccids: Array<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSubscriptionArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  subscriptionId: Scalars['String']['input'];
  typeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateSystemAdminCompanyIdArgs = {
  companyId: Scalars['String']['input'];
};


export type MutationUpdateUserAttributesArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserGroupArgs = {
  newGroup: Scalars['String']['input'];
  oldGroup: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationVerifyUserAttributeArgs = {
  attributeName: Scalars['String']['input'];
  code: Scalars['String']['input'];
};

export type Node = {
  id: Scalars['ID']['output'];
};

export type Oauth = {
  client_id: Scalars['String']['input'];
  client_secret: Scalars['String']['input'];
  scopes: Scalars['String']['input'];
  token_url: Scalars['String']['input'];
};

export type Oauth2 = {
  __typename?: 'Oauth2';
  clientId: Scalars['String']['output'];
  clientSecret: Scalars['String']['output'];
  scopes: Scalars['String']['output'];
  tokenUrl: Scalars['String']['output'];
};

export type OrderItem = {
  iccid?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Int']['input'];
  sku: ShopProductSku;
};

export type Page = {
  pageInfo: PageInfo;
  sort: Sort;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean']['output'];
  nextPage?: Maybe<Scalars['Int']['output']>;
};

export enum PaymentType {
  Bill = 'bill',
  CreditCard = 'credit_card',
  SepaDirectDebits = 'sepa_direct_debits'
}

export type PingResult = {
  __typename?: 'PingResult';
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type PoolingUsage = {
  __typename?: 'PoolingUsage';
  dataVolume?: Maybe<Quota>;
  exceeded?: Maybe<Scalars['Boolean']['output']>;
  smsVolume?: Maybe<Quota>;
  startDate?: Maybe<Scalars['String']['output']>;
};

export type PredicateResult = {
  __typename?: 'PredicateResult';
  canExceedDataVolume?: Maybe<Scalars['Boolean']['output']>;
};

export type ProxyConfig = {
  targetUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ProxyConfigInput = {
  headers?: InputMaybe<Array<InputMaybe<KeyValueHeader>>>;
  oauth?: InputMaybe<Oauth>;
  target_url: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  company: Company;
  companySelectUserList: Array<User>;
  filterCompanyBySim: Scalars['ID']['output'];
  getIotConnectorGroups: IotConnectorGroups;
  getSimsTraces: TracesOutputs;
  ping: PingResult;
  shopProduct: ShopProduct;
  sim?: Maybe<Sim>;
  simPage: SimPage;
  simState?: Maybe<SimState>;
  simulateProxy: SimulateOutput;
  technologyAvailability: Array<TechnologyAvailability>;
  technologyAvailabilityInCountry: TechnologyAvailability;
  userList: Array<User>;
  wireGuard: WireGuard;
};


export type QueryFilterCompanyBySimArgs = {
  filterKey?: InputMaybe<Scalars['String']['input']>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetSimsTracesArgs = {
  iccids: Array<Scalars['String']['input']>;
  interval?: InputMaybe<Interval>;
  limit?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryPingArgs = {
  iccid: Scalars['String']['input'];
};


export type QueryShopProductArgs = {
  sku: ShopProductSku;
};


export type QuerySimArgs = {
  iccid: Scalars['ID']['input'];
};


export type QuerySimPageArgs = {
  filterKey?: InputMaybe<SimFilterKey>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
  first: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  searchKey?: InputMaybe<SearchableColumn>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
  sortBy: SimSortBy;
  sortDirection: SortDirection;
};


export type QuerySimStateArgs = {
  iccid: Scalars['ID']['input'];
};


export type QuerySimulateProxyArgs = {
  coapRequest: CoapRequest;
  proxyConfigInput: ProxyConfigInput;
};


export type QueryTechnologyAvailabilityInCountryArgs = {
  countryName: Scalars['String']['input'];
};

export type Quota = {
  __typename?: 'Quota';
  count: Scalars['Long']['output'];
  limit?: Maybe<Scalars['Long']['output']>;
};

export type Ssh = {
  __typename?: 'SSH';
  fingerprint: Scalars['String']['output'];
  publicKey: Scalars['String']['output'];
  user: Scalars['String']['output'];
};

export enum SearchableColumn {
  CostCenterId = 'costCenterId',
  Iccid = 'iccid',
  Imei = 'imei',
  Imsi = 'imsi',
  Ip = 'ip',
  Label = 'label',
  Msisdn = 'msisdn'
}

export type ShopProduct = {
  __typename?: 'ShopProduct';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  price: Scalars['String']['output'];
  sku: ShopProductSku;
};

export enum ShopProductSku {
  Datavolume_1gb = 'datavolume_1gb',
  SimClassic_01 = 'sim_classic_01',
  SimMff2_01 = 'sim_mff2_01',
  WireguardBase_1m = 'wireguard_base_1m',
  WireguardXl_1m = 'wireguard_xl_1m'
}

export type Sim = Node & {
  __typename?: 'Sim';
  apn?: Maybe<Scalars['String']['output']>;
  canExceedDataVolume: Scalars['Boolean']['output'];
  costCenterId: Scalars['String']['output'];
  data: Quota;
  endDate?: Maybe<Scalars['String']['output']>;
  iccid: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imei?: Maybe<Scalars['String']['output']>;
  imsi?: Maybe<Scalars['String']['output']>;
  ip?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  lastUse?: Maybe<Scalars['String']['output']>;
  msisdn?: Maybe<Scalars['String']['output']>;
  session: SimSession;
  sms: Quota;
  state: SimState;
  stateReason?: Maybe<SimStateReason>;
  type: SimType;
};

export type SimFilter = {
  __typename?: 'SimFilter';
  filterKey: SimFilterKey;
  filterValue: Scalars['String']['output'];
};

export type SimFilterCount = {
  __typename?: 'SimFilterCount';
  active: Scalars['Int']['output'];
  all: Scalars['Int']['output'];
  eightyPercentDataUsed: Scalars['Int']['output'];
  inactive: Scalars['Int']['output'];
  noDataVolume: Scalars['Int']['output'];
};

export enum SimFilterKey {
  PercentageReached = 'percentageReached',
  State = 'state'
}

export type SimPage = Page & {
  __typename?: 'SimPage';
  filter?: Maybe<SimFilter>;
  filterCount?: Maybe<SimFilterCount>;
  items: Array<Sim>;
  pageInfo: PageInfo;
  sort: Sort;
};

export enum SimSession {
  Offline = 'offline',
  Online = 'online',
  Unknown = 'unknown'
}

export enum SimSortBy {
  Apn = 'apn',
  CostCenterId = 'costCenterId',
  DataVolume = 'dataVolume',
  EndDate = 'endDate',
  Iccid = 'iccid',
  Imei = 'imei',
  Imsi = 'imsi',
  Ip = 'ip',
  Label = 'label',
  LastUse = 'lastUse',
  Msisdn = 'msisdn',
  State = 'state'
}

export enum SimState {
  ActivationPending = 'activation_pending',
  Active = 'active',
  Initial = 'initial',
  SuspendPending = 'suspend_pending',
  Suspended = 'suspended',
  Terminated = 'terminated'
}

export enum SimStateReason {
  ActivationFailed = 'activation_failed',
  ContractDurationExceeded = 'contract_duration_exceeded',
  DataVolumeQuotaExceeded = 'data_volume_quota_exceeded',
  SimUnused = 'sim_unused',
  User = 'user'
}

export enum SimType {
  Classic = 'classic',
  Mff2 = 'mff2'
}

export type SimulateOutput = {
  __typename?: 'SimulateOutput';
  body?: Maybe<Scalars['String']['output']>;
  code: Scalars['Int']['output'];
  mediaType: Scalars['Int']['output'];
};

export type Sort = {
  __typename?: 'Sort';
  sortBy: Scalars['String']['output'];
  sortDirection: SortDirection;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpanOutput = {
  __typename?: 'SpanOutput';
  attributes: Array<KeyValue>;
  key: Scalars['String']['output'];
  resources: Array<KeyValue>;
};

export enum SubscriptionState {
  Active = 'active',
  Inactive = 'inactive',
  Initial = 'initial'
}

export type TechnologyAvailability = {
  __typename?: 'TechnologyAvailability';
  countryName: Scalars['String']['output'];
  is2gAvailable: Scalars['Boolean']['output'];
  is3gAvailable: Scalars['Boolean']['output'];
  is5gAvailable: Scalars['Boolean']['output'];
  isLteAvailable: Scalars['Boolean']['output'];
  isLtemAvailable: Scalars['Boolean']['output'];
  isNbIotAvailable: Scalars['Boolean']['output'];
};

export type Ticket = {
  __typename?: 'Ticket';
  deviceIp: Scalars['String']['output'];
  signature: Scalars['String']['output'];
  timeStamp: Scalars['String']['output'];
};

export type TicketOutput = {
  __typename?: 'TicketOutput';
  ticket: Ticket;
};

export type TraceOutput = {
  __typename?: 'TraceOutput';
  iccid: Scalars['String']['output'];
  spans: Array<SpanOutput>;
  traceID: Scalars['String']['output'];
};

export type TracesOutputs = {
  __typename?: 'TracesOutputs';
  traceOutputs: Array<TraceOutput>;
};

export type UpdateSubscriptionOutput = {
  __typename?: 'UpdateSubscriptionOutput';
  endDate?: Maybe<Scalars['String']['output']>;
  state: SubscriptionState;
};

export type User = Node & {
  __typename?: 'User';
  company?: Maybe<Company>;
  email: Scalars['String']['output'];
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  group: UserGroup;
  id: Scalars['ID']['output'];
  state: UserState;
};

export enum UserGroup {
  CompanyAdmin = 'company_admin',
  CompanyOwner = 'company_owner',
  CompanyUser = 'company_user',
  Deleted = 'deleted',
  SystemAdmin = 'system_admin',
  SystemUser = 'system_user'
}

export enum UserState {
  Active = 'active',
  Deleted = 'deleted',
  Inactive = 'inactive'
}

export type WireGuard = {
  __typename?: 'WireGuard';
  configs?: Maybe<Array<WireGuardConfig>>;
  subscription?: Maybe<WireGuardSubscription>;
};

export type WireGuardConfig = {
  __typename?: 'WireGuardConfig';
  spec: WireGuardSpec;
  status: WireGuardStatus;
  uuid: Scalars['String']['output'];
};

export enum WireGuardDeploymentStatus {
  Deleting = 'deleting',
  Deploying = 'deploying',
  Done = 'done',
  Failed = 'failed',
  Updating = 'updating'
}

export enum WireGuardInstanceType {
  Small = 'small',
  Xlarge = 'xlarge'
}

export type WireGuardSpec = {
  __typename?: 'WireGuardSpec';
  clientPublicKey: Scalars['String']['output'];
  companyId?: Maybe<Scalars['String']['output']>;
  instanceType?: Maybe<WireGuardInstanceType>;
  simIps: Array<Scalars['String']['output']>;
};

export type WireGuardStatus = {
  __typename?: 'WireGuardStatus';
  clientIp: Scalars['String']['output'];
  deploymentStatus?: Maybe<WireGuardDeploymentStatus>;
  wgPublicKey: Scalars['String']['output'];
  wgUrl: Scalars['String']['output'];
};

export type WireGuardSubscription = {
  __typename?: 'WireGuardSubscription';
  endDate?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  instanceType: WireGuardInstanceType;
  state: SubscriptionState;
};

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  familyName: Scalars['String']['input'];
  givenName: Scalars['String']['input'];
  group: UserGroup;
}>;


export type InviteUserMutation = { __typename?: 'Mutation', inviteUser: { __typename?: 'User', email: string } };

export type ResendUserInvitationMutationVariables = Exact<{
  email: Scalars['String']['input'];
}>;


export type ResendUserInvitationMutation = { __typename?: 'Mutation', resendUserInvitation: { __typename?: 'User', email: string } };

export type UpdateUserAttributesMutationVariables = Exact<{
  givenName?: InputMaybe<Scalars['String']['input']>;
  familyName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateUserAttributesMutation = { __typename?: 'Mutation', updateUserAttributes: { __typename?: 'User', email: string } };

export type VerifyUserAttributeMutationVariables = Exact<{
  attributeName: Scalars['String']['input'];
  code: Scalars['String']['input'];
}>;


export type VerifyUserAttributeMutation = { __typename?: 'Mutation', verifyUserAttribute: { __typename?: 'User', email: string } };

export type UpdateUserGroupMutationVariables = Exact<{
  username: Scalars['String']['input'];
  oldGroup: Scalars['String']['input'];
  newGroup: Scalars['String']['input'];
}>;


export type UpdateUserGroupMutation = { __typename?: 'Mutation', updateUserGroup: { __typename?: 'User', email: string } };

export type SoftDeleteUserMutationVariables = Exact<{
  username: Scalars['String']['input'];
}>;


export type SoftDeleteUserMutation = { __typename?: 'Mutation', softDeleteUser: { __typename?: 'User', id: string } };

export type UpdateSystemAdminCompanyIdMutationVariables = Exact<{
  companyId: Scalars['String']['input'];
}>;


export type UpdateSystemAdminCompanyIdMutation = { __typename?: 'Mutation', updateSystemAdminCompanyId?: string | null };

export type CreateSupportTicketMutationVariables = Exact<{
  name: Scalars['String']['input'];
  email: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  simCards?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>> | InputMaybe<Scalars['String']['input']>>;
  firstOccurrenceTimestamp: Scalars['String']['input'];
  description: Scalars['String']['input'];
  technicalInfo?: InputMaybe<Scalars['String']['input']>;
}>;


export type CreateSupportTicketMutation = { __typename?: 'Mutation', createSupportTicket: boolean };

export type UpdateSimAttributesMutationVariables = Exact<{
  iccids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  label?: InputMaybe<Scalars['String']['input']>;
  costCenterId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSimAttributesMutation = { __typename?: 'Mutation', updateSimAttributes: Array<{ __typename?: 'Sim', label: string, costCenterId: string }> };

export type RegisterSimMutationVariables = Exact<{
  iccid: Scalars['String']['input'];
  count?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  mac?: InputMaybe<Scalars['String']['input']>;
}>;


export type RegisterSimMutation = { __typename?: 'Mutation', registerSim: Array<string> };

export type DeactivateSimCardsMutationVariables = Exact<{
  iccids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeactivateSimCardsMutation = { __typename?: 'Mutation', changeSimActivationState: Array<string> };

export type ActivateSimCardsMutationVariables = Exact<{
  iccids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type ActivateSimCardsMutation = { __typename?: 'Mutation', changeSimActivationState: Array<string> };

export type UpdateCompanyAttributesMutationVariables = Exact<{
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  streetAddress: Scalars['String']['input'];
  city: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  country?: InputMaybe<Scalars['String']['input']>;
  vatId?: InputMaybe<Scalars['String']['input']>;
  additionalAddressInfo?: InputMaybe<Scalars['String']['input']>;
  internalReference?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateCompanyAttributesMutation = { __typename?: 'Mutation', updateCompanyAttributes: { __typename?: 'Company', name: string, streetAddress: string, city: string, postalCode: string, country: string, vatId: string, additionalAddressInfo?: string | null, internalReference?: string | null } };

export type ReloadDataVolumeMutationVariables = Exact<{
  invoiceDetails: InvoiceDetails;
  overwriteCompanyAttributes: Scalars['Boolean']['input'];
  paymentType?: InputMaybe<PaymentType>;
  volumes: Array<IccidVolumePair> | IccidVolumePair;
  termsAccepted: Scalars['Boolean']['input'];
}>;


export type ReloadDataVolumeMutation = { __typename?: 'Mutation', reloadDataVolume?: string | null };

export type CreateOrderMutationVariables = Exact<{
  invoiceDetails: InvoiceDetails;
  overwriteCompanyAttributes: Scalars['Boolean']['input'];
  paymentType?: InputMaybe<PaymentType>;
  items: Array<OrderItem> | OrderItem;
  termsAccepted: Scalars['Boolean']['input'];
}>;


export type CreateOrderMutation = { __typename?: 'Mutation', createOrder?: string | null };

export type UpdateIotConnectorGroupMutationVariables = Exact<{
  groupId: Scalars['String']['input'];
  general?: InputMaybe<IotConnectorGroupGeneralInput>;
  sshConfig?: InputMaybe<IotConnectorGroupSshInput>;
  oauth2Config?: InputMaybe<IotConnectorGroupOauth2Input>;
  headers?: InputMaybe<Array<HttpHeaderInput> | HttpHeaderInput>;
}>;


export type UpdateIotConnectorGroupMutation = { __typename?: 'Mutation', updateIotConnectorGroup: { __typename?: 'IotConnectorGroup', id: string, proxy: { __typename?: 'IotConnectorProxyConfiguration', targetUrl?: string | null, headers: Array<{ __typename?: 'Header', key: string, value: string }>, oauth2?: { __typename?: 'Oauth2', clientId: string, clientSecret: string, scopes: string, tokenUrl: string } | null }, ssh?: { __typename?: 'SSH', user: string, publicKey: string, fingerprint: string } | null } };

export type ChangeDataVolumePredicateMutationVariables = Exact<{
  iccid: Scalars['String']['input'];
}>;


export type ChangeDataVolumePredicateMutation = { __typename?: 'Mutation', changeDataVolumePredicate: { __typename?: 'PredicateResult', canExceedDataVolume?: boolean | null } };

export type CreateWireGuardConfigurationMutationVariables = Exact<{
  instanceType: WireGuardInstanceType;
  publicKey: Scalars['String']['input'];
}>;


export type CreateWireGuardConfigurationMutation = { __typename?: 'Mutation', createWireGuardConfiguration: { __typename?: 'WireGuardConfig', uuid: string, spec: { __typename?: 'WireGuardSpec', clientPublicKey: string, simIps: Array<string> }, status: { __typename?: 'WireGuardStatus', clientIp: string, wgPublicKey: string, wgUrl: string, deploymentStatus?: WireGuardDeploymentStatus | null } } };

export type SshTicketGenerationMutationVariables = Exact<{
  iccid: Scalars['String']['input'];
}>;


export type SshTicketGenerationMutation = { __typename?: 'Mutation', sshTicketGeneration: { __typename?: 'TicketOutput', ticket: { __typename?: 'Ticket', deviceIp: string, signature: string, timeStamp: string } } };

export type UpdateSubscriptionMutationVariables = Exact<{
  subscriptionId: Scalars['String']['input'];
  endDate?: InputMaybe<Scalars['String']['input']>;
  typeId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: { __typename?: 'UpdateSubscriptionOutput', endDate?: string | null, state: SubscriptionState } };

export type CompanyQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanyQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, name: string, streetAddress: string, additionalAddressInfo?: string | null, city: string, postalCode: string, country: string, vatId: string, internalReference?: string | null } };

export type FilterCompanyBySimQueryVariables = Exact<{
  filterKey?: InputMaybe<Scalars['String']['input']>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type FilterCompanyBySimQuery = { __typename?: 'Query', filterCompanyBySim: string };

export type SimPageQueryVariables = Exact<{
  first: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sortBy: SimSortBy;
  sortDirection: SortDirection;
  filterKey?: InputMaybe<SimFilterKey>;
  filterValue?: InputMaybe<Scalars['String']['input']>;
  searchKey?: InputMaybe<SearchableColumn>;
  searchValue?: InputMaybe<Scalars['String']['input']>;
}>;


export type SimPageQuery = { __typename?: 'Query', simPage: { __typename?: 'SimPage', items: Array<{ __typename?: 'Sim', apn?: string | null, costCenterId: string, endDate?: string | null, iccid: string, id: string, canExceedDataVolume: boolean, imei?: string | null, imsi?: string | null, ip?: string | null, lastUse?: string | null, msisdn?: string | null, type: SimType, state: SimState, stateReason?: SimStateReason | null, session: SimSession, label: string, data: { __typename?: 'Quota', count: number, limit?: number | null }, sms: { __typename?: 'Quota', count: number, limit?: number | null } }>, pageInfo: { __typename?: 'PageInfo', hasNextPage: boolean, nextPage?: number | null }, filterCount?: { __typename?: 'SimFilterCount', active: number, all: number, eightyPercentDataUsed: number, inactive: number, noDataVolume: number } | null } };

export type SimStateQueryVariables = Exact<{
  iccid: Scalars['ID']['input'];
}>;


export type SimStateQuery = { __typename?: 'Query', simState?: SimState | null };

export type PingQueryVariables = Exact<{
  iccid: Scalars['String']['input'];
}>;


export type PingQuery = { __typename?: 'Query', ping: { __typename?: 'PingResult', success?: boolean | null } };

export type SimulateProxyQueryVariables = Exact<{
  coapRequest: CoapRequest;
  proxyConfigInput: ProxyConfigInput;
}>;


export type SimulateProxyQuery = { __typename?: 'Query', simulateProxy: { __typename?: 'SimulateOutput', body?: string | null, code: number, mediaType: number } };

export type UserListQueryVariables = Exact<{ [key: string]: never; }>;


export type UserListQuery = { __typename?: 'Query', userList: Array<{ __typename?: 'User', id: string, email: string, familyName?: string | null, givenName?: string | null, group: UserGroup, state: UserState }> };

export type CompanySelectUserListQueryVariables = Exact<{ [key: string]: never; }>;


export type CompanySelectUserListQuery = { __typename?: 'Query', companySelectUserList: Array<{ __typename?: 'User', email: string, group: UserGroup, company?: { __typename?: 'Company', id: string, name: string } | null }> };

export type GetIotConnectorGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIotConnectorGroupsQuery = { __typename?: 'Query', getIotConnectorGroups: { __typename?: 'IotConnectorGroups', maxHttpHeaderCount: number, defaultGroup: { __typename?: 'IotConnectorGroup', id: string, proxy: { __typename?: 'IotConnectorProxyConfiguration', targetUrl?: string | null, headers: Array<{ __typename?: 'Header', key: string, value: string }>, oauth2?: { __typename?: 'Oauth2', tokenUrl: string } | null }, ssh?: { __typename?: 'SSH', user: string, publicKey: string, fingerprint: string } | null } } };

export type WireGuardQueryVariables = Exact<{ [key: string]: never; }>;


export type WireGuardQuery = { __typename?: 'Query', wireGuard: { __typename?: 'WireGuard', configs?: Array<{ __typename?: 'WireGuardConfig', uuid: string, spec: { __typename?: 'WireGuardSpec', clientPublicKey: string, simIps: Array<string> }, status: { __typename?: 'WireGuardStatus', clientIp: string, wgPublicKey: string, wgUrl: string, deploymentStatus?: WireGuardDeploymentStatus | null } }> | null, subscription?: { __typename?: 'WireGuardSubscription', id: string, instanceType: WireGuardInstanceType, state: SubscriptionState, endDate?: string | null } | null } };

export type ShopProductQueryVariables = Exact<{
  sku: ShopProductSku;
}>;


export type ShopProductQuery = { __typename?: 'Query', shopProduct: { __typename?: 'ShopProduct', id: number, name: string, price: string, sku: ShopProductSku } };



export const InviteUserDocument = `
    mutation InviteUser($email: String!, $familyName: String!, $givenName: String!, $group: UserGroup!) {
  inviteUser(
    email: $email
    familyName: $familyName
    givenName: $givenName
    group: $group
  ) {
    email
  }
}
    `;

export const useInviteUserMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<InviteUserMutation, TError, InviteUserMutationVariables, TContext>) => {
    
    return useMutation<InviteUserMutation, TError, InviteUserMutationVariables, TContext>(
      {
    mutationKey: ['InviteUser'],
    mutationFn: (variables?: InviteUserMutationVariables) => fetcher<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, variables)(),
    ...options
  }
    )};


useInviteUserMutation.fetcher = (variables: InviteUserMutationVariables) => fetcher<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, variables);

export const ResendUserInvitationDocument = `
    mutation ResendUserInvitation($email: String!) {
  resendUserInvitation(email: $email) {
    email
  }
}
    `;

export const useResendUserInvitationMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<ResendUserInvitationMutation, TError, ResendUserInvitationMutationVariables, TContext>) => {
    
    return useMutation<ResendUserInvitationMutation, TError, ResendUserInvitationMutationVariables, TContext>(
      {
    mutationKey: ['ResendUserInvitation'],
    mutationFn: (variables?: ResendUserInvitationMutationVariables) => fetcher<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>(ResendUserInvitationDocument, variables)(),
    ...options
  }
    )};


useResendUserInvitationMutation.fetcher = (variables: ResendUserInvitationMutationVariables) => fetcher<ResendUserInvitationMutation, ResendUserInvitationMutationVariables>(ResendUserInvitationDocument, variables);

export const UpdateUserAttributesDocument = `
    mutation UpdateUserAttributes($givenName: String, $familyName: String, $email: String) {
  updateUserAttributes(
    givenName: $givenName
    familyName: $familyName
    email: $email
  ) {
    email
  }
}
    `;

export const useUpdateUserAttributesMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserAttributesMutation, TError, UpdateUserAttributesMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserAttributesMutation, TError, UpdateUserAttributesMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUserAttributes'],
    mutationFn: (variables?: UpdateUserAttributesMutationVariables) => fetcher<UpdateUserAttributesMutation, UpdateUserAttributesMutationVariables>(UpdateUserAttributesDocument, variables)(),
    ...options
  }
    )};


useUpdateUserAttributesMutation.fetcher = (variables?: UpdateUserAttributesMutationVariables) => fetcher<UpdateUserAttributesMutation, UpdateUserAttributesMutationVariables>(UpdateUserAttributesDocument, variables);

export const VerifyUserAttributeDocument = `
    mutation VerifyUserAttribute($attributeName: String!, $code: String!) {
  verifyUserAttribute(attributeName: $attributeName, code: $code) {
    email
  }
}
    `;

export const useVerifyUserAttributeMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<VerifyUserAttributeMutation, TError, VerifyUserAttributeMutationVariables, TContext>) => {
    
    return useMutation<VerifyUserAttributeMutation, TError, VerifyUserAttributeMutationVariables, TContext>(
      {
    mutationKey: ['VerifyUserAttribute'],
    mutationFn: (variables?: VerifyUserAttributeMutationVariables) => fetcher<VerifyUserAttributeMutation, VerifyUserAttributeMutationVariables>(VerifyUserAttributeDocument, variables)(),
    ...options
  }
    )};


useVerifyUserAttributeMutation.fetcher = (variables: VerifyUserAttributeMutationVariables) => fetcher<VerifyUserAttributeMutation, VerifyUserAttributeMutationVariables>(VerifyUserAttributeDocument, variables);

export const UpdateUserGroupDocument = `
    mutation UpdateUserGroup($username: String!, $oldGroup: String!, $newGroup: String!) {
  updateUserGroup(username: $username, oldGroup: $oldGroup, newGroup: $newGroup) {
    email
  }
}
    `;

export const useUpdateUserGroupMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateUserGroupMutation, TError, UpdateUserGroupMutationVariables, TContext>) => {
    
    return useMutation<UpdateUserGroupMutation, TError, UpdateUserGroupMutationVariables, TContext>(
      {
    mutationKey: ['UpdateUserGroup'],
    mutationFn: (variables?: UpdateUserGroupMutationVariables) => fetcher<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(UpdateUserGroupDocument, variables)(),
    ...options
  }
    )};


useUpdateUserGroupMutation.fetcher = (variables: UpdateUserGroupMutationVariables) => fetcher<UpdateUserGroupMutation, UpdateUserGroupMutationVariables>(UpdateUserGroupDocument, variables);

export const SoftDeleteUserDocument = `
    mutation SoftDeleteUser($username: String!) {
  softDeleteUser(username: $username) {
    id
  }
}
    `;

export const useSoftDeleteUserMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SoftDeleteUserMutation, TError, SoftDeleteUserMutationVariables, TContext>) => {
    
    return useMutation<SoftDeleteUserMutation, TError, SoftDeleteUserMutationVariables, TContext>(
      {
    mutationKey: ['SoftDeleteUser'],
    mutationFn: (variables?: SoftDeleteUserMutationVariables) => fetcher<SoftDeleteUserMutation, SoftDeleteUserMutationVariables>(SoftDeleteUserDocument, variables)(),
    ...options
  }
    )};


useSoftDeleteUserMutation.fetcher = (variables: SoftDeleteUserMutationVariables) => fetcher<SoftDeleteUserMutation, SoftDeleteUserMutationVariables>(SoftDeleteUserDocument, variables);

export const UpdateSystemAdminCompanyIdDocument = `
    mutation UpdateSystemAdminCompanyId($companyId: String!) {
  updateSystemAdminCompanyId(companyId: $companyId)
}
    `;

export const useUpdateSystemAdminCompanyIdMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSystemAdminCompanyIdMutation, TError, UpdateSystemAdminCompanyIdMutationVariables, TContext>) => {
    
    return useMutation<UpdateSystemAdminCompanyIdMutation, TError, UpdateSystemAdminCompanyIdMutationVariables, TContext>(
      {
    mutationKey: ['UpdateSystemAdminCompanyId'],
    mutationFn: (variables?: UpdateSystemAdminCompanyIdMutationVariables) => fetcher<UpdateSystemAdminCompanyIdMutation, UpdateSystemAdminCompanyIdMutationVariables>(UpdateSystemAdminCompanyIdDocument, variables)(),
    ...options
  }
    )};


useUpdateSystemAdminCompanyIdMutation.fetcher = (variables: UpdateSystemAdminCompanyIdMutationVariables) => fetcher<UpdateSystemAdminCompanyIdMutation, UpdateSystemAdminCompanyIdMutationVariables>(UpdateSystemAdminCompanyIdDocument, variables);

export const CreateSupportTicketDocument = `
    mutation CreateSupportTicket($name: String!, $email: String!, $phone: String, $simCards: [String], $firstOccurrenceTimestamp: String!, $description: String!, $technicalInfo: String) {
  createSupportTicket(
    name: $name
    email: $email
    phone: $phone
    simCards: $simCards
    firstOccurrenceTimestamp: $firstOccurrenceTimestamp
    description: $description
    technicalInfo: $technicalInfo
  )
}
    `;

export const useCreateSupportTicketMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateSupportTicketMutation, TError, CreateSupportTicketMutationVariables, TContext>) => {
    
    return useMutation<CreateSupportTicketMutation, TError, CreateSupportTicketMutationVariables, TContext>(
      {
    mutationKey: ['CreateSupportTicket'],
    mutationFn: (variables?: CreateSupportTicketMutationVariables) => fetcher<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>(CreateSupportTicketDocument, variables)(),
    ...options
  }
    )};


useCreateSupportTicketMutation.fetcher = (variables: CreateSupportTicketMutationVariables) => fetcher<CreateSupportTicketMutation, CreateSupportTicketMutationVariables>(CreateSupportTicketDocument, variables);

export const UpdateSimAttributesDocument = `
    mutation UpdateSimAttributes($iccids: [String!]!, $label: String, $costCenterId: String) {
  updateSimAttributes(iccids: $iccids, label: $label, costCenterId: $costCenterId) {
    label
    costCenterId
  }
}
    `;

export const useUpdateSimAttributesMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSimAttributesMutation, TError, UpdateSimAttributesMutationVariables, TContext>) => {
    
    return useMutation<UpdateSimAttributesMutation, TError, UpdateSimAttributesMutationVariables, TContext>(
      {
    mutationKey: ['UpdateSimAttributes'],
    mutationFn: (variables?: UpdateSimAttributesMutationVariables) => fetcher<UpdateSimAttributesMutation, UpdateSimAttributesMutationVariables>(UpdateSimAttributesDocument, variables)(),
    ...options
  }
    )};


useUpdateSimAttributesMutation.fetcher = (variables: UpdateSimAttributesMutationVariables) => fetcher<UpdateSimAttributesMutation, UpdateSimAttributesMutationVariables>(UpdateSimAttributesDocument, variables);

export const RegisterSimDocument = `
    mutation RegisterSim($iccid: String!, $count: Int, $type: String, $mac: String) {
  registerSim(iccid: $iccid, count: $count, type: $type, mac: $mac)
}
    `;

export const useRegisterSimMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<RegisterSimMutation, TError, RegisterSimMutationVariables, TContext>) => {
    
    return useMutation<RegisterSimMutation, TError, RegisterSimMutationVariables, TContext>(
      {
    mutationKey: ['RegisterSim'],
    mutationFn: (variables?: RegisterSimMutationVariables) => fetcher<RegisterSimMutation, RegisterSimMutationVariables>(RegisterSimDocument, variables)(),
    ...options
  }
    )};


useRegisterSimMutation.fetcher = (variables: RegisterSimMutationVariables) => fetcher<RegisterSimMutation, RegisterSimMutationVariables>(RegisterSimDocument, variables);

export const DeactivateSimCardsDocument = `
    mutation DeactivateSimCards($iccids: [String!]!) {
  changeSimActivationState(action: deactivate, iccids: $iccids)
}
    `;

export const useDeactivateSimCardsMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<DeactivateSimCardsMutation, TError, DeactivateSimCardsMutationVariables, TContext>) => {
    
    return useMutation<DeactivateSimCardsMutation, TError, DeactivateSimCardsMutationVariables, TContext>(
      {
    mutationKey: ['DeactivateSimCards'],
    mutationFn: (variables?: DeactivateSimCardsMutationVariables) => fetcher<DeactivateSimCardsMutation, DeactivateSimCardsMutationVariables>(DeactivateSimCardsDocument, variables)(),
    ...options
  }
    )};


useDeactivateSimCardsMutation.fetcher = (variables: DeactivateSimCardsMutationVariables) => fetcher<DeactivateSimCardsMutation, DeactivateSimCardsMutationVariables>(DeactivateSimCardsDocument, variables);

export const ActivateSimCardsDocument = `
    mutation ActivateSimCards($iccids: [String!]!) {
  changeSimActivationState(action: activate, iccids: $iccids)
}
    `;

export const useActivateSimCardsMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<ActivateSimCardsMutation, TError, ActivateSimCardsMutationVariables, TContext>) => {
    
    return useMutation<ActivateSimCardsMutation, TError, ActivateSimCardsMutationVariables, TContext>(
      {
    mutationKey: ['ActivateSimCards'],
    mutationFn: (variables?: ActivateSimCardsMutationVariables) => fetcher<ActivateSimCardsMutation, ActivateSimCardsMutationVariables>(ActivateSimCardsDocument, variables)(),
    ...options
  }
    )};


useActivateSimCardsMutation.fetcher = (variables: ActivateSimCardsMutationVariables) => fetcher<ActivateSimCardsMutation, ActivateSimCardsMutationVariables>(ActivateSimCardsDocument, variables);

export const UpdateCompanyAttributesDocument = `
    mutation UpdateCompanyAttributes($id: ID!, $name: String, $streetAddress: String!, $city: String!, $postalCode: String!, $country: String, $vatId: String, $additionalAddressInfo: String, $internalReference: String) {
  updateCompanyAttributes(
    id: $id
    name: $name
    streetAddress: $streetAddress
    city: $city
    postalCode: $postalCode
    country: $country
    vatId: $vatId
    additionalAddressInfo: $additionalAddressInfo
    internalReference: $internalReference
  ) {
    name
    streetAddress
    city
    postalCode
    country
    vatId
    additionalAddressInfo
    internalReference
  }
}
    `;

export const useUpdateCompanyAttributesMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateCompanyAttributesMutation, TError, UpdateCompanyAttributesMutationVariables, TContext>) => {
    
    return useMutation<UpdateCompanyAttributesMutation, TError, UpdateCompanyAttributesMutationVariables, TContext>(
      {
    mutationKey: ['UpdateCompanyAttributes'],
    mutationFn: (variables?: UpdateCompanyAttributesMutationVariables) => fetcher<UpdateCompanyAttributesMutation, UpdateCompanyAttributesMutationVariables>(UpdateCompanyAttributesDocument, variables)(),
    ...options
  }
    )};


useUpdateCompanyAttributesMutation.fetcher = (variables: UpdateCompanyAttributesMutationVariables) => fetcher<UpdateCompanyAttributesMutation, UpdateCompanyAttributesMutationVariables>(UpdateCompanyAttributesDocument, variables);

export const ReloadDataVolumeDocument = `
    mutation ReloadDataVolume($invoiceDetails: InvoiceDetails!, $overwriteCompanyAttributes: Boolean!, $paymentType: PaymentType, $volumes: [IccidVolumePair!]!, $termsAccepted: Boolean!) {
  reloadDataVolume(
    invoiceDetails: $invoiceDetails
    overwriteCompanyAttributes: $overwriteCompanyAttributes
    paymentType: $paymentType
    volumes: $volumes
    termsAccepted: $termsAccepted
  )
}
    `;

export const useReloadDataVolumeMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<ReloadDataVolumeMutation, TError, ReloadDataVolumeMutationVariables, TContext>) => {
    
    return useMutation<ReloadDataVolumeMutation, TError, ReloadDataVolumeMutationVariables, TContext>(
      {
    mutationKey: ['ReloadDataVolume'],
    mutationFn: (variables?: ReloadDataVolumeMutationVariables) => fetcher<ReloadDataVolumeMutation, ReloadDataVolumeMutationVariables>(ReloadDataVolumeDocument, variables)(),
    ...options
  }
    )};


useReloadDataVolumeMutation.fetcher = (variables: ReloadDataVolumeMutationVariables) => fetcher<ReloadDataVolumeMutation, ReloadDataVolumeMutationVariables>(ReloadDataVolumeDocument, variables);

export const CreateOrderDocument = `
    mutation CreateOrder($invoiceDetails: InvoiceDetails!, $overwriteCompanyAttributes: Boolean!, $paymentType: PaymentType, $items: [OrderItem!]!, $termsAccepted: Boolean!) {
  createOrder(
    invoiceDetails: $invoiceDetails
    overwriteCompanyAttributes: $overwriteCompanyAttributes
    paymentType: $paymentType
    items: $items
    termsAccepted: $termsAccepted
  )
}
    `;

export const useCreateOrderMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateOrderMutation, TError, CreateOrderMutationVariables, TContext>) => {
    
    return useMutation<CreateOrderMutation, TError, CreateOrderMutationVariables, TContext>(
      {
    mutationKey: ['CreateOrder'],
    mutationFn: (variables?: CreateOrderMutationVariables) => fetcher<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, variables)(),
    ...options
  }
    )};


useCreateOrderMutation.fetcher = (variables: CreateOrderMutationVariables) => fetcher<CreateOrderMutation, CreateOrderMutationVariables>(CreateOrderDocument, variables);

export const UpdateIotConnectorGroupDocument = `
    mutation UpdateIotConnectorGroup($groupId: String!, $general: IotConnectorGroupGeneralInput, $sshConfig: IotConnectorGroupSSHInput, $oauth2Config: IotConnectorGroupOauth2Input, $headers: [HttpHeaderInput!]) {
  updateIotConnectorGroup(
    groupId: $groupId
    general: $general
    sshConfig: $sshConfig
    headers: $headers
    oauth2Config: $oauth2Config
  ) {
    id
    proxy {
      targetUrl
      headers {
        key
        value
      }
      oauth2 {
        clientId
        clientSecret
        scopes
        tokenUrl
      }
    }
    ssh {
      user
      publicKey
      fingerprint
    }
  }
}
    `;

export const useUpdateIotConnectorGroupMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateIotConnectorGroupMutation, TError, UpdateIotConnectorGroupMutationVariables, TContext>) => {
    
    return useMutation<UpdateIotConnectorGroupMutation, TError, UpdateIotConnectorGroupMutationVariables, TContext>(
      {
    mutationKey: ['UpdateIotConnectorGroup'],
    mutationFn: (variables?: UpdateIotConnectorGroupMutationVariables) => fetcher<UpdateIotConnectorGroupMutation, UpdateIotConnectorGroupMutationVariables>(UpdateIotConnectorGroupDocument, variables)(),
    ...options
  }
    )};


useUpdateIotConnectorGroupMutation.fetcher = (variables: UpdateIotConnectorGroupMutationVariables) => fetcher<UpdateIotConnectorGroupMutation, UpdateIotConnectorGroupMutationVariables>(UpdateIotConnectorGroupDocument, variables);

export const ChangeDataVolumePredicateDocument = `
    mutation changeDataVolumePredicate($iccid: String!) {
  changeDataVolumePredicate(iccid: $iccid) {
    canExceedDataVolume
  }
}
    `;

export const useChangeDataVolumePredicateMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<ChangeDataVolumePredicateMutation, TError, ChangeDataVolumePredicateMutationVariables, TContext>) => {
    
    return useMutation<ChangeDataVolumePredicateMutation, TError, ChangeDataVolumePredicateMutationVariables, TContext>(
      {
    mutationKey: ['changeDataVolumePredicate'],
    mutationFn: (variables?: ChangeDataVolumePredicateMutationVariables) => fetcher<ChangeDataVolumePredicateMutation, ChangeDataVolumePredicateMutationVariables>(ChangeDataVolumePredicateDocument, variables)(),
    ...options
  }
    )};


useChangeDataVolumePredicateMutation.fetcher = (variables: ChangeDataVolumePredicateMutationVariables) => fetcher<ChangeDataVolumePredicateMutation, ChangeDataVolumePredicateMutationVariables>(ChangeDataVolumePredicateDocument, variables);

export const CreateWireGuardConfigurationDocument = `
    mutation CreateWireGuardConfiguration($instanceType: WireGuardInstanceType!, $publicKey: String!) {
  createWireGuardConfiguration(instanceType: $instanceType, publicKey: $publicKey) {
    spec {
      clientPublicKey
      simIps
    }
    status {
      clientIp
      wgPublicKey
      wgUrl
      deploymentStatus
    }
    uuid
  }
}
    `;

export const useCreateWireGuardConfigurationMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<CreateWireGuardConfigurationMutation, TError, CreateWireGuardConfigurationMutationVariables, TContext>) => {
    
    return useMutation<CreateWireGuardConfigurationMutation, TError, CreateWireGuardConfigurationMutationVariables, TContext>(
      {
    mutationKey: ['CreateWireGuardConfiguration'],
    mutationFn: (variables?: CreateWireGuardConfigurationMutationVariables) => fetcher<CreateWireGuardConfigurationMutation, CreateWireGuardConfigurationMutationVariables>(CreateWireGuardConfigurationDocument, variables)(),
    ...options
  }
    )};


useCreateWireGuardConfigurationMutation.fetcher = (variables: CreateWireGuardConfigurationMutationVariables) => fetcher<CreateWireGuardConfigurationMutation, CreateWireGuardConfigurationMutationVariables>(CreateWireGuardConfigurationDocument, variables);

export const SshTicketGenerationDocument = `
    mutation sshTicketGeneration($iccid: String!) {
  sshTicketGeneration(iccid: $iccid) {
    ticket {
      deviceIp
      signature
      timeStamp
    }
  }
}
    `;

export const useSshTicketGenerationMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<SshTicketGenerationMutation, TError, SshTicketGenerationMutationVariables, TContext>) => {
    
    return useMutation<SshTicketGenerationMutation, TError, SshTicketGenerationMutationVariables, TContext>(
      {
    mutationKey: ['sshTicketGeneration'],
    mutationFn: (variables?: SshTicketGenerationMutationVariables) => fetcher<SshTicketGenerationMutation, SshTicketGenerationMutationVariables>(SshTicketGenerationDocument, variables)(),
    ...options
  }
    )};


useSshTicketGenerationMutation.fetcher = (variables: SshTicketGenerationMutationVariables) => fetcher<SshTicketGenerationMutation, SshTicketGenerationMutationVariables>(SshTicketGenerationDocument, variables);

export const UpdateSubscriptionDocument = `
    mutation UpdateSubscription($subscriptionId: String!, $endDate: String, $typeId: String) {
  updateSubscription(
    subscriptionId: $subscriptionId
    endDate: $endDate
    typeId: $typeId
  ) {
    endDate
    state
  }
}
    `;

export const useUpdateSubscriptionMutation = <
      TError = Error,
      TContext = unknown
    >(options?: UseMutationOptions<UpdateSubscriptionMutation, TError, UpdateSubscriptionMutationVariables, TContext>) => {
    
    return useMutation<UpdateSubscriptionMutation, TError, UpdateSubscriptionMutationVariables, TContext>(
      {
    mutationKey: ['UpdateSubscription'],
    mutationFn: (variables?: UpdateSubscriptionMutationVariables) => fetcher<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, variables)(),
    ...options
  }
    )};


useUpdateSubscriptionMutation.fetcher = (variables: UpdateSubscriptionMutationVariables) => fetcher<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, variables);

export const CompanyDocument = `
    query Company {
  company {
    id
    name
    streetAddress
    additionalAddressInfo
    city
    postalCode
    country
    vatId
    internalReference
  }
}
    `;

export const useCompanyQuery = <
      TData = CompanyQuery,
      TError = Error
    >(
      variables?: CompanyQueryVariables,
      options?: Omit<UseQueryOptions<CompanyQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CompanyQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CompanyQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['Company'] : ['Company', variables],
    queryFn: fetcher<CompanyQuery, CompanyQueryVariables>(CompanyDocument, variables),
    ...options
  }
    )};

useCompanyQuery.getKey = (variables?: CompanyQueryVariables) => variables === undefined ? ['Company'] : ['Company', variables];

export const useInfiniteCompanyQuery = <
      TData = InfiniteData<CompanyQuery>,
      TError = Error
    >(
      variables: CompanyQueryVariables,
      options: Omit<UseInfiniteQueryOptions<CompanyQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<CompanyQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<CompanyQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? variables === undefined ? ['Company.infinite'] : ['Company.infinite', variables],
      queryFn: (metaData) => fetcher<CompanyQuery, CompanyQueryVariables>(CompanyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteCompanyQuery.getKey = (variables?: CompanyQueryVariables) => variables === undefined ? ['Company.infinite'] : ['Company.infinite', variables];


useCompanyQuery.fetcher = (variables?: CompanyQueryVariables) => fetcher<CompanyQuery, CompanyQueryVariables>(CompanyDocument, variables);

export const FilterCompanyBySimDocument = `
    query FilterCompanyBySim($filterKey: String, $filterValue: String) {
  filterCompanyBySim(filterKey: $filterKey, filterValue: $filterValue)
}
    `;

export const useFilterCompanyBySimQuery = <
      TData = FilterCompanyBySimQuery,
      TError = Error
    >(
      variables?: FilterCompanyBySimQueryVariables,
      options?: Omit<UseQueryOptions<FilterCompanyBySimQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<FilterCompanyBySimQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<FilterCompanyBySimQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['FilterCompanyBySim'] : ['FilterCompanyBySim', variables],
    queryFn: fetcher<FilterCompanyBySimQuery, FilterCompanyBySimQueryVariables>(FilterCompanyBySimDocument, variables),
    ...options
  }
    )};

useFilterCompanyBySimQuery.getKey = (variables?: FilterCompanyBySimQueryVariables) => variables === undefined ? ['FilterCompanyBySim'] : ['FilterCompanyBySim', variables];

export const useInfiniteFilterCompanyBySimQuery = <
      TData = InfiniteData<FilterCompanyBySimQuery>,
      TError = Error
    >(
      variables: FilterCompanyBySimQueryVariables,
      options: Omit<UseInfiniteQueryOptions<FilterCompanyBySimQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<FilterCompanyBySimQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<FilterCompanyBySimQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? variables === undefined ? ['FilterCompanyBySim.infinite'] : ['FilterCompanyBySim.infinite', variables],
      queryFn: (metaData) => fetcher<FilterCompanyBySimQuery, FilterCompanyBySimQueryVariables>(FilterCompanyBySimDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteFilterCompanyBySimQuery.getKey = (variables?: FilterCompanyBySimQueryVariables) => variables === undefined ? ['FilterCompanyBySim.infinite'] : ['FilterCompanyBySim.infinite', variables];


useFilterCompanyBySimQuery.fetcher = (variables?: FilterCompanyBySimQueryVariables) => fetcher<FilterCompanyBySimQuery, FilterCompanyBySimQueryVariables>(FilterCompanyBySimDocument, variables);

export const SimPageDocument = `
    query SimPage($first: Int!, $offset: Int!, $sortBy: SimSortBy!, $sortDirection: SortDirection!, $filterKey: SimFilterKey, $filterValue: String, $searchKey: SearchableColumn, $searchValue: String) {
  simPage(
    first: $first
    offset: $offset
    sortBy: $sortBy
    sortDirection: $sortDirection
    filterKey: $filterKey
    filterValue: $filterValue
    searchKey: $searchKey
    searchValue: $searchValue
  ) {
    items {
      apn
      costCenterId
      data {
        count
        limit
      }
      sms {
        count
        limit
      }
      endDate
      iccid
      id
      canExceedDataVolume
      imei
      imsi
      ip
      lastUse
      msisdn
      type
      state
      stateReason
      session
      label
    }
    pageInfo {
      hasNextPage
      nextPage
    }
    filterCount {
      active
      all
      eightyPercentDataUsed
      inactive
      noDataVolume
    }
  }
}
    `;

export const useSimPageQuery = <
      TData = SimPageQuery,
      TError = Error
    >(
      variables: SimPageQueryVariables,
      options?: Omit<UseQueryOptions<SimPageQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<SimPageQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<SimPageQuery, TError, TData>(
      {
    queryKey: ['SimPage', variables],
    queryFn: fetcher<SimPageQuery, SimPageQueryVariables>(SimPageDocument, variables),
    ...options
  }
    )};

useSimPageQuery.getKey = (variables: SimPageQueryVariables) => ['SimPage', variables];

export const useInfiniteSimPageQuery = <
      TData = InfiniteData<SimPageQuery>,
      TError = Error
    >(
      variables: SimPageQueryVariables,
      options: Omit<UseInfiniteQueryOptions<SimPageQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<SimPageQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<SimPageQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? ['SimPage.infinite', variables],
      queryFn: (metaData) => fetcher<SimPageQuery, SimPageQueryVariables>(SimPageDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteSimPageQuery.getKey = (variables: SimPageQueryVariables) => ['SimPage.infinite', variables];


useSimPageQuery.fetcher = (variables: SimPageQueryVariables) => fetcher<SimPageQuery, SimPageQueryVariables>(SimPageDocument, variables);

export const SimStateDocument = `
    query SimState($iccid: ID!) {
  simState(iccid: $iccid)
}
    `;

export const useSimStateQuery = <
      TData = SimStateQuery,
      TError = Error
    >(
      variables: SimStateQueryVariables,
      options?: Omit<UseQueryOptions<SimStateQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<SimStateQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<SimStateQuery, TError, TData>(
      {
    queryKey: ['SimState', variables],
    queryFn: fetcher<SimStateQuery, SimStateQueryVariables>(SimStateDocument, variables),
    ...options
  }
    )};

useSimStateQuery.getKey = (variables: SimStateQueryVariables) => ['SimState', variables];

export const useInfiniteSimStateQuery = <
      TData = InfiniteData<SimStateQuery>,
      TError = Error
    >(
      variables: SimStateQueryVariables,
      options: Omit<UseInfiniteQueryOptions<SimStateQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<SimStateQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<SimStateQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? ['SimState.infinite', variables],
      queryFn: (metaData) => fetcher<SimStateQuery, SimStateQueryVariables>(SimStateDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteSimStateQuery.getKey = (variables: SimStateQueryVariables) => ['SimState.infinite', variables];


useSimStateQuery.fetcher = (variables: SimStateQueryVariables) => fetcher<SimStateQuery, SimStateQueryVariables>(SimStateDocument, variables);

export const PingDocument = `
    query ping($iccid: String!) {
  ping(iccid: $iccid) {
    success
  }
}
    `;

export const usePingQuery = <
      TData = PingQuery,
      TError = Error
    >(
      variables: PingQueryVariables,
      options?: Omit<UseQueryOptions<PingQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<PingQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<PingQuery, TError, TData>(
      {
    queryKey: ['ping', variables],
    queryFn: fetcher<PingQuery, PingQueryVariables>(PingDocument, variables),
    ...options
  }
    )};

usePingQuery.getKey = (variables: PingQueryVariables) => ['ping', variables];

export const useInfinitePingQuery = <
      TData = InfiniteData<PingQuery>,
      TError = Error
    >(
      variables: PingQueryVariables,
      options: Omit<UseInfiniteQueryOptions<PingQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<PingQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<PingQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? ['ping.infinite', variables],
      queryFn: (metaData) => fetcher<PingQuery, PingQueryVariables>(PingDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfinitePingQuery.getKey = (variables: PingQueryVariables) => ['ping.infinite', variables];


usePingQuery.fetcher = (variables: PingQueryVariables) => fetcher<PingQuery, PingQueryVariables>(PingDocument, variables);

export const SimulateProxyDocument = `
    query simulateProxy($coapRequest: CoapRequest!, $proxyConfigInput: ProxyConfigInput!) {
  simulateProxy(coapRequest: $coapRequest, proxyConfigInput: $proxyConfigInput) {
    body
    code
    mediaType
  }
}
    `;

export const useSimulateProxyQuery = <
      TData = SimulateProxyQuery,
      TError = Error
    >(
      variables: SimulateProxyQueryVariables,
      options?: Omit<UseQueryOptions<SimulateProxyQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<SimulateProxyQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<SimulateProxyQuery, TError, TData>(
      {
    queryKey: ['simulateProxy', variables],
    queryFn: fetcher<SimulateProxyQuery, SimulateProxyQueryVariables>(SimulateProxyDocument, variables),
    ...options
  }
    )};

useSimulateProxyQuery.getKey = (variables: SimulateProxyQueryVariables) => ['simulateProxy', variables];

export const useInfiniteSimulateProxyQuery = <
      TData = InfiniteData<SimulateProxyQuery>,
      TError = Error
    >(
      variables: SimulateProxyQueryVariables,
      options: Omit<UseInfiniteQueryOptions<SimulateProxyQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<SimulateProxyQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<SimulateProxyQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? ['simulateProxy.infinite', variables],
      queryFn: (metaData) => fetcher<SimulateProxyQuery, SimulateProxyQueryVariables>(SimulateProxyDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteSimulateProxyQuery.getKey = (variables: SimulateProxyQueryVariables) => ['simulateProxy.infinite', variables];


useSimulateProxyQuery.fetcher = (variables: SimulateProxyQueryVariables) => fetcher<SimulateProxyQuery, SimulateProxyQueryVariables>(SimulateProxyDocument, variables);

export const UserListDocument = `
    query UserList {
  userList {
    id
    email
    familyName
    givenName
    group
    state
  }
}
    `;

export const useUserListQuery = <
      TData = UserListQuery,
      TError = Error
    >(
      variables?: UserListQueryVariables,
      options?: Omit<UseQueryOptions<UserListQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<UserListQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<UserListQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['UserList'] : ['UserList', variables],
    queryFn: fetcher<UserListQuery, UserListQueryVariables>(UserListDocument, variables),
    ...options
  }
    )};

useUserListQuery.getKey = (variables?: UserListQueryVariables) => variables === undefined ? ['UserList'] : ['UserList', variables];

export const useInfiniteUserListQuery = <
      TData = InfiniteData<UserListQuery>,
      TError = Error
    >(
      variables: UserListQueryVariables,
      options: Omit<UseInfiniteQueryOptions<UserListQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<UserListQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<UserListQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? variables === undefined ? ['UserList.infinite'] : ['UserList.infinite', variables],
      queryFn: (metaData) => fetcher<UserListQuery, UserListQueryVariables>(UserListDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteUserListQuery.getKey = (variables?: UserListQueryVariables) => variables === undefined ? ['UserList.infinite'] : ['UserList.infinite', variables];


useUserListQuery.fetcher = (variables?: UserListQueryVariables) => fetcher<UserListQuery, UserListQueryVariables>(UserListDocument, variables);

export const CompanySelectUserListDocument = `
    query CompanySelectUserList {
  companySelectUserList {
    email
    group
    company {
      id
      name
    }
  }
}
    `;

export const useCompanySelectUserListQuery = <
      TData = CompanySelectUserListQuery,
      TError = Error
    >(
      variables?: CompanySelectUserListQueryVariables,
      options?: Omit<UseQueryOptions<CompanySelectUserListQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<CompanySelectUserListQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<CompanySelectUserListQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['CompanySelectUserList'] : ['CompanySelectUserList', variables],
    queryFn: fetcher<CompanySelectUserListQuery, CompanySelectUserListQueryVariables>(CompanySelectUserListDocument, variables),
    ...options
  }
    )};

useCompanySelectUserListQuery.getKey = (variables?: CompanySelectUserListQueryVariables) => variables === undefined ? ['CompanySelectUserList'] : ['CompanySelectUserList', variables];

export const useInfiniteCompanySelectUserListQuery = <
      TData = InfiniteData<CompanySelectUserListQuery>,
      TError = Error
    >(
      variables: CompanySelectUserListQueryVariables,
      options: Omit<UseInfiniteQueryOptions<CompanySelectUserListQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<CompanySelectUserListQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<CompanySelectUserListQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? variables === undefined ? ['CompanySelectUserList.infinite'] : ['CompanySelectUserList.infinite', variables],
      queryFn: (metaData) => fetcher<CompanySelectUserListQuery, CompanySelectUserListQueryVariables>(CompanySelectUserListDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteCompanySelectUserListQuery.getKey = (variables?: CompanySelectUserListQueryVariables) => variables === undefined ? ['CompanySelectUserList.infinite'] : ['CompanySelectUserList.infinite', variables];


useCompanySelectUserListQuery.fetcher = (variables?: CompanySelectUserListQueryVariables) => fetcher<CompanySelectUserListQuery, CompanySelectUserListQueryVariables>(CompanySelectUserListDocument, variables);

export const GetIotConnectorGroupsDocument = `
    query GetIotConnectorGroups {
  getIotConnectorGroups {
    defaultGroup {
      id
      proxy {
        targetUrl
        headers {
          key
          value
        }
        oauth2 {
          tokenUrl
        }
      }
      ssh {
        user
        publicKey
        fingerprint
      }
    }
    maxHttpHeaderCount
  }
}
    `;

export const useGetIotConnectorGroupsQuery = <
      TData = GetIotConnectorGroupsQuery,
      TError = Error
    >(
      variables?: GetIotConnectorGroupsQueryVariables,
      options?: Omit<UseQueryOptions<GetIotConnectorGroupsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<GetIotConnectorGroupsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<GetIotConnectorGroupsQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['GetIotConnectorGroups'] : ['GetIotConnectorGroups', variables],
    queryFn: fetcher<GetIotConnectorGroupsQuery, GetIotConnectorGroupsQueryVariables>(GetIotConnectorGroupsDocument, variables),
    ...options
  }
    )};

useGetIotConnectorGroupsQuery.getKey = (variables?: GetIotConnectorGroupsQueryVariables) => variables === undefined ? ['GetIotConnectorGroups'] : ['GetIotConnectorGroups', variables];

export const useInfiniteGetIotConnectorGroupsQuery = <
      TData = InfiniteData<GetIotConnectorGroupsQuery>,
      TError = Error
    >(
      variables: GetIotConnectorGroupsQueryVariables,
      options: Omit<UseInfiniteQueryOptions<GetIotConnectorGroupsQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<GetIotConnectorGroupsQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<GetIotConnectorGroupsQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? variables === undefined ? ['GetIotConnectorGroups.infinite'] : ['GetIotConnectorGroups.infinite', variables],
      queryFn: (metaData) => fetcher<GetIotConnectorGroupsQuery, GetIotConnectorGroupsQueryVariables>(GetIotConnectorGroupsDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteGetIotConnectorGroupsQuery.getKey = (variables?: GetIotConnectorGroupsQueryVariables) => variables === undefined ? ['GetIotConnectorGroups.infinite'] : ['GetIotConnectorGroups.infinite', variables];


useGetIotConnectorGroupsQuery.fetcher = (variables?: GetIotConnectorGroupsQueryVariables) => fetcher<GetIotConnectorGroupsQuery, GetIotConnectorGroupsQueryVariables>(GetIotConnectorGroupsDocument, variables);

export const WireGuardDocument = `
    query WireGuard {
  wireGuard {
    configs {
      spec {
        clientPublicKey
        simIps
      }
      status {
        clientIp
        wgPublicKey
        wgUrl
        deploymentStatus
      }
      uuid
    }
    subscription {
      id
      instanceType
      state
      endDate
    }
  }
}
    `;

export const useWireGuardQuery = <
      TData = WireGuardQuery,
      TError = Error
    >(
      variables?: WireGuardQueryVariables,
      options?: Omit<UseQueryOptions<WireGuardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<WireGuardQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<WireGuardQuery, TError, TData>(
      {
    queryKey: variables === undefined ? ['WireGuard'] : ['WireGuard', variables],
    queryFn: fetcher<WireGuardQuery, WireGuardQueryVariables>(WireGuardDocument, variables),
    ...options
  }
    )};

useWireGuardQuery.getKey = (variables?: WireGuardQueryVariables) => variables === undefined ? ['WireGuard'] : ['WireGuard', variables];

export const useInfiniteWireGuardQuery = <
      TData = InfiniteData<WireGuardQuery>,
      TError = Error
    >(
      variables: WireGuardQueryVariables,
      options: Omit<UseInfiniteQueryOptions<WireGuardQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<WireGuardQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<WireGuardQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? variables === undefined ? ['WireGuard.infinite'] : ['WireGuard.infinite', variables],
      queryFn: (metaData) => fetcher<WireGuardQuery, WireGuardQueryVariables>(WireGuardDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteWireGuardQuery.getKey = (variables?: WireGuardQueryVariables) => variables === undefined ? ['WireGuard.infinite'] : ['WireGuard.infinite', variables];


useWireGuardQuery.fetcher = (variables?: WireGuardQueryVariables) => fetcher<WireGuardQuery, WireGuardQueryVariables>(WireGuardDocument, variables);

export const ShopProductDocument = `
    query ShopProduct($sku: ShopProductSku!) {
  shopProduct(sku: $sku) {
    id
    name
    price
    sku
  }
}
    `;

export const useShopProductQuery = <
      TData = ShopProductQuery,
      TError = Error
    >(
      variables: ShopProductQueryVariables,
      options?: Omit<UseQueryOptions<ShopProductQuery, TError, TData>, 'queryKey'> & { queryKey?: UseQueryOptions<ShopProductQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useQuery<ShopProductQuery, TError, TData>(
      {
    queryKey: ['ShopProduct', variables],
    queryFn: fetcher<ShopProductQuery, ShopProductQueryVariables>(ShopProductDocument, variables),
    ...options
  }
    )};

useShopProductQuery.getKey = (variables: ShopProductQueryVariables) => ['ShopProduct', variables];

export const useInfiniteShopProductQuery = <
      TData = InfiniteData<ShopProductQuery>,
      TError = Error
    >(
      variables: ShopProductQueryVariables,
      options: Omit<UseInfiniteQueryOptions<ShopProductQuery, TError, TData>, 'queryKey'> & { queryKey?: UseInfiniteQueryOptions<ShopProductQuery, TError, TData>['queryKey'] }
    ) => {
    
    return useInfiniteQuery<ShopProductQuery, TError, TData>(
      (() => {
    const { queryKey: optionsQueryKey, ...restOptions } = options;
    return {
      queryKey: optionsQueryKey ?? ['ShopProduct.infinite', variables],
      queryFn: (metaData) => fetcher<ShopProductQuery, ShopProductQueryVariables>(ShopProductDocument, {...variables, ...(metaData.pageParam ?? {})})(),
      ...restOptions
    }
  })()
    )};

useInfiniteShopProductQuery.getKey = (variables: ShopProductQueryVariables) => ['ShopProduct.infinite', variables];


useShopProductQuery.fetcher = (variables: ShopProductQueryVariables) => fetcher<ShopProductQuery, ShopProductQueryVariables>(ShopProductDocument, variables);
