import type { ComponentStyleConfig } from '@chakra-ui/react';

export const Button: ComponentStyleConfig = {
  baseStyle: {
    _active: {
      color: 'white',
      bgColor: 'qGray.500',
    },
    _hover: {
      color: 'white',
      bgColor: 'brand.500',
      borderColor: 'brand.500',
      _disabled: {
        color: 'gray.400',
        bg: 'gray.50',
      },
    },
    _focus: {
      boxShadow: 'none',
      border: '1px solid',
      borderColor: 'brand.500',
    },
    _disabled: {
      color: 'gray.400',
      bg: 'gray.50',
      border: 'none',
    },
  },
  sizes: {
    lg: {
      px: '5',
    },
    sm: {
      fontSize: 'md',
      px: '5',
    },
    xxs: {
      h: 4,
      w: 4,
      fontSize: 'md',
    },
  },
  variants: {
    link: {
      _hover: { textDecoration: 'none' },
    },
    primary: {
      color: 'white',
      bgColor: 'qGray.500',
      border: '1px solid',
      borderColor: 'qGray.500',
    },
    secondary: {
      bgColor: 'inherit',
      border: '1px solid',
      borderColor: 'qGray.500',
    },
    sort: {
      bgColor: 'white',
      color: 'black',
      border: '1px solid',
      borderColor: 'qGray.200',
      m: 2,

      _hover: {
        color: 'inherit',
        bg: 'inherit',
      },
      _focus: {
        borderColor: 'brand.500',
      },
    },
    ghost: {
      border: '1px solid',
      borderColor: 'transparent',
      _active: {
        color: 'brand.500',
        bg: 'inherit',
      },
      _hover: {
        color: 'brand.500',
        bg: 'inherit',
        borderColor: 'transparent',
      },
    },
    outline: {
      _active: { bg: 'brand.500' },
      _hover: { bg: 'brand.500' },
    },
  },

  defaultProps: {
    variant: 'primary',
    size: 'sm',
  },
};

// For testing purposes or getting familiar with the buttons copy&paste the following
// code anywhere. Please be aware we don't use all default variants.

/*
<HStack p="1rem" bg="gray.200">
  <Button variant="primary">medium primary</Button>
  <Button variant="primary" size="lg">
    large primary
  </Button>
  <Button variant="secondary">secondary</Button>
  <Button disabled>disabled</Button>
  <Button variant="solid">solid</Button>
  <Button variant="outline">outline</Button>
  <Button variant="ghost">ghost</Button>
  <Button variant="link">link</Button>
</HStack>
*/
