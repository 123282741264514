import { useToast, type UseToastOptions } from '@chakra-ui/react';

const defaultOptions: UseToastOptions = {
  position: 'top',
  duration: 5000,
};

const errorToastProps: UseToastOptions = {
  status: 'error',
  duration: 10000,
  isClosable: true,
};

export const useIotSimToast = (options?: UseToastOptions) =>
  useToast({
    ...defaultOptions,
    ...(options?.status === 'error' && errorToastProps),
    ...options,
  });
