import { HStack, Text } from '@chakra-ui/react';
import { type Cell } from '@tanstack/react-table';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { type UsersTableRow } from '..';
import { UserRoleForm } from './UserRoleForm';
import { UserGroup } from 'utils/graphql/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

interface UserGroupCellProps {
  cell: Cell<UsersTableRow, string>;
}

export const UserGroupCell: FunctionComponent<UserGroupCellProps> = ({ cell }) => {
  const { t } = useTranslation();
  const { authUser, hasRequiredRole } = useAuthUserContext();

  const editable =
    hasRequiredRole(UserGroup.CompanyAdmin) &&
    hasRequiredRole(cell.row.original.group) &&
    cell.row.original.id !== authUser?.attributes.sub &&
    cell.row.original.group !== UserGroup.SystemAdmin;

  return (
    <HStack>
      <Text {...(!editable && { color: 'qGray.200' })}>
        {t(`common.roles.${cell.row.original.group?.toLowerCase()}`)}
      </Text>
      {editable && <UserRoleForm currentUser={cell.row.original} />}
    </HStack>
  );
};
