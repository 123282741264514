import { Button, Tooltip } from '@chakra-ui/react';
import { type FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface FilterButtonProps {
  text: string;
  simAmount?: number;
  onClick: () => void;
  active?: boolean;
}

export const FilterButton: FunctionComponent<FilterButtonProps> = ({
  text,
  simAmount,
  onClick,
  active = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const sharedButtonProps = useMemo(
    () => ({
      minW: 'min-content',
      isDisabled: !simAmount,
      variant: active ? 'primary' : 'secondary',
      onClick,
      ...rest,
    }),
    [active, onClick, rest, simAmount],
  );

  if (simAmount) {
    return (
      <Button ml="4" {...sharedButtonProps}>
        {`${text} (${simAmount})`}
      </Button>
    );
  }

  return (
    <Tooltip label={t('sim.filterButtonDisabled')} ml="4" shouldWrapChildren tabIndex={-1}>
      <Button {...sharedButtonProps}>{text}</Button>
    </Tooltip>
  );
};
