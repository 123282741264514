import { Button } from '@chakra-ui/react';
import type { Dispatch, FunctionComponent, SetStateAction } from 'react';

interface IShowPasswordButtonProps {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
}

export const ShowSecretButton: FunctionComponent<IShowPasswordButtonProps> = ({
  show,
  setShow,
}) => (
  <Button
    size="md"
    variant="ghost"
    _focus={{ border: '0' }}
    onClick={() => setShow(!show)}
    tabIndex={-1}
  >
    {show ? 'Hide' : 'Show'}
  </Button>
);
