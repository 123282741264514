import { Box, type BoxProps } from '@chakra-ui/react';
import { forwardRef } from 'react';
import { SimSession } from 'utils/graphql/hooks';

interface SimSessionIndicatorProps extends Omit<BoxProps, 'color' | 'bgColor' | 'backgroundColor'> {
  session: SimSession;
}

export const SimSessionIndicator = forwardRef<HTMLDivElement, SimSessionIndicatorProps>(
  ({ session, boxSize = 4, ...rest }, ref) => {
    const indicatorColor = () => {
      switch (session) {
        case SimSession.Online:
          return 'qGreen.500';
        case SimSession.Offline:
          return 'red.500';
        case SimSession.Unknown:
        default:
          return 'qGray.100';
      }
    };

    return (
      <Box boxSize={boxSize} bgColor={indicatorColor()} borderRadius="100%" ref={ref} {...rest} />
    );
  },
);

// this name is used by React in debugging messages
SimSessionIndicator.displayName = 'SessionIndicator';
