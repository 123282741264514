import { Button, useDisclosure } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { TerminalModal } from './TerminalModal';

interface SSHTerminalProps {
  iccid: string;
}

export const SSH: FunctionComponent<SSHTerminalProps> = ({ iccid }) => {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button maxW="max-content" variant="secondary" onClick={onOpen}>
        {t('sim.ssh')}
      </Button>
      {isOpen && <TerminalModal openTerminal={isOpen} onClose={onClose} iccid={iccid} />}
    </>
  );
};
