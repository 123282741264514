import { Center, Flex, Heading, Link, Spinner, Text, VStack } from '@chakra-ui/react';
import { useEffect, useState, type FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { SectionStack } from 'components';
import { useRegisterSimMutation } from 'utils/graphql/hooks';
import { useIotSimToast, useSimActivationUrlParams } from 'utils/hooks';
import { routes } from 'utils/routes';

const supportEmail = process.env.REACT_APP_SUPPORT_EMAIL;

export const SimCardRegisterPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  // workaround on env !== prod to prevent second request due to strict mode
  const [isRegistering, setIsRegistering] = useState<boolean>(false);

  const { firstIccid, simAmount, hmac, type: simType } = useSimActivationUrlParams();

  const {
    mutateAsync: registerSim,
    isSuccess,
    isError,
    error,
    data: registeredSims,
  } = useRegisterSimMutation();

  useEffect(() => {
    if (isSuccess) {
      successToast({
        title: t('sim.registration.success', { count: simAmount, iccid: firstIccid }),
      });

      const openSimDetailsSearchParam =
        simAmount === 1 && registeredSims.registerSim[0]
          ? `&iccid=${registeredSims.registerSim[0]}`
          : undefined;

      navigate({
        pathname: routes.simCards,
        search: `?sortBy=endDate${openSimDetailsSearchParam}`,
      });
    }
  }, [
    isSuccess,
    t,
    successToast,
    simAmount,
    firstIccid,
    navigate,
    registeredSims?.registerSim,
    location.pathname,
    location,
  ]);

  useEffect(() => {
    if (isError) {
      let errorMessage = error.message;
      if (errorMessage.includes('Not Authorized to access')) {
        errorMessage = 'unauthorized';
      }

      errorToast({
        title: t('sim.registration.error', { count: simAmount, iccid: firstIccid }),
        description: (
          <Trans
            i18nKey={[
              `sim.registration.errorDescription.${errorMessage}`,
              `sim.registration.errorDescription.invalidUrl`,
            ]}
            values={{ email: supportEmail }}
          >
            <Link href={`mailto:${supportEmail}`} textDecoration="underline" />
          </Trans>
        ),
      });

      navigate(routes.simCards);
    }
  }, [isError, error, t, errorToast, simAmount, navigate, firstIccid]);

  useEffect(() => {
    if (firstIccid == null || isRegistering) {
      return;
    }

    setIsRegistering(true);

    registerSim({
      iccid: firstIccid,
      count: simAmount,
      mac: hmac,
      type: simType,
    });
  }, [firstIccid, simAmount, hmac, simType, registerSim, isRegistering]);

  return (
    <Center h="50vh">
      <SectionStack type="outer" mx="auto" w="lg">
        <VStack my="8" spacing="12">
          <Heading>{t('sim.registration.inProgressTitle')}</Heading>
          <Spinner size="xl" />
          <Flex direction="column" align="center">
            <Text>{t('sim.registration.inProgressDescription', { count: simAmount })}</Text>
            <Text>{t('sim.registration.inProgressRedirectMessage')}</Text>
          </Flex>
        </VStack>
      </SectionStack>
    </Center>
  );
};
