import { chakra, HStack, Input, Text, VStack } from '@chakra-ui/react';
import type { Dispatch, FunctionComponent } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Oauth, ProxyConfigInput } from '../../../../../utils/graphql/hooks';

interface ProxyConfigType {
  proxyConfigInput: ProxyConfigInput;
  setConfig: Dispatch<React.SetStateAction<ProxyConfigInput>>;
}

interface ProxyConfigProps {
  proxyConfig: ProxyConfigType;
}

type OauthKey = 'client_id' | 'client_secret' | 'scopes' | 'token_url';

export const ProxyConfig: FunctionComponent<ProxyConfigProps> = ({ proxyConfig }) => {
  const { t } = useTranslation();

  const changeOauthObj = (e: React.ChangeEvent<HTMLInputElement>, key: OauthKey) => {
    const targetOauth = {
      ...proxyConfig.proxyConfigInput.oauth,
      [key]: e.target.value,
    };
    proxyConfig.setConfig({
      ...proxyConfig.proxyConfigInput,
      oauth: targetOauth as Oauth,
    });
  };

  const commonInputProps = {
    fontSize: 'md',
    width: '17rem',
  };

  return (
    <VStack spacing={3}>
      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Text width="10rem" fontSize="xl">
          {t('config.iotConnectorProxy.oauth')}
        </Text>
      </HStack>
      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <chakra.label width="10rem" htmlFor="clientId">
          {t('config.iotConnectorProxy.oauthConfig.clientId')}
        </chakra.label>
        <Input
          placeholder={t('config.iotConnectorProxy.oauthConfig.clientId')}
          {...commonInputProps}
          onChange={(e) => {
            changeOauthObj(e, 'client_id');
          }}
          value={proxyConfig.proxyConfigInput.oauth?.client_id}
        />
      </HStack>
      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <chakra.label width="10rem" htmlFor="clientSecret">
          {t('config.iotConnectorProxy.oauthConfig.clientSecret')}
        </chakra.label>
        <Input
          placeholder={t('config.iotConnectorProxy.oauthConfig.clientSecret')}
          {...commonInputProps}
          value={proxyConfig.proxyConfigInput.oauth?.client_secret}
          onChange={(e) => {
            changeOauthObj(e, 'client_secret');
          }}
        />
      </HStack>
      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <chakra.label width="10rem" htmlFor="scopes">
          {t('config.iotConnectorProxy.oauthConfig.scopes')}
        </chakra.label>
        <Input
          placeholder={t('config.iotConnectorProxy.oauthConfig.scopes')}
          {...commonInputProps}
          value={proxyConfig.proxyConfigInput.oauth?.scopes}
          onChange={(e) => {
            changeOauthObj(e, 'scopes');
          }}
        />
      </HStack>
      <HStack width="100%" flexDirection="row" alignItems="center" justifyContent="space-between">
        <chakra.label width="10rem" htmlFor="tokenUrl">
          {t('config.iotConnectorProxy.oauthConfig.tokenUrl')}
        </chakra.label>
        <Input
          placeholder={t('config.iotConnectorProxy.oauthConfig.tokenUrl')}
          {...commonInputProps}
          value={proxyConfig.proxyConfigInput.oauth?.token_url}
          onChange={(e) => {
            changeOauthObj(e, 'token_url');
          }}
        />
      </HStack>
    </VStack>
  );
};
