/* eslint-disable react-hooks/exhaustive-deps */
import { RepeatIcon } from '@chakra-ui/icons';
import { Button, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { XTerm } from 'xterm-for-react';
import { Renderer } from './Renderer';
import type { ConnectionStatus } from './types';
import { WsStatus } from './types';
import { WebsocketWrapper } from './websocket';
import { ReactComponent as Active } from 'assets/icons/Active.svg';
import { ReactComponent as Inactive } from 'assets/icons/Inactive.svg';
import { BaseModal } from 'components';
import { useSshTicketGenerationMutation } from 'utils/graphql/hooks';

interface TerminalProps {
  iccid: string;
  openTerminal: boolean;
  onClose: () => void;
}

export const TerminalModal: FunctionComponent<TerminalProps> = ({
  iccid,
  openTerminal,
  onClose,
}) => {
  const { t } = useTranslation();
  const [ws] = useState<WebsocketWrapper>(WebsocketWrapper.getInstance());
  const { mutateAsync } = useSshTicketGenerationMutation();
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>({
    status: WsStatus.INITIAL,
    node: null,
  });
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const terminal = useRef<XTerm>(null);
  const init = useRef<boolean>(false);

  const setConnectionStatusState = useCallback((status: WsStatus) => {
    switch (status) {
      case WsStatus.SUCCESS:
        setConnectionStatus({
          status: WsStatus.SUCCESS,
          node: (
            <>
              <Active />
              <Text>{t('sim.connectionEstablished')}</Text>
            </>
          ),
        });
        break;
      case WsStatus.FAILED:
        setConnectionStatus({
          status: WsStatus.FAILED,
          node: (
            <>
              <Inactive />
              <Text>{t('sim.connectionFailed')}</Text>
            </>
          ),
        });
        break;
      case WsStatus.CLOSED:
        setConnectionStatus({
          status: WsStatus.CLOSED,
          node: (
            <>
              <Inactive />
              <Text>{t('sim.connectionClosed')}</Text>
            </>
          ),
        });
        break;
      default: // ignore
    }
  }, []);

  const establishConnection = useCallback(() => {
    ws.connect();
    const client = ws.getClient();
    if (client) {
      client.onopen = () => {
        mutateAsync({
          iccid,
        })
          .then((data) => {
            const { ticket } = data.sshTicketGeneration;
            client.send(
              JSON.stringify({
                device_ip: ticket.deviceIp,
                signature: ticket.signature,
                timestamp: ticket.timeStamp,
              }),
            );
          })
          .catch(() => {
            setConnectionStatusState(WsStatus.FAILED);
            ws.setForceClose(true);
            ws.closeSocket();
          });
      };
    }
  }, []);

  const setUpCallbacks = useCallback((refresh: boolean = false) => {
    const client = ws.getClient();
    if (client) {
      client.onclose = () => {
        if (ws.getForceClose()) {
          setConnectionStatusState(WsStatus.FAILED);
          ws.setForceClose(false);
        } else {
          setConnectionStatusState(WsStatus.CLOSED);
        }
      };

      client.onmessage = (event) => {
        if (!ws.getAuthenticated()) {
          try {
            const resp = JSON.parse(event.data);
            if (!resp.authenticated) {
              ws.setForceClose(true);
              ws.closeSocket();

              return;
            }
            setConnectionStatusState(WsStatus.SUCCESS);
            ws.setAuthenticated(true);
          } catch (err) {
            // ignore error
          }
        }
        let strEnv = '';
        if (typeof event.data === 'string') {
          strEnv = event.data;
        }

        if (refresh) {
          strEnv = strEnv.replace('{"authenticated":true}', '\n');
        }

        // remove last escape sequence [?2004l from the received message
        const endStr = strEnv.replace('[?2004l', '').trim();
        if (endStr.includes('logout') && endStr.length <= 8) {
          ws.closeSocket();
        }

        if (terminal.current) {
          terminal.current.terminal.write(strEnv);
          setIsRefreshing(false);
        }
      };

      client.onerror = () => {
        setConnectionStatusState(WsStatus.FAILED);
      };
    }
  }, []);

  useEffect(() => {
    if (!init.current) {
      if (connectionStatus.status === WsStatus.INITIAL) {
        establishConnection();
        setUpCallbacks();
      }
      init.current = true;
    }
  }, [connectionStatus.status]);

  return (
    <BaseModal
      size={['xs', 'md', '2xl', '3xl']}
      isOpen={openTerminal}
      header={
        <VStack>
          <Text fontSize="xl">{t('sim.ssh')}</Text>
          <HStack justifyContent="center" alignItems="center">
            <HStack>{connectionStatus.node}</HStack>
            {connectionStatus.status === WsStatus.CLOSED && (
              <IconButton
                aria-label="Refresh socket connection"
                size="xs"
                isRound
                isLoading={isRefreshing}
                onClick={() => {
                  setIsRefreshing(true);
                  establishConnection();
                  setUpCallbacks(true);
                }}
                icon={<RepeatIcon />}
              />
            )}
          </HStack>
        </VStack>
      }
      footer={
        <Button
          onClick={() => {
            ws.closeSocket();
            onClose();
          }}
        >
          {t('common.close')}
        </Button>
      }
      onClose={onClose}
    >
      <Renderer
        conStatus={connectionStatus}
        ws={ws}
        funcs={{
          establish: establishConnection,
          callbacks: setUpCallbacks,
        }}
        terminal={terminal}
      />
    </BaseModal>
  );
};
