import { getAccessToken, getConfig } from 'utils';

export const fetchParams = async () => {
  const headers: { [headerName: string]: string } = {
    'Content-Type': 'application/json', // msw needs content type set for intercepting GraphQL requests
  };

  const accessToken = await getAccessToken();

  if (accessToken) {
    headers.Authorization = `Bearer ${accessToken}`;
  } else {
    const config = await getConfig();

    // Only endpoints with @aws_api_key annotation in the GraphQL schema within the Infra repo can be accessed with it
    // see: https://git.infra.cospace.de/iot-sim/infrastructure/-/blob/master/modules/portal-api/schema.graphql
    headers['x-api-key'] = config.publicApiKey;
  }

  return { headers };
};

export const endpointUrl = async () => {
  const config = await getConfig();

  return config.apiEndpoint;
};
