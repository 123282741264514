import type { FlexProps } from '@chakra-ui/react';
import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import type { FunctionComponent, PropsWithChildren } from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SettingsIcon } from 'assets/icons/Settings.svg';

const MORE_COLUMNS_BUTTON_TEXT = 'moreColumnsButtonText';

export const ColumnConfigPopover: FunctionComponent<PropsWithChildren<FlexProps>> = ({
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  const [isColumnConfigOpen, setIsColumnConfigOpen] = useState<boolean>(false);

  const onPopoverTriggerClick = useCallback(
    () => setIsColumnConfigOpen(!isColumnConfigOpen),
    [isColumnConfigOpen, setIsColumnConfigOpen],
  );
  const onPopoverClose = useCallback(() => setIsColumnConfigOpen(false), [setIsColumnConfigOpen]);

  return (
    <Flex justify="flex-end" {...rest}>
      <Popover placement="bottom-end" isOpen={isColumnConfigOpen} onClose={onPopoverClose}>
        <PopoverTrigger>
          <Button
            variant="ghost"
            my="6"
            pl="2"
            pr="1"
            color={isColumnConfigOpen ? 'brand.500' : 'inherit'}
            onClick={onPopoverTriggerClick}
          >
            <Text id={MORE_COLUMNS_BUTTON_TEXT} pr="1">
              {t('sim.configColumns')}
            </Text>
            <SettingsIcon aria-labelledby={MORE_COLUMNS_BUTTON_TEXT} />
          </Button>
        </PopoverTrigger>
        <PopoverContent w="48" mr="2">
          <Flex as={PopoverBody} direction="column" gap="2" p="5">
            {children}
          </Flex>
        </PopoverContent>
      </Popover>
    </Flex>
  );
};
