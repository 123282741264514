import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  type FunctionComponent,
  type PropsWithChildren,
} from 'react';
import { type Sim } from 'utils/graphql/hooks';

interface SelectedSimCardsMap extends Map<Sim['iccid'], Sim> {}

export interface SelectedSimCardsContextValues {
  selectedSimCardsMap: SelectedSimCardsMap;
  addSelectedSimCards: (sims: Sim[]) => void;
  updateSelectedSimCard: (sim: Sim) => void;
  removeSelectedSimCards: (sims: Sim[]) => void;
  clearSelectedSimCards: () => void;
  selectedIccids: string[];
  selectedSimCards: Sim[];
}

export const SelectedSimCardsContext = createContext<SelectedSimCardsContextValues>({
  selectedSimCardsMap: new Map(),
  addSelectedSimCards: () => {},
  updateSelectedSimCard: () => {},
  removeSelectedSimCards: () => {},
  clearSelectedSimCards: () => {},
  selectedIccids: [],
  selectedSimCards: [],
});

export const useSelectedSimCardsContext = () => useContext(SelectedSimCardsContext);

export const SelectedSimCardProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [selectedSimCardsMap, setSelectedSimCardsMap] = useState<SelectedSimCardsMap>(new Map());

  const addSelectedSimCards = useCallback((sims: Sim[]) => {
    setSelectedSimCardsMap(
      (prev) =>
        new Map(
          [...prev, ...new Map(sims.map((sim) => [sim.iccid, sim]))].sort(([a], [b]) =>
            a.localeCompare(b),
          ),
        ),
    );
  }, []);

  const removeSelectedSimCards = useCallback((sims: Sim[]) => {
    setSelectedSimCardsMap((prev) => {
      if (sims.length === 1) {
        prev.delete(sims[0].iccid);

        return new Map(prev);
      }

      return new Map([...prev].filter(([key]) => !sims.map((sim) => sim.iccid).includes(key)));
    });
  }, []);

  const updateSelectedSimCard = useCallback((sim: Sim) => {
    setSelectedSimCardsMap((prev) => {
      if (prev.has(sim.iccid)) {
        prev.set(sim.iccid, { ...prev.get(sim.iccid), ...sim });
      }

      return new Map(prev);
    });
  }, []);

  const clearSelectedSimCards = useCallback(() => setSelectedSimCardsMap(new Map()), []);

  const contextValue = useMemo(
    () => ({
      selectedSimCardsMap,
      addSelectedSimCards,
      updateSelectedSimCard,
      removeSelectedSimCards,
      clearSelectedSimCards,
      selectedIccids: [...selectedSimCardsMap.keys()],
      selectedSimCards: [...selectedSimCardsMap.values()],
    }),
    [
      addSelectedSimCards,
      updateSelectedSimCard,
      clearSelectedSimCards,
      removeSelectedSimCards,
      selectedSimCardsMap,
    ],
  );

  return (
    <SelectedSimCardsContext.Provider value={contextValue}>
      {children}
    </SelectedSimCardsContext.Provider>
  );
};
