export const sessionTimeoutSeconds = 540;
export class WebsocketWrapper {
  private static instance: WebsocketWrapper;

  private authenticated: boolean = false;

  private forceClose: boolean = false;

  private lastSentMsgTimestamp: number = -1;

  private client: WebSocket | undefined;

  // eslint-disable-next-line no-empty-function
  private constructor() {}

  public static getInstance(): WebsocketWrapper {
    if (!WebsocketWrapper.instance) {
      WebsocketWrapper.instance = new WebsocketWrapper();
    }

    return WebsocketWrapper.instance;
  }

  public connect() {
    const env = process.env.REACT_APP_ENVIRONMENT;
    let URL = process.env.REACT_APP_IOT_CONNECTOR_DEVICES_WEBSOCKET_URL_DEV;
    if (env?.length && env !== 'dev' && !env.includes('local-')) {
      URL = process.env.REACT_APP_IOT_CONNECTOR_DEVICES_WEBSOCKET_URL_PROD_TEST;
    }
    this.client = new WebSocket(URL as string);
  }

  public setAuthenticated(value: boolean) {
    this.authenticated = value;
  }

  public getAuthenticated(): boolean {
    return this.authenticated;
  }

  public getClient(): WebSocket | undefined {
    return this.client;
  }

  public setClient(ws: WebSocket) {
    this.client = ws;
  }

  public setForceClose(value: boolean) {
    this.forceClose = value;
  }

  public getForceClose(): boolean {
    return this.forceClose;
  }

  public setLastSentMsgTimestamp(value: number) {
    this.lastSentMsgTimestamp = value;
  }

  public getLastSentMsgTimestamp(): number {
    return this.lastSentMsgTimestamp;
  }

  public checkSessionTimeOut(): boolean {
    const diff = Date.now() - this.lastSentMsgTimestamp;

    return diff >= 540;
  }

  public closeSocket() {
    try {
      if (this.authenticated) {
        this.client?.close();
        this.authenticated = false;
      }
    } catch (e) {
      // ignore error
    }
  }
}
