import { GridItem, type GridItemProps } from '@chakra-ui/react';
import type { FunctionComponent, PropsWithChildren } from 'react';

const labelColSpan = [2, null, null, 1];
const inputColSpan = [4, null, null, 5];

interface WireGuardFormGridItemProps extends GridItemProps {
  type: 'label' | 'input';
}

export const WireGuardFormGridItem: FunctionComponent<
  PropsWithChildren<WireGuardFormGridItemProps>
> = ({ type, children, ...rest }) => (
  <GridItem colSpan={type === 'label' ? labelColSpan : inputColSpan} {...rest}>
    {children}
  </GridItem>
);
