import type { ReactNode } from 'react';

export enum WsStatus {
  INITIAL,
  SUCCESS,
  FAILED,
  CLOSED,
}

export interface WsFuncs {
  establish: () => void;
  callbacks: (arg0: boolean) => void;
}

export interface ConnectionStatus {
  status: WsStatus;
  node: ReactNode;
}
