import { Input, Stack, VStack, chakra, Button, Text, useDisclosure } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { SimulateProxy } from './SimulateProxy';
import { SectionStack } from 'components';
import { isValidHttpUrl } from 'utils';
import { useUpdateIotConnectorGroupMutation } from 'utils/graphql/hooks';
import type { Header } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

const TARGET_URL_INPUT_ID = 'targetUrlInput';

interface IotConnectorProxyFormData {
  targetUrl: string;
}

interface IotConnectorProxyFormProps {
  defaultGroupId: string;
  initialTargetUrl: string;
  httpHeaders?: Header[];
}

export const IotConnectorProxyForm: FunctionComponent<IotConnectorProxyFormProps> = ({
  defaultGroupId,
  initialTargetUrl,
  httpHeaders,
}) => {
  const { t } = useTranslation();
  const { readonlyProps, readonlyDisabledProp, readonlyTitleProp } = useAuthUtils();

  const {
    register,
    handleSubmit,
    watch,

    formState: { isDirty, errors },
    reset,
  } = useForm<IotConnectorProxyFormData>({
    defaultValues: {
      targetUrl: initialTargetUrl,
    },
    mode: 'onChange',
  });

  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const { mutateAsync, isPending } = useUpdateIotConnectorGroupMutation();

  const currentTargetUrl = watch('targetUrl');

  const onSubmit = handleSubmit(async ({ targetUrl }) => {
    try {
      const result = await mutateAsync({
        groupId: defaultGroupId,
        general: { proxyConfig: { targetUrl } },
      });

      reset({ targetUrl: result.updateIotConnectorGroup.proxy.targetUrl || '' });

      successToast({ title: t('common.updateAttributesSuccess') });
    } catch (e) {
      errorToast(
        e instanceof Error
          ? { title: t('common.updateAttributesError'), description: e.message }
          : { title: t('error.generic') },
      );
    }
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <chakra.form onSubmit={onSubmit}>
      <SectionStack type="inner" py="7" px={['5', '10']} spacing="5">
        <VStack alignItems="normal">
          <Stack
            spacing="4"
            direction={['column', null, 'row']}
            alignItems={['stretch', null, 'center']}
          >
            <chakra.label htmlFor={TARGET_URL_INPUT_ID} fontWeight="bold" fontSize="lg">
              {t('config.iotConnectorProxy.targetUrl')}
            </chakra.label>

            <Input
              id={TARGET_URL_INPUT_ID}
              isDisabled={isPending || readonlyDisabledProp}
              title={readonlyTitleProp}
              placeholder="https://..."
              variant="userData"
              size="sm"
              {...register('targetUrl', {
                validate: (value) => isValidHttpUrl(value) || value === '',
              })}
              flex={[null, null, 1]}
            />

            <Button
              type="submit"
              isLoading={isPending}
              isDisabled={!isDirty || !!errors.targetUrl || readonlyDisabledProp}
              title={readonlyTitleProp}
            >
              {t('common.apply')}
            </Button>
          </Stack>

          <Text>{t('config.iotConnectorProxy.targetUrlDescription')}</Text>

          <Stack
            spacing="4"
            direction={['column', null, 'row']}
            alignItems={['stretch', null, 'center']}
            justifyContent={['stretch', null, 'space-between']}
          >
            <Text pt="4">
              {t('config.iotConnectorProxy.examplePreviewText')}{' '}
              {!errors.targetUrl && currentTargetUrl ? (
                <Trans
                  i18nKey="config.iotConnectorProxy.examplePreview"
                  values={{
                    targetUrl: currentTargetUrl,
                    proxyBaseUrl: process.env.REACT_APP_IOT_CONNECTOR_PROXY_BASE_URL,
                  }}
                />
              ) : (
                <chakra.i>{t('config.iotConnectorProxy.enterTargetUrlForPreview')}</chakra.i>
              )}
            </Text>
            <Button onClick={onOpen} {...readonlyProps}>
              {t('config.iotConnectorProxy.simulate')}
            </Button>
            {isOpen && (
              <SimulateProxy
                openSimulateProxy={isOpen}
                onClose={onClose}
                targetUrl={currentTargetUrl}
                httpHeaders={httpHeaders as Header[]}
              />
            )}
          </Stack>
        </VStack>
      </SectionStack>
    </chakra.form>
  );
};
