import {
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  type ModalProps,
} from '@chakra-ui/react';
import { useEffect, type FunctionComponent, type PropsWithChildren, type ReactNode } from 'react';
import { SectionStack } from 'components/SectionStack';
import { useBlurContext } from 'utils/provider/BlurProvider';

interface BaseModalProps extends ModalProps {
  header?: ReactNode;
  footer?: ReactNode;
}

export const BaseModal: FunctionComponent<PropsWithChildren<BaseModalProps>> = ({
  header,
  footer,
  isOpen,
  children,
  ...rest
}) => {
  const { setIsBlurred } = useBlurContext();

  useEffect(() => {
    setIsBlurred(isOpen);

    // on some cases, the modal is closing without isOpen changes, e.g. on a redirect
    return () => setIsBlurred(false);
  }, [isOpen, setIsBlurred]);

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={false} {...rest}>
      <ModalOverlay />
      <SectionStack as={ModalContent} type="outer">
        {header && (
          <ModalHeader>
            <Center>{header}</Center>
          </ModalHeader>
        )}
        <ModalBody>{children}</ModalBody>
        {footer && (
          <ModalFooter>
            <HStack w="full" justify="center" spacing="4">
              {footer}
            </HStack>
          </ModalFooter>
        )}
      </SectionStack>
    </Modal>
  );
};
