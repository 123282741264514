import type { CenterProps } from '@chakra-ui/react';
import { Center } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

interface EmptyTableMessageProps extends CenterProps {
  text?: string;
}

export const EmptyTableMessage: FunctionComponent<EmptyTableMessageProps> = ({ text, ...rest }) => {
  const { t } = useTranslation();

  return (
    <Center py="16" {...rest}>
      {text ?? t('error.emptyTable')}
    </Center>
  );
};
