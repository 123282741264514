import {
  type AuthUser as AmplifyAuthUser,
  type UserAttributeKey,
  fetchAuthSession,
  fetchUserAttributes,
} from '@aws-amplify/auth';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useQueryClient } from '@tanstack/react-query';
import { Hub } from 'aws-amplify/utils';
import type { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { UserGroup } from 'utils/graphql/hooks';

const userGroupSet = new Set(Object.values<string>(UserGroup));

const handleCognitoGroups = async (forceRefreshSession = false) => {
  const { tokens } = await fetchAuthSession({ forceRefresh: forceRefreshSession });
  const groups = tokens?.idToken?.payload?.['cognito:groups'];

  let companyId: string | undefined;
  let userGroup: UserGroup | undefined;
  let multipleUserGroups = false;

  // user can only have two groups in total (one contains the companyId)
  if (Array.isArray(groups) && groups.length <= 2) {
    groups.forEach((group) => {
      if (typeof group === 'string') {
        if (group.startsWith('COMPANY:')) {
          // eslint-disable-next-line prefer-destructuring
          companyId = group.split(':')[1];

          return;
        }

        // only one cognito group besides the companyId group
        if (userGroup) {
          multipleUserGroups = true;

          return;
        }

        // cognito returns groups in upper snake case, enum values generated by GraphQL schema are lower snake case
        const cognitoGroup = group.toLowerCase();
        if (userGroupSet.has(cognitoGroup)) {
          userGroup = cognitoGroup as UserGroup;
        }
      }
    });
  }

  return { companyId, userGroup: multipleUserGroups ? undefined : userGroup };
};

type AuthUserAttributes = Partial<Record<UserAttributeKey, string | undefined>>;

export interface AuthUser extends Omit<AmplifyAuthUser, 'username' | 'userId'> {
  userId?: string;
  username?: string;
  attributes: AuthUserAttributes;
  group?: UserGroup;
  companyId?: string;
}

export interface AuthUserContextContentValues {
  authUser: AuthUser | undefined;
  setAuthUser: Dispatch<SetStateAction<AuthUser | undefined>>;
  isLoading: boolean;
  hasRequiredRole: (requiredRole: UserGroup) => boolean;
  refetchUser: (forceRefreshSession?: boolean) => Promise<void>;
}

export const AuthUserContext = createContext<AuthUserContextContentValues>({
  authUser: undefined,
  setAuthUser: () => {},
  isLoading: true,
  hasRequiredRole: () => false,
  refetchUser: async () => {},
});

export const useAuthUserContext = () => useContext(AuthUserContext);

export const AuthUserProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const queryClient = useQueryClient();
  const { authStatus, user: signedInUser } = useAuthenticator((context) => [context.user]);

  const [authUser, setAuthUser] = useState<AuthUser>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const refetchUser = useCallback(
    async (forceRefreshSession = false) => {
      if (authStatus === 'authenticated') {
        setIsLoading(true);

        const attributes = await fetchUserAttributes();
        const { companyId, userGroup } = await handleCognitoGroups(forceRefreshSession);

        setAuthUser({
          userId: signedInUser.userId,
          username: signedInUser.username,
          group: userGroup,
          attributes,
          companyId,
        });
        setIsLoading(false);
      }
    },
    [authStatus, signedInUser.userId, signedInUser.username],
  );

  const hasRequiredRole = useCallback(
    (requiredRole: UserGroup) => {
      if (!authUser?.group) {
        return false;
      }

      // UserGroup enum is ordered alphabetical and can not be taken as source
      const orderedGroups: string[] = [
        'system_admin',
        'company_owner',
        'company_admin',
        'system_user',
        'company_user',
      ];

      return orderedGroups.indexOf(authUser.group) <= orderedGroups.indexOf(requiredRole);
    },
    [authUser?.group],
  );

  useEffect(() => {
    (async () => refetchUser())();
  }, [refetchUser]);

  useEffect(() => {
    // docs: https://docs.amplify.aws/react/build-a-backend/auth/auth-events/
    const stopListen = Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedOut':
        case 'tokenRefresh_failure':
          queryClient.clear();
          break;
        default:
          break;
      }
    });

    return () => stopListen();
  }, [queryClient]);

  const contextValue = useMemo(
    () => ({ authUser, setAuthUser, hasRequiredRole, isLoading, refetchUser }),
    [authUser, hasRequiredRole, isLoading, refetchUser],
  );

  return <AuthUserContext.Provider value={contextValue}>{children}</AuthUserContext.Provider>;
};
