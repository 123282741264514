import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  type UseDisclosureProps,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import type { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseModal, InputContainer } from 'components';
import {
  useUpdateUserAttributesMutation,
  type UpdateUserAttributesMutationVariables,
  useUserListQuery,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

const formId = 'editUserAttributesForm';

export const EditUserAttributesModal: FunctionComponent<UseDisclosureProps> = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const { authUser, setAuthUser } = useAuthUserContext();
  const { mutateAsync } = useUpdateUserAttributesMutation();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateUserAttributesMutationVariables>({
    defaultValues: {
      givenName: authUser?.attributes.given_name || '',
      familyName: authUser?.attributes.family_name || '',
    },
  });

  const onModalClose = () => {
    onClose();
    reset();
  };

  const onSubmit = handleSubmit(async ({ givenName, familyName }) => {
    try {
      const givenNameToSubmit = givenName?.trim();
      const familyNameToSubmit = familyName?.trim();

      await mutateAsync({
        givenName: givenNameToSubmit,
        familyName: familyNameToSubmit,
      });

      successToast({ title: t('common.updateAttributesSuccess') });

      setAuthUser((current) => ({
        ...current,
        attributes: {
          ...current?.attributes,
          given_name: givenNameToSubmit,
          family_name: familyNameToSubmit,
        },
      }));
      await queryClient.invalidateQueries({ queryKey: useUserListQuery.getKey() });
      onClose();
    } catch (error) {
      errorToast({
        title: (error instanceof Error && t('common.updateAttributesError')) || t('error.generic'),
        ...(error instanceof Error && { description: error.message }),
      });
    }
  });

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onModalClose}
      header={t('user.editData')}
      footer={
        <>
          <Button variant="secondary" onClick={onModalClose}>
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            form={formId}
            isLoading={isSubmitting}
            loadingText={t('common.saving')}
          >
            {t('common.saveChanges')}
          </Button>
        </>
      }
    >
      <form onSubmit={onSubmit} id={formId}>
        <Flex direction="column">
          <InputContainer>
            <FormControl isInvalid={!!errors.givenName}>
              <FormLabel>{t('user.firstName')}</FormLabel>
              <Input
                {...register('givenName', {
                  validate: (value) => (value?.trim() ? true : t('user.error.firstNameRequired')),
                })}
                size="sm"
                variant="userData"
              />
              <FormErrorMessage whiteSpace="pre-line">{errors.givenName?.message}</FormErrorMessage>
            </FormControl>
          </InputContainer>

          <InputContainer>
            <FormControl isInvalid={!!errors.familyName}>
              <FormLabel>{t('user.lastName')}</FormLabel>
              <Input
                {...register('familyName', {
                  validate: (value) => (value?.trim() ? true : t('user.error.lastNameRequired')),
                })}
                size="sm"
                variant="userData"
              />
              <FormErrorMessage whiteSpace="pre-line">
                {errors.familyName?.message}
              </FormErrorMessage>
            </FormControl>
          </InputContainer>
        </Flex>
      </form>
    </BaseModal>
  );
};
