import { Box, Heading, Text, VStack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { MoreInfoText } from '../components/MoreInfoText';
import { HttpHeaderSection, IotConnectorProxyForm } from './components';
import { OauthSection } from './components/OauthSection/OauthSection';
import { SectionError, SectionLoading, SectionStack } from 'components';
import { useGetIotConnectorGroupsQuery } from 'utils/graphql/hooks';
import { routes } from 'utils/routes';

export const IotConnectorProxyConfigurationPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const { data, isLoading, isError, error } = useGetIotConnectorGroupsQuery();

  return (
    <VStack alignItems="stretch">
      <SectionStack type="outer">
        <VStack alignItems="normal" py={[4, 7]} px={[5, 10]}>
          <Heading size="lg">{t('config.iotConnectorProxy.heading')}</Heading>

          <Text>{t('config.iotConnectorProxy.proxyDescription')}</Text>

          <Text>{t('config.iotConnectorProxy.technicalProxyDescription')}</Text>

          <MoreInfoText link={routes.external.devHub.connector} />
        </VStack>

        {isLoading && <SectionLoading />}
        {isError && <SectionError title={t('config.error.query')} message={error.message} />}

        {data && (
          <IotConnectorProxyForm
            defaultGroupId={data.getIotConnectorGroups.defaultGroup.id}
            initialTargetUrl={data.getIotConnectorGroups.defaultGroup.proxy.targetUrl || ''}
            httpHeaders={data.getIotConnectorGroups.defaultGroup.proxy.headers}
          />
        )}

        {data && (
          <Box py={[4, 7]} px={[5, 10]}>
            <OauthSection
              groupId={data.getIotConnectorGroups.defaultGroup.id}
              tokenUrl={data.getIotConnectorGroups.defaultGroup.proxy.oauth2?.tokenUrl || ''}
            />
          </Box>
        )}

        {data && (
          <Box py={[4, 7]} px={[5, 10]}>
            <HttpHeaderSection
              httpHeaders={data.getIotConnectorGroups.defaultGroup.proxy.headers}
              maxHttpHeaderCount={data.getIotConnectorGroups.maxHttpHeaderCount}
              defaultGroupId={data.getIotConnectorGroups.defaultGroup.id}
            />
          </Box>
        )}
      </SectionStack>
    </VStack>
  );
};
