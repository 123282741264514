import { fetchAuthSession } from '@aws-amplify/auth';
import type { QueryClient, QueryKey } from '@tanstack/react-query';
import i18next from 'i18next';
import type { Location } from 'react-router-dom';
import { progressOfMonth } from './dateUtils';

export interface LocationState {
  from: Partial<Location> | undefined;
  email: string | undefined;
}

export interface Configuration {
  userpoolId: string;
  userpoolClientId: string;
  apiEndpoint: string;
  publicApiKey: string;
}

let globalConfig: Promise<Configuration> | undefined;

export const getConfig = async (): Promise<Configuration> => {
  if (!globalConfig) {
    const response = await fetch('/config.json');

    const contentType = response.headers.get('content-type');

    if (!contentType?.includes('application/json')) {
      throw new Error('Could not load configuration');
    }

    globalConfig = response.json();
  }

  return globalConfig;
};

export const getAccessToken = async () => {
  try {
    const session = await fetchAuthSession();

    return session.tokens?.accessToken;
  } catch {
    // this simply means the user is not logged in
    return undefined;
  }
};

export const melitaIccidPrefix = '893567';

export const InputValidation = {
  code: /^\d{6}$/,
  email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  password: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/,
  iccid: /^\d{19}$/,
  imsi: /^\d{14,15}$/,
};

const luhnChecksum = (code: string) => {
  const { length } = code;
  const parity = length % 2;
  let sum = 0;

  for (let i = length - 1; i >= 0; i--) {
    let d = parseInt(code.charAt(i), 10);
    if (i % 2 === parity) {
      d *= 2;
    }
    if (d > 9) {
      d -= 9;
    }
    sum += d;
  }

  return sum % 10;
};

/**
 * calculates a full code with checksum by receiving a partcode
 */
export const luhnCalculate = (partcode: string) => {
  const checksum = luhnChecksum(`${partcode}0`);
  const checkDigit = checksum === 0 ? 0 : 10 - checksum;

  return partcode + checkDigit;
};

/**
 * validates the checksum of a code with checkdigit
 */
export const luhnValidate = (fullcode: string) => luhnChecksum(fullcode) === 0;

export const getFromLocalStorage = (key: string) => {
  const storageContent = localStorage.getItem(key);

  if (!storageContent) {
    return null;
  }

  return JSON.parse(storageContent);
};

export const setToLocalStorage = (key: string, value: unknown) =>
  localStorage.setItem(key, JSON.stringify(value));

export const resetPaginationPages = <T extends { pages: T[]; pageParams: [] }>(
  queryClient: QueryClient,
  queryKey: QueryKey,
) =>
  queryClient.setQueryData<T | undefined>(queryKey, (queryData) =>
    queryData
      ? {
          ...queryData,
          pages: [queryData?.pages[0]],
          done: true,
        }
      : undefined,
  );

export const byteToGigaByte = (byte: number) => parseFloat((byte / 1024 / 1024 / 1024).toFixed(2));

export const isValidHttpUrl = (url: string) => {
  try {
    const parsedUrl = new URL(url);

    return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
  } catch {
    return false;
  }
};

export const displayAsCurrency = (value: number) =>
  Intl.NumberFormat(i18next.language, { style: 'currency', currency: 'EUR' }).format(value);

export const priceUntilEndOfMonth = (fullPrice: number, dateTimeOverwrite?: Date) => {
  const now = dateTimeOverwrite || new Date();

  const proratedPrice = fullPrice * (1 - progressOfMonth(now, true));

  return proratedPrice;
};

/**
 * Downloads the given text as a .txt file with the specified filename.
 *
 * @param text - The text content to be downloaded.
 * @param filename - The name of the file to be downloaded, `.txt` will be added if not present.
 */
export const downloadTextAsTxtFile = (text: string, filename: string) => {
  const blob = new Blob([text], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename.endsWith('.txt') ? filename : `${filename}.txt`;
  link.click();
  URL.revokeObjectURL(url);
};
