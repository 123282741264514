/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Text, useDisclosure, VStack } from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XTerm } from 'xterm-for-react';
import { BaseModal } from '../../../../../../../../../components';
import type { WsFuncs } from '../types';
import type { WebsocketWrapper } from '../websocket';
import { sessionTimeoutSeconds } from '../websocket';

interface TerminalProps {
  ws: WebsocketWrapper;
  connect: WsFuncs;
  terminal: React.MutableRefObject<XTerm | null>;
}

export const TerminalX: FunctionComponent<TerminalProps> = ({ ws, connect, terminal }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [startCounter, setStartCounter] = useState<boolean>(false);
  const [counter, setCounter] = useState<number>(60);
  const counterRef = useRef<number>(0);
  const { t } = useTranslation();
  const [opened, setOpened] = useState<boolean>(false);
  const terminalRef = useRef<HTMLDivElement>(null);

  // check session timeout after 9 minutes
  useEffect(() => {
    const sessionInterval = window.setInterval(() => {
      if (ws.checkSessionTimeOut() || ws.getLastSentMsgTimestamp() === -1) {
        onOpen();
        setStartCounter(true);
      }
    }, sessionTimeoutSeconds * 1000);

    return () => window.clearInterval(sessionInterval);
  }, []);

  // update counterRef, which holds the new value of counter
  // we use counterRef, because setInterval holds always the start value of counter and does not change with every setCounter Call
  useEffect(() => {
    counterRef.current = counter;
  });

  // 1 minute counter for session refresh
  useEffect(() => {
    let oneMinuteCounter: number;
    if (startCounter) {
      oneMinuteCounter = window.setInterval(() => {
        if (counterRef.current < 2) {
          ws.closeSocket();
          onClose();
          setStartCounter(false);
          setCounter(60);
        } else {
          setCounter(counterRef.current - 1);
        }
      }, 1000);
    }

    return () => window.clearInterval(oneMinuteCounter);
  }, [startCounter]);

  useEffect(() => {
    if (!opened) {
      if (terminalRef.current !== null) {
        if (terminal.current === null) {
          // eslint-disable-next-line no-param-reassign
          terminal.current = new XTerm({
            options: {
              cursorBlink: true,
              cursorStyle: 'bar',
              cursorWidth: 1,
              fontSize: 13,
              theme: {
                foreground: 'white',
                background: 'black',
                cursor: 'white',
                selectionForeground: 'white',
              },
            },
            className: 'xterm-id',
          });
          terminal.current.terminal.onKey((event) => {
            event.domEvent.preventDefault();
            if (ws.getAuthenticated()) {
              ws.getClient()?.send(event.key);
              ws.setLastSentMsgTimestamp(Date.now());
            }
          });
          terminal.current.terminal.attachCustomKeyEventHandler((event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
              navigator.clipboard.readText().then((clipText) => {
                if (ws.getAuthenticated()) {
                  ws.getClient()?.send(clipText);
                  ws.setLastSentMsgTimestamp(Date.now());
                }
              });
            }

            return true;
          });

          terminal.current.terminal.open(terminalRef.current);
          terminal.current.terminal.focus();
        }
        setOpened(true);
      }
    }
  }, [opened]);

  return (
    <>
      <Box ref={terminalRef} id="xterm-id" />
      {isOpen && (
        <BaseModal size={['xs']} isOpen={isOpen} onClose={onClose}>
          <VStack>
            <Text>{t('sim.sshSessionTimeoutWarning')}</Text>
            <Text fontWeight="bold">{counter}</Text>
            <Button
              onClick={() => {
                ws.closeSocket();
                connect.establish();
                connect.callbacks(true);
                onClose();
                setStartCounter(false);
                setCounter(60);
              }}
            >
              {t('sim.sshSessionExtend')}
            </Button>
          </VStack>
        </BaseModal>
      )}
    </>
  );
};
