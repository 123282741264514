import { Checkbox } from '@chakra-ui/react';
import { type CellContext } from '@tanstack/react-table';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BlockClickEventPropagation } from 'components/BlockClickEventPropagation';
import { type Sim } from 'utils/graphql/hooks';
import { useSelectedSimCardsContext } from 'utils/provider/SelectedSimProvider';

export const SimSelectRowCell: FunctionComponent<CellContext<Sim, unknown>> = ({ row }) => {
  const { selectedSimCardsMap, addSelectedSimCards, removeSelectedSimCards } =
    useSelectedSimCardsContext();
  const { t } = useTranslation();

  return (
    <BlockClickEventPropagation>
      <Checkbox
        title={t('common.toggleRowSelected')}
        key={row.id}
        isChecked={row.getIsSelected()}
        p="1.25rem 0.5rem"
        w="full"
        onChange={() => {
          selectedSimCardsMap.has(row.original.iccid)
            ? removeSelectedSimCards([row.original])
            : addSelectedSimCards([row.original]);
        }}
      />
    </BlockClickEventPropagation>
  );
};
