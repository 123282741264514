import type { ReactNode } from 'react';
import { ReactComponent as AccountIcon } from 'assets/icons/Account.svg';
import { ReactComponent as ConfigurationsIcons } from 'assets/icons/Configurations.svg';
import { ReactComponent as SimIcon } from 'assets/icons/SIM.svg';
import { ReactComponent as UserIcon } from 'assets/icons/User.svg';
import { routes } from 'utils/routes/index';

interface MenuEntry {
  href: string;
  text: string;
  icon: ReactNode;
  isExternal?: boolean;
}

export const menuEntries: MenuEntry[] = [
  { href: routes.simCards, text: 'common.simCard_other', icon: <SimIcon /> },
  {
    href: routes.config.root,
    text: 'config.configurations',
    icon: <ConfigurationsIcons />,
  },
  { href: routes.users, text: 'user.users', icon: <UserIcon /> },
];

export const mobileMenuEntries: MenuEntry[] = [
  ...menuEntries,
  { href: routes.account.root, text: 'account.accountAndSupport', icon: <AccountIcon /> },
];
