import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Stack,
  type FormLabelProps,
  type UseDisclosureProps,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { type FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { BaseModal, UserRoleRadioGroup } from 'components';
import { InputValidation } from 'utils';
import {
  useInviteUserMutation,
  UserGroup,
  type InviteUserMutationVariables,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';

const formId = 'addUserForm';
const formLabelProps: FormLabelProps = { minW: { base: '16', sm: '24' }, mb: '0' };

export const AddUserModal: FunctionComponent<UseDisclosureProps> = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const { mutateAsync, isPending } = useInviteUserMutation();
  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });
  const queryClient = useQueryClient();

  const onModalClose = () => {
    onClose();
    reset();
  };

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<InviteUserMutationVariables>({
    defaultValues: { givenName: '', familyName: '', email: '', group: UserGroup.CompanyUser },
  });

  const onSubmit = handleSubmit(async ({ givenName, familyName, email, group }) => {
    try {
      const { inviteUser } = await mutateAsync({
        givenName: givenName.trim(),
        familyName: familyName.trim(),
        email,
        group,
      });

      if (inviteUser) {
        successToast({ title: t('user.inviteSuccess', { email: inviteUser.email }) });
        await queryClient.refetchQueries({ queryKey: ['UserList'] });
        onModalClose();
      } else {
        errorToast({ title: t('user.inviteError') });
      }
    } catch (error) {
      errorToast({ title: (error instanceof Error && error.message) || t('error.generic') });
    }
  });

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onModalClose}
      header={t('user.addNew')}
      footer={
        <>
          <Button variant="secondary" onClick={onModalClose}>
            {t('common.cancel')}
          </Button>
          <Button
            type="submit"
            form={formId}
            isLoading={isSubmitting || isPending}
            loadingText={t('user.inviting')}
          >
            {t('user.invite')}
          </Button>
        </>
      }
    >
      <Stack as="form" spacing="4" px={{ sm: '8' }} py="6" id={formId} onSubmit={onSubmit}>
        <FormControl isRequired as={HStack} alignItems="center" isInvalid={!!errors.givenName}>
          <FormLabel htmlFor="givenName" {...formLabelProps}>
            {t('user.firstName')}
          </FormLabel>
          <Box w="full">
            <Input
              id="givenName"
              type="text"
              size="sm"
              autoFocus
              {...register('givenName', {
                validate: (value) => !!value.trim() || t('user.error.firstNameRequired'),
              })}
            />
            <FormErrorMessage whiteSpace="pre-line">{errors.givenName?.message}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl isRequired as={HStack} isInvalid={!!errors.familyName}>
          <FormLabel htmlFor="familyName" {...formLabelProps}>
            {t('user.lastName')}
          </FormLabel>
          <Box w="full">
            <Input
              id="familyName"
              type="text"
              size="sm"
              {...register('familyName', {
                validate: (value) => !!value.trim() || t('user.error.lastNameRequired'),
              })}
            />
            <FormErrorMessage whiteSpace="pre-line">{errors.familyName?.message}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl isRequired as={HStack} isInvalid={!!errors.email}>
          <FormLabel htmlFor="email" {...formLabelProps}>
            {t('common.email')}
          </FormLabel>
          <Box w="full">
            <Input
              id="email"
              type="email"
              size="sm"
              {...register('email', {
                validate: (value) =>
                  InputValidation.email.test(value) || t('auth.error.mailWrongFormat'),
              })}
            />
            <FormErrorMessage whiteSpace="pre-line">{errors.email?.message}</FormErrorMessage>
          </Box>
        </FormControl>

        <FormControl isRequired as={HStack} alignItems="flex-start" isInvalid={!!errors.group}>
          <FormLabel htmlFor="group" {...formLabelProps}>
            {t('common.role')}
          </FormLabel>
          <UserRoleRadioGroup register={register('group')} />
        </FormControl>
      </Stack>
    </BaseModal>
  );
};
