import { Flex, Heading, Spacer } from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { FilterButton, SimTable } from './components';
import { BulkMenu } from './components/BulkMenu';
import { defaultSimPagination } from './components/SimTable/components';
import { searchableSimTableColumns } from './components/simTableSearchConfig';
import { CustomSelect, Searchbar, SectionStack } from 'components';
import {
  SearchableColumn,
  SimFilterKey,
  SimSortBy,
  SimState,
  SortDirection,
  useInfiniteSimPageQuery,
} from 'utils/graphql/hooks';
import type { InputMaybe, SimFilterCount, SimPageQueryVariables } from 'utils/graphql/hooks';
import { useSelectedSimCardsContext } from 'utils/provider/SelectedSimProvider';

const searchComponentsMobileWidths = ['full', '60%'];

type SimFilter = {
  filterKey?: SimPageQueryVariables['filterKey'];
  filterValue?: SimPageQueryVariables['filterValue'];
};

const filter: Record<string, SimFilter> = {
  all: {
    filterKey: undefined,
    filterValue: undefined,
  },
  eightyPercent: {
    filterKey: SimFilterKey.PercentageReached,
    filterValue: '80',
  },
  noDataVolume: {
    filterKey: SimFilterKey.PercentageReached,
    filterValue: '100',
  },
  active: {
    filterKey: SimFilterKey.State,
    filterValue: SimState.Active,
  },
  inactive: {
    filterKey: SimFilterKey.State,
    filterValue: SimState.Suspended,
  },
};

const isFilterActive = (pagination: Partial<SimPageQueryVariables>, filters: SimFilter) =>
  pagination.filterKey === filters.filterKey && pagination.filterValue === filters.filterValue;

export const SimCardsPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedSimCards, selectedIccids, addSelectedSimCards } = useSelectedSimCardsContext();

  const sortBy = new URLSearchParams(location.search).get('sortBy');
  const openedSimModalIccid = new URLSearchParams(location.search).get('iccid');
  const selectedIccid = new URLSearchParams(location.search).get('selected');

  const initialFilterValueFromUrl = useMemo(
    () => selectedIccid || openedSimModalIccid || undefined,
    [openedSimModalIccid, selectedIccid],
  );

  const [simCardSearchColumn, setSimCardSearchColumn] = useState<SearchableColumn>(
    initialFilterValueFromUrl ? SearchableColumn.Iccid : SearchableColumn.Label,
  );

  const [filterCount, setFilterCount] = useState<SimFilterCount | null>();
  const [simPageQueryParams, setSimPageQueryParams] = useState<SimPageQueryVariables>({
    ...defaultSimPagination,
    ...(sortBy &&
      Object.values<string>(SimSortBy).includes(sortBy) && {
        sortBy: sortBy as SimSortBy,
        sortDirection: SortDirection.Desc,
      }),
    searchKey: initialFilterValueFromUrl ? SearchableColumn.Iccid : undefined,
    searchValue: initialFilterValueFromUrl,
  });

  const simPageQueryResult = useInfiniteSimPageQuery(simPageQueryParams, {
    initialPageParam: 0,
    getNextPageParam: (lastPage) =>
      lastPage.simPage.pageInfo.hasNextPage
        ? { offset: lastPage.simPage.pageInfo.nextPage }
        : undefined,
  });

  const { data, isLoading, isFetchingNextPage } = simPageQueryResult;

  const updateSearchParams = useMemo(
    () =>
      debounce((searchKey?: SearchableColumn, searchValue?: InputMaybe<string>) => {
        setSimPageQueryParams((previous) => ({
          ...previous,
          // only set key and value if value is set and not an empty string
          searchKey: searchValue ? searchKey || simCardSearchColumn : undefined,
          searchValue: searchValue || undefined,
        }));
      }, 500),
    [simCardSearchColumn],
  );

  useEffect(() => {
    if (data) {
      setFilterCount(data?.pages[data.pages.length - 1].simPage.filterCount || undefined);
    }
  }, [data]);

  useEffect(() => {
    if (selectedIccid) {
      const foundSelectedSimCards = data?.pages
        .flatMap((page) => page.simPage.items)
        .filter((sim) => sim.iccid === selectedIccid);

      if (
        foundSelectedSimCards?.length === 1 &&
        !selectedIccids.includes(foundSelectedSimCards[0].iccid)
      ) {
        addSelectedSimCards(foundSelectedSimCards);
        navigate(location.pathname, { replace: true }); // url cleanup
      }
    }
  }, [
    addSelectedSimCards,
    data?.pages,
    location.pathname,
    navigate,
    selectedIccid,
    selectedIccids,
  ]);

  return (
    <>
      <SectionStack
        type="outer"
        alignItems="center"
        direction={['column', null, 'row']}
        px="10"
        as="form"
        spacing="2"
      >
        <Heading as="h2" size="lg" py={[4, null, 7]}>
          {t('common.mySimCard_other')}
        </Heading>
        <Spacer />

        <CustomSelect
          items={searchableSimTableColumns}
          defaultSelectedValue={t(`sim.col.${simCardSearchColumn}`)}
          menuButtonProps={{
            size: 'lg',
            borderColor: 'qGray.200',
            fontWeight: 'bold',
            minWidth: '14rem',
            w: [...searchComponentsMobileWidths, 'inherit'],
          }}
          onClick={(value) => {
            setSimCardSearchColumn(value as SearchableColumn);
            updateSearchParams(value as SearchableColumn, simPageQueryParams.searchValue);
          }}
        />

        <Searchbar
          placeholder={t('sim.search')}
          initialSearchValue={simPageQueryParams.searchValue || undefined}
          onChange={(searchValue) => {
            updateSearchParams(simCardSearchColumn, searchValue);
          }}
          w={[...searchComponentsMobileWidths, 60, 80]}
          pb={[4, null, 0]}
        />
      </SectionStack>

      <SectionStack mt="2" type="outer" flex="1" p="10" pb="0" pos="relative">
        <Flex justify={{ base: 'center', lg: 'flex-end' }} mb="10" flexWrap="wrap" gap="1rem">
          <FilterButton
            text={t('sim.filter.showAll')}
            simAmount={filterCount?.all}
            active={isFilterActive(simPageQueryParams, filter.all)}
            onClick={() => {
              setSimPageQueryParams((prev) => ({ ...prev, ...filter.all }));
            }}
          />
          <FilterButton
            text={t('sim.filter.dataVolumeOver', { amount: '80%' })}
            simAmount={filterCount?.eightyPercentDataUsed}
            active={isFilterActive(simPageQueryParams, filter.eightyPercent)}
            onClick={() => {
              setSimPageQueryParams((prev) => ({ ...prev, ...filter.eightyPercent }));
            }}
          />
          <FilterButton
            text={t('sim.filter.noDataVolume')}
            simAmount={filterCount?.noDataVolume}
            active={isFilterActive(simPageQueryParams, filter.noDataVolume)}
            onClick={() => {
              setSimPageQueryParams((prev) => ({ ...prev, ...filter.noDataVolume }));
            }}
          />
          <FilterButton
            text={t('common.states.active')}
            simAmount={filterCount?.active}
            active={isFilterActive(simPageQueryParams, filter.active)}
            onClick={() => {
              setSimPageQueryParams((prev) => ({ ...prev, ...filter.active }));
            }}
          />
          <FilterButton
            text={t('common.states.inactive')}
            simAmount={filterCount?.inactive}
            active={isFilterActive(simPageQueryParams, filter.inactive)}
            onClick={() => {
              setSimPageQueryParams((prev) => ({ ...prev, ...filter.inactive }));
            }}
          />
        </Flex>

        <SimTable
          simPageQueryResult={simPageQueryResult}
          paginationParams={simPageQueryParams}
          setPaginationParams={setSimPageQueryParams}
        />
      </SectionStack>

      {!isLoading && !isFetchingNextPage && selectedSimCards.length > 0 && <BulkMenu />}
    </>
  );
};
