import { Button, Stack, type UseDisclosureProps } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseModal, ScannedSimCardOverview, SimCardImage } from 'components';
import { useSimActivationUrlParams } from 'utils/hooks';

/**
 * This component is currently not in use since the activation and claiming should be done
 * automatically in the background. In case the user should have the opportunity to claim
 * or not the sim cards after the scanning this component could be used.
 */
export const ClaimSimModal: FunctionComponent<UseDisclosureProps> = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const { isEmbeddedSim } = useSimActivationUrlParams();

  const onModalClose = () => {
    onClose();
  };

  const claimSimCard = async () => {
    // activate and claim sim in here
    onModalClose();
  };

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onModalClose}
      header={t('scannedSim.scannedSim')}
      footer={
        <>
          <Button variant="secondary" onClick={onModalClose}>
            {t('common.cancel')}
          </Button>
          <Button onClick={claimSimCard}>{t('scannedSim.assignToCompany')}</Button>
        </>
      }
    >
      <Stack align="center">
        <SimCardImage isEmbeddedSim={isEmbeddedSim} />
        <ScannedSimCardOverview />
      </Stack>
    </BaseModal>
  );
};
