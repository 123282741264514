import { Center, Spinner, type CenterProps } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';

interface LoadingOverlayProps extends CenterProps {
  spinnerSize?: string;
}

export const LoadingOverlay: FunctionComponent<LoadingOverlayProps> = ({
  spinnerSize = 'lg',
  ...rest
}) => (
  <Center pos="absolute" bg="whiteAlpha.100" w="full" h="full" borderRadius="lg" {...rest}>
    <Spinner size={spinnerSize} />
  </Center>
);
