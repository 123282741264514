import type { FunctionComponent, SVGProps } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ExternalIcon } from 'assets/icons/ExternalLink.svg';

interface ExternalLinkIconProps extends SVGProps<SVGSVGElement> {
  title?: string;
}

export const ExternalLinkIcon: FunctionComponent<ExternalLinkIconProps> = ({ title }) => {
  const { t } = useTranslation();

  return <ExternalIcon title={title ?? t('a11y.externalLinkIconTitle')} />;
};
