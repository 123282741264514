import type { Dispatch, FunctionComponent, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

interface BlurContextContent {
  isBlurred: boolean;
  setIsBlurred: Dispatch<SetStateAction<boolean>>;
}

const BlurContext = createContext<BlurContextContent>({ isBlurred: false, setIsBlurred: () => {} });

export const useBlurContext = () => useContext(BlurContext);

// TODO: this is just a workaround to have the blur also on Firefox because of
// FF does not support backdrop-filter:
// https://caniuse.com/?search=backdrop-filter
// https://github.com/chakra-ui/chakra-ui-docs/pull/299#issuecomment-1035946608
export const BlurProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const [isBlurred, setIsBlurred] = useState<boolean>(false);

  const contextValue = useMemo(() => ({ isBlurred, setIsBlurred }), [isBlurred]);

  return <BlurContext.Provider value={contextValue}>{children}</BlurContext.Provider>;
};
