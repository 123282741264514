import { Button, type UseDisclosureProps } from '@chakra-ui/react';
import { useState, type FunctionComponent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SendCodeModalContent } from './components/SendCodeModalContent';
import { VerifyEmailModalContent } from './components/VerifyEmailModalContent';
import { BaseModal } from 'components';

const sendCodeFormId = 'verificationCodeForm';
const verifyEmailFormId = 'verifyNewEmailForm';

export const ChangeEmailModal: FunctionComponent<UseDisclosureProps> = ({
  isOpen = false,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const [sendCodeOpen, setSendCodeOpen] = useState<boolean>(true);
  const [formIsSubmitting, setFormIsSubmitting] = useState<boolean>(false);

  const onModalClose = useCallback(() => {
    onClose();
    setSendCodeOpen(true);
  }, [onClose]);

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onModalClose}
      header={
        sendCodeOpen
          ? t('account.user.changeEmail.sendCode')
          : t('account.user.changeEmail.verifyTitle')
      }
      footer={
        <>
          <Button variant="secondary" onClick={onModalClose}>
            {t('common.cancel')}
          </Button>
          {sendCodeOpen ? (
            <Button
              type="submit"
              form={sendCodeFormId}
              isLoading={formIsSubmitting}
              loadingText={t('common.sending')}
            >
              {t('common.send')}
            </Button>
          ) : (
            <Button
              type="submit"
              form={verifyEmailFormId}
              isLoading={formIsSubmitting}
              loadingText={t('common.verifying')}
            >
              {t('account.user.changeEmail.verifyTitle')}
            </Button>
          )}
        </>
      }
    >
      {sendCodeOpen ? (
        <SendCodeModalContent
          formId={sendCodeFormId}
          setSendCodeOpen={setSendCodeOpen}
          setFormIsSubmitting={setFormIsSubmitting}
        />
      ) : (
        <VerifyEmailModalContent
          formId={verifyEmailFormId}
          setFormIsSubmitting={setFormIsSubmitting}
          onClose={onModalClose}
        />
      )}
    </BaseModal>
  );
};
