import { Text } from '@chakra-ui/react';
import { kebabCase } from 'lodash';
import type { FunctionComponent, PropsWithChildren } from 'react';

type BoldVariant = 'valueOnly' | 'descriptionOnly' | 'both';

interface DetailsDisplayProps {
  description: string;
  boldVariant?: BoldVariant;
  descriptionTitle?: string;
  valueTitle?: string;
}

/**
 * @param boldVariant - 'valueOnly' | 'descriptionOnly' | 'both' - default is 'valueOnly'
 */
export const DetailsDisplayer: FunctionComponent<PropsWithChildren<DetailsDisplayProps>> = ({
  children,
  description,
  boldVariant = 'valueOnly',
  descriptionTitle,
  valueTitle,
}) => {
  const id = kebabCase(`${description}Description`);

  return (
    <>
      <Text
        id={id}
        fontWeight={boldVariant !== 'valueOnly' ? 'bold' : undefined}
        title={descriptionTitle}
      >
        {description}
      </Text>
      {/* Set the element 1px to the right, so it align with the content of the input fields (input fields have 1px border */}
      <Text
        aria-describedby={id}
        fontWeight={boldVariant !== 'descriptionOnly' ? 'bold' : undefined}
        pl={4}
        transform="translateX(1px)"
        title={valueTitle}
      >
        {children}
      </Text>
    </>
  );
};
