import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import i18next from 'i18next';
import { type FunctionComponent } from 'react';
import { type Sim } from 'utils/graphql/hooks';
import { useSelectedSimCardsContext } from 'utils/provider/SelectedSimProvider';

export const SelectedSimTable: FunctionComponent = () => {
  const { selectedSimCards } = useSelectedSimCardsContext();
  const columnHelper = createColumnHelper<Sim>();

  const columns = [
    columnHelper.accessor('iccid', {
      id: 'iccid',
      header: () => i18next.t('common.iccid').toString(),
    }),
    columnHelper.accessor('ip', {
      id: 'ip',
      header: () => i18next.t('sim.col.ip').toString(),
    }),
    columnHelper.accessor('label', {
      id: 'label',
      header: () => i18next.t('sim.col.label').toString(),
    }),
  ];

  const table = useReactTable<Sim>({
    columns,
    data: selectedSimCards,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <Box w="full">
      <Table id="selectedSimCardsTable">
        <Thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Th key={header.id}>
                  <Flex alignItems="center">
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {table.getRowModel().rows.map((row) => (
            <Tr h="14" key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
