import { Center } from '@chakra-ui/react';
import { type FunctionComponent } from 'react';
import { ReactComponent as QbeyondLogo } from 'assets/images/qbeyondLogo.svg';
import { MaintenanceAlert } from 'components/layout/components/MaintenanceAlert';

export const UnauthenticatedHeader: FunctionComponent = () => (
  <>
    <Center pb={['8', null, '16']}>
      <QbeyondLogo title="q.beyond" width="10rem" height="6rem" />
    </Center>
    <MaintenanceAlert />
  </>
);
