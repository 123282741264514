import { Box, Button, Flex, Link, Text, VStack, type TextProps } from '@chakra-ui/react';
import { useCallback, useMemo, type FunctionComponent, type ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import { ExternalLinkIcon } from 'components/ExternalLinkIcon';

const linkContentTextProps: TextProps = {
  fontSize: 'xl',
  fontWeight: 'bold',
};

interface IconButtonWithTextProps {
  translationPath: string;
  href: string;
  icon: ReactNode;
  isExternal?: boolean;
}

export const HeaderIconButton: FunctionComponent<IconButtonWithTextProps> = ({
  translationPath,
  href,
  icon,
  isExternal,
}) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isCurrentOrSubPage = useMemo(
    () => location.pathname.startsWith(href),
    [href, location.pathname],
  );

  const LinkContent = useCallback(
    () => (
      <VStack spacing="0">
        {icon}
        {isExternal ? (
          <Flex align="center">
            <Text {...linkContentTextProps} mr="1">
              {t(translationPath)}
            </Text>
            <Box boxSize="4">
              <ExternalLinkIcon />
            </Box>
          </Flex>
        ) : (
          <Text {...linkContentTextProps}>{t(translationPath)}</Text>
        )}
      </VStack>
    ),
    [icon, isExternal, t, translationPath],
  );

  const linkStyles = {
    variant: 'link',
    w: '10rem',
    py: '4',
    display: 'grid',
    _active: { bgColor: 'inherit', border: 'none' },
    _hover: { color: 'qGray.300' },
    _focus: {
      border: 'none',
      ...(!isCurrentOrSubPage && { color: 'qGray.300' }),
    },
    colorScheme: isCurrentOrSubPage ? 'brand' : 'inherit',
  };

  if (isExternal) {
    return (
      <Button as={Link} href={href} isExternal {...linkStyles}>
        <LinkContent />
      </Button>
    );
  }

  return (
    <Button as={ReactRouterLink} to={href} {...linkStyles}>
      <LinkContent />
    </Button>
  );
};
