import {
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spacer,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HeaderIconButton } from './components/HeaderIconButton';
import { menuEntries, mobileMenuEntries } from './components/menuEntries';
import { ReactComponent as AccountIcon } from 'assets/icons/Account.svg';
import { ReactComponent as BurgerIcon } from 'assets/icons/Burger.svg';
import { ReactComponent as QbeyondLogo } from 'assets/images/qbeyondLogo.svg';
import { routes } from 'utils/routes/index';

const mobileBreakpoint = 'lg';

export const Header: FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <Center minH="32" w="full" bgColor="white" data-test-id="header">
      <Flex
        as="header"
        px="4.5rem"
        py="4"
        w="full"
        maxW="112rem"
        align="center"
        justify="space-between"
      >
        <Link to={routes.root}>
          <QbeyondLogo width="11rem" height="2.25rem" />
        </Link>
        <Spacer />

        <Show above={mobileBreakpoint}>
          <Flex as="nav" justify="space-evenly" w="50%">
            {menuEntries.map((entry) => (
              <HeaderIconButton
                key={entry.text}
                href={entry.href}
                translationPath={entry.text}
                icon={entry.icon}
                isExternal={entry.isExternal}
              />
            ))}
          </Flex>
          <Spacer />
          <HeaderIconButton
            href={routes.account.root}
            translationPath="account.accountAndSupport"
            icon={<AccountIcon />}
          />
        </Show>

        <Show below={mobileBreakpoint}>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label={t('a11y.mobileMenuButtonLabel')}
              icon={<BurgerIcon />}
              variant="outline"
              _active={{ background: 'inherit' }}
              _hover={{ borderColor: 'gray.200', bg: 'inherit' }}
            />
            <MenuList>
              {mobileMenuEntries.map((entry) => (
                <MenuItem as={Link} key={entry.text} to={entry.href}>
                  {t(entry.text)}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </Show>
      </Flex>
    </Center>
  );
};
