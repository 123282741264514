import { Button, chakra, Input, Stack, Text, Textarea, Tooltip, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SectionStack } from '../../../../components';
import type { Ssh } from 'utils/graphql/hooks';
import {
  useGetIotConnectorGroupsQuery,
  useUpdateIotConnectorGroupMutation,
} from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

interface SSHConfigurationFormProps {
  groupId: string;
  ssh?: Ssh;
}

const formatPrivateKey = (privateKey: string): string => {
  const splitStr = privateKey.split('-----').filter((str) => str !== '');
  const beginTag = `-----${splitStr[0]}-----`;
  const endTag = `-----${splitStr[2]}-----`;
  const formattedStr = splitStr[1].split(' ').join('\n');

  return `\n${beginTag}${formattedStr}${endTag}\n`;
};

export const SSHConfigurationForm: FunctionComponent<SSHConfigurationFormProps> = ({
  groupId,
  ssh,
}) => {
  const { t } = useTranslation();
  const { readonlyDisabledProp, readonlyTitleProp } = useAuthUtils();

  const [user, setUser] = useState<string>(ssh?.user || '');
  const [privateKey, setPrivateKey] = useState<string>('');

  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const { isPending: isGetQueryPending } = useGetIotConnectorGroupsQuery();
  const { mutateAsync, isPending: isUpdateQueryPending } = useUpdateIotConnectorGroupMutation();

  const isPending = isGetQueryPending || isUpdateQueryPending;

  const queryClient = useQueryClient();

  const updateConfig = async () => {
    try {
      await mutateAsync({
        groupId,
        sshConfig: {
          user,
          privateKey: privateKey ? formatPrivateKey(privateKey) : undefined,
        },
      });

      queryClient.invalidateQueries({
        queryKey: useGetIotConnectorGroupsQuery.getKey(),
      });

      setPrivateKey('');
      successToast({ title: t('common.updateAttributesSuccess') });
    } catch (err) {
      errorToast(
        err instanceof Error
          ? { title: t('common.updateAttributesError'), description: err.message }
          : { title: t('error.generic') },
      );
    }
  };

  return (
    <chakra.form>
      <SectionStack type="inner">
        <VStack alignItems="normal" py={[4, 7]} px={[5, 10]}>
          <Stack
            spacing="4"
            direction={['column', null, 'row']}
            alignItems={['stretch', null, 'center']}
          >
            <chakra.label htmlFor={t('config.ssh.user')} fontWeight="bold" fontSize="lg" width={28}>
              {t('config.ssh.user')}
            </chakra.label>

            <Input
              id={t('config.ssh.user')}
              placeholder={t('config.ssh.user')}
              isDisabled={readonlyDisabledProp || isPending}
              title={readonlyTitleProp}
              variant="userData"
              onChange={(e) => setUser(e.target.value)}
              value={user}
              size="sm"
              flex={[null, null, 1]}
            />
          </Stack>

          <Stack
            spacing="4"
            direction={['column', null, 'row']}
            alignItems={['stretch', null, 'start']}
          >
            <Tooltip label="RSA, ECDSA, ED25529">
              <chakra.label
                pt={2}
                htmlFor={t('config.ssh.privateKey')}
                fontWeight="bold"
                fontSize="lg"
                width={28}
              >
                {t('config.ssh.privateKey')}
              </chakra.label>
            </Tooltip>

            <Textarea
              id={t('config.ssh.privateKey')}
              placeholder={t('config.ssh.privateKey')}
              isDisabled={readonlyDisabledProp || isPending}
              title={readonlyTitleProp}
              variant="userData"
              onChange={(e) => setPrivateKey(e.target.value)}
              value={privateKey}
              size="sm"
              flex={[null, null, 1]}
              minHeight={80}
            />
          </Stack>

          <Button
            onClick={updateConfig}
            isLoading={isPending}
            isDisabled={readonlyDisabledProp || !user}
            title={readonlyTitleProp}
            mt={2}
          >
            {t('common.apply')}
          </Button>
        </VStack>

        {ssh?.publicKey && ssh.fingerprint && (
          <VStack alignItems="normal" py={[4, 7]} px={[5, 10]}>
            <Text>{t('config.ssh.publicKeyAndFingerprint')}</Text>

            <Stack
              spacing="4"
              direction={['column', null, 'row']}
              alignItems={['stretch', null, 'start']}
            >
              <chakra.label
                pt={1}
                htmlFor={t('config.ssh.publicKey')}
                fontWeight="bold"
                fontSize="lg"
                width={28}
                flexShrink={0}
              >
                {t('config.ssh.publicKey')}
              </chakra.label>

              <Text flexGrow={1} wordBreak="break-all" variant="bordered">
                {ssh?.publicKey}
              </Text>
            </Stack>

            <Stack
              spacing="4"
              direction={['column', null, 'row']}
              alignItems={['stretch', null, 'center']}
            >
              <chakra.label
                htmlFor={t('config.ssh.fingerprint')}
                fontWeight="bold"
                fontSize="lg"
                width={28}
                flexShrink={0}
              >
                {t('config.ssh.fingerprint')}
              </chakra.label>

              <Text flexGrow={1} wordBreak="break-all" variant="bordered">
                {ssh?.fingerprint}
              </Text>
            </Stack>
          </VStack>
        )}
      </SectionStack>
    </chakra.form>
  );
};
