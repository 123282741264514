import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  IconButton,
  Link,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangeEmailModal, ChangePasswordModal, EditUserAttributesModal } from './components';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { AccountDataField, SectionStack } from 'components';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';

const accountDeletionEmail = process.env.REACT_APP_ACCOUNT_DELETION_EMAIL;

export const UserAccountPage: FunctionComponent = () => {
  const { t } = useTranslation();
  const { authUser } = useAuthUserContext();

  const {
    isOpen: isOpenChangePassword,
    onOpen: onOpenChangePassword,
    onClose: onCloseChangePassword,
  } = useDisclosure();

  const {
    isOpen: isOpenEditUserAttributes,
    onOpen: onOpenEditUserAttributes,
    onClose: onCloseEditUserAttributes,
  } = useDisclosure();

  const {
    isOpen: isOpenChangeEmail,
    onOpen: onOpenChangeEmail,
    onClose: onCloseChangeEmail,
  } = useDisclosure();

  return (
    <>
      <EditUserAttributesModal
        isOpen={isOpenEditUserAttributes}
        onClose={onCloseEditUserAttributes}
      />
      <ChangeEmailModal isOpen={isOpenChangeEmail} onClose={onCloseChangeEmail} />
      <ChangePasswordModal isOpen={isOpenChangePassword} onClose={onCloseChangePassword} />
      <SectionStack type="outer" flex="1">
        <Heading as="h2" size="lg" px="12" py="7" display="flex" justifyContent="space-between">
          {t('account.user.accountInfo')}
          <IconButton
            aria-label={t('user.editData').toLowerCase()}
            icon={<EditIcon title={t('common.edit')} />}
            alignSelf="center"
            variant="ghost"
            onClick={onOpenEditUserAttributes}
          />
        </Heading>

        <Grid templateColumns={['1fr', null, '2fr 2fr']} m={[null, 6]}>
          <AccountDataField title={t('user.firstName')} data={authUser?.attributes.given_name} />
          <AccountDataField title={t('user.lastName')} data={authUser?.attributes.family_name} />
          <AccountDataField title={t('common.email')} data={authUser?.attributes.email} />
          <AccountDataField title={t('common.role')} data={t(`common.roles.${authUser?.group}`)} />
        </Grid>

        <Stack
          direction={['column', 'row']}
          alignItems="center"
          spacing={['2', '4']}
          mx={['6', '12']}
          my="8"
        >
          <Box boxSize="5">
            <InfoIcon />
          </Box>

          <Text>
            {t('account.user.deletionInformation')}{' '}
            <Link variant="brand" href={`mailto:${accountDeletionEmail}`}>
              {accountDeletionEmail}
            </Link>
          </Text>
        </Stack>

        <Flex
          p="6"
          gap="2"
          justify={[null, 'space-around']}
          direction={['column', 'row']}
          alignItems="center"
        >
          <Button onClick={onOpenChangeEmail}>{t('account.user.changeEmail.title')}</Button>
          <Button onClick={onOpenChangePassword}>{t('account.user.changePassword.title')}</Button>
        </Flex>
      </SectionStack>
    </>
  );
};
