import type { FunctionComponent } from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import type { SideMenuLink } from 'components';
import { SideMenu, SideMenuWrapper } from 'components';
import { routes } from 'utils/routes';

export const ConfigurationsPage: FunctionComponent = () => {
  const { t } = useTranslation();

  const sideMenuLinks: SideMenuLink[] = useMemo(
    () => [
      { to: routes.config.iotConnectorProxy, text: t('config.iotConnectorProxy.heading') },
      { to: routes.config.iotConnectorAzure, text: t('config.iotConnectorAzure.heading') },
      { to: routes.config.openVpn, text: t('config.openVpn.heading') },
      { to: routes.config.wireGuard, text: t('config.wireGuard.heading') },
      { to: routes.config.ssh, text: t('config.ssh.heading') },
    ],
    [t],
  );

  return (
    <SideMenuWrapper>
      <SideMenu links={sideMenuLinks} />

      <Outlet />
    </SideMenuWrapper>
  );
};
