import { Button, IconButton, Stack, Text } from '@chakra-ui/react';
import { type Cell } from '@tanstack/react-table';
import type { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import type { HttpHeaderViewModel } from '..';
import { ReactComponent as DeleteIcon } from 'assets/icons/Delete.svg';
import { useAuthUtils } from 'utils/hooks/useAuthUtils';

interface ActionsCellProps {
  cell: Cell<HttpHeaderViewModel, unknown>;
  onRemoveHeaderClick: () => void;
  onShowHideButtonClick: () => void;
}

export const HttpHeaderActionsCell: FunctionComponent<ActionsCellProps> = ({
  cell,
  onRemoveHeaderClick,
  onShowHideButtonClick,
}) => {
  const { t } = useTranslation();
  const { readonlyProps } = useAuthUtils();

  return (
    <Stack direction="row-reverse">
      {cell.row.original.isDefault ? (
        <Text>{t('common.default')}</Text>
      ) : (
        <>
          <IconButton
            aria-label={t('config.iotConnectorProxy.removeHeader')}
            variant="outline"
            onClick={onRemoveHeaderClick}
            {...readonlyProps}
          >
            <DeleteIcon />
          </IconButton>
          <Button onClick={onShowHideButtonClick}>
            {cell.row.original.isValueShown ? t('common.hideValue') : t('common.showValue')}
          </Button>
        </>
      )}
    </Stack>
  );
};
