import type { GridProps } from '@chakra-ui/react';
import { Grid } from '@chakra-ui/react';
import type { FunctionComponent, PropsWithChildren } from 'react';

export const SideMenuWrapper: FunctionComponent<PropsWithChildren<GridProps>> = ({
  children,
  ...rest
}) => (
  // minmax(0, 1fr) allows the grid items to shrink on a small viewport
  // -> This is necessary for wide elements (e.g. a table) that should overflow with a scroll bar on small screens
  // See also: https://stackoverflow.com/questions/52861086/why-does-minmax0-1fr-work-for-long-elements-while-1fr-doesnt
  <Grid templateColumns={['minmax(0, 1fr)', null, '12rem minmax(0, 1fr)']} gap="4" {...rest}>
    {children}
  </Grid>
);
