import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback, useMemo, type FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import type { SelectedSimCardsByState } from '..';
import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import { SimState, UserGroup, useActivateSimCardsMutation } from 'utils/graphql/hooks';
import { useIotSimToast } from 'utils/hooks';
import { useAuthUserContext } from 'utils/provider/AuthUserProvider';
import { useSelectedSimCardsContext } from 'utils/provider/SelectedSimProvider';
import { isSimActive } from 'utils/simUtils';

interface ActivationModalContentProps {
  selectedSimCardsByState: SelectedSimCardsByState;
  onClose: () => void;
}

export const ActivationModalContent: FunctionComponent<ActivationModalContentProps> = ({
  selectedSimCardsByState,
  onClose,
}) => {
  const { t } = useTranslation();
  const { selectedSimCards, updateSelectedSimCard } = useSelectedSimCardsContext();
  const { hasRequiredRole } = useAuthUserContext();
  const queryClient = useQueryClient();

  const successToast = useIotSimToast({ status: 'success' });
  const errorToast = useIotSimToast({ status: 'error' });

  const { mutateAsync: activateAsync, isPending: isActivating } = useActivateSimCardsMutation();

  const systemAdminActivationPendingCardsAmount = useMemo(
    () =>
      hasRequiredRole(UserGroup.SystemAdmin) ? selectedSimCardsByState.activationPending.length : 0,
    [hasRequiredRole, selectedSimCardsByState.activationPending.length],
  );

  const allDeactivatedSimCards = useMemo(
    () => selectedSimCards.filter((sim) => !isSimActive(sim)),
    [selectedSimCards],
  );

  const activateAllDeactivatedSimCards = useCallback(async () => {
    const res = await activateAsync({ iccids: allDeactivatedSimCards.map((sim) => sim.iccid) });

    allDeactivatedSimCards.forEach((sim) => {
      if (res.changeSimActivationState.includes(sim.iccid)) {
        updateSelectedSimCard({ ...sim, state: SimState.ActivationPending });
      }
    });

    if (res.changeSimActivationState.length) {
      successToast({
        title: t('sim.bulk.activationSuccess', { count: res.changeSimActivationState.length }),
      });

      await queryClient.invalidateQueries({ queryKey: ['SimPage.infinite'] });
      onClose();
    } else {
      errorToast({
        title: t('sim.bulk.activationFailure', { count: allDeactivatedSimCards.length }),
      });
    }
  }, [
    activateAsync,
    allDeactivatedSimCards,
    errorToast,
    onClose,
    queryClient,
    successToast,
    t,
    updateSelectedSimCard,
  ]);

  if (!selectedSimCardsByState.systemDeactivated.length) {
    return (
      <Text textAlign="center">
        {t('sim.bulk.activateCount', {
          count:
            selectedSimCardsByState.userDeactivated.length +
            systemAdminActivationPendingCardsAmount,
        })}
      </Text>
    );
  }

  return (
    <VStack spacing={2} textAlign="center">
      <HStack alignItems="center" spacing={['2', '4']}>
        <Box boxSize="5">
          <InfoIcon />
        </Box>
        <Text textAlign="start">
          {t('sim.bulk.systemDeactivatedCardsHint', {
            systemDeactivatedCount: selectedSimCardsByState.systemDeactivated.length,
            selectionCount: selectedSimCards.length,
          })}
        </Text>
      </HStack>

      <Box>
        {selectedSimCardsByState.systemDeactivated.map((sim) => (
          <Text key={sim.iccid}>{sim.iccid}</Text>
        ))}
      </Box>

      <Text pt={2}>
        {t('sim.bulk.activateCount', {
          count:
            selectedSimCardsByState.userDeactivated.length +
            systemAdminActivationPendingCardsAmount,
        })}
      </Text>

      {hasRequiredRole(UserGroup.SystemAdmin) && (
        <Button
          mt="4"
          isLoading={isActivating}
          isDisabled={!allDeactivatedSimCards.length}
          onClick={activateAllDeactivatedSimCards}
        >
          {t('sim.bulk.activateAllSystemAdmin')}
        </Button>
      )}
    </VStack>
  );
};
